import { Operator } from 'model/Operator'

export const initialStateOperator: OperatorState = {
    pending: false,
    results: [],
    profilesAllowed: [],
    tenants: [],
    operator: null,
    currentPage: 0,
}

export interface Tenant {
    id: string
    name: string
}

export interface OperatorState {
    pending: boolean
    results: Operator[]
    profilesAllowed: string[]
    tenants: Tenant[]
    operator?: Operator | null
    currentPage: number
    error?: string | null
}

export interface OperatorAction {
    type: string
    results?: Operator[] | Tenant[]
    operator?: Operator
    profiles?: string[]
    page?: number
    id?: string
    error?: string
}
