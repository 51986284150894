import { Grid, FormControl, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MapZoneStops from './maps'
import { googleMapsService } from '../../../services/googleMapsService'
import { factory } from '../../../helpers/factory'
import { alertActions } from '../../../redux/actions/alert_actions'
import store from '../../../redux/store'
import i18next from 'i18next'
import StopsForm from './form'
import utils from '../../../helpers/validations'
import { emitCustomEvent } from 'react-custom-events'
import PublishIcon from '@material-ui/icons/Publish'
import XMLParser from 'react-xml-parser'
import AlertDialog from '../../alert-dialog'
import MapClickListener from './mapclicklistener'

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}))

class ZoneStopView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            stops: [],
            zone: '',
            errors: {
                zone: { result: false, message: '' },
                items: [],
            },
            markers: [],
            editingIndex: null,
            editingMarker: null,
        }
        this.onChangeZones = this.onChangeZones.bind(this)
        this.onChange = this.onChange.bind(this)
        this.addStop = this.addStop.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.renderMarkers = this.renderMarkers.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.cleanStops = this.cleanStops.bind(this)
        this.onChangeLatLon = this.onChangeLatLon.bind(this)
        this.onMapEditMode = this.onMapEditMode.bind(this)
        this.handleMapClick = this.handleMapClick.bind(this)
    }

    onMapEditMode(index) {
        console.log('onMapEditMode called with index:', index)
        console.log('Current state:', {
            editingIndex: this.state.editingIndex,
            stops: this.state.stops,
            markers: this.state.markers,
        })

        const { markers, editingMarker } = this.state

        if (this.state.editingIndex === index) {
            console.log('Desactivando modo edición para el mismo índice')
            if (editingMarker && editingMarker.pin) {
                editingMarker.pin.background = '#EA4335'
                editingMarker.pin.borderColor = '#b31412'
                editingMarker.pin.glyphColor = '#b31412'
            }
            this.setState({
                editingIndex: null,
                editingMarker: null,
            })
            return
        }

        if (editingMarker && editingMarker.pin) {
            console.log('Reseteando marcador anterior')
            editingMarker.pin.background = '#EA4335'
            editingMarker.pin.borderColor = '#b31412'
            editingMarker.pin.glyphColor = '#b31412'
        }

        if (!this.state.stops[index] || !this.state.stops[index].id) {
            console.error('Invalid stop index or missing id', {
                index,
                stops: this.state.stops,
                currentStop: this.state.stops[index],
            })
            return
        }

        const markerToUpdate = markers.find(marker => marker.idStop === this.state.stops[index].id)
        console.log('Marker to update:', markerToUpdate)

        if (markerToUpdate && markerToUpdate.pin) {
            console.log('Actualizando marcador a modo edición')
            markerToUpdate.pin.background = '#35C6F9'
            markerToUpdate.pin.borderColor = '#000000'
            markerToUpdate.pin.glyphColor = '#000000'

            this.setState({
                editingIndex: index,
                editingMarker: markerToUpdate,
            })
        } else {
            console.log('No se encontró el marcador o no tiene pin')
        }
    }

    handleMapClick = async ({ lat, lng }) => {
        const { editingIndex, editingMarker } = this.state

        if (editingIndex === null) return

        try {
            const result = await googleMapsService.getAddress(lat, lng)

            const updatedStops = [...this.state.stops]
            updatedStops[editingIndex] = {
                ...updatedStops[editingIndex],
                address: result.formatted_address,
                point: { lat, lon: lng },
            }

            this.processAddressPoint(
                { lat, lng },
                { description: result.formatted_address },
                updatedStops,
                editingIndex,
                this.state.errors,
            )

            if (editingMarker && editingMarker.pin) {
                editingMarker.pin.background = '#EA4335'
                editingMarker.pin.borderColor = '#b31412'
                editingMarker.pin.glyphColor = '#b31412'
            }

            this.setState({
                editingIndex: null,
                editingMarker: null,
                stops: updatedStops,
            })
        } catch (error) {
            console.error('Error al obtener la dirección:', error)
        }
    }

    addStop() {
        console.log('Adding new stop')
        let errors = this.state.errors
        errors.items.push({
            address: { result: true, message: '' },
            name: { result: true, message: '' },
            lat: { result: true, message: '' },
            lon: { result: true, message: '' },
        })
        let idStop = Math.random()
        console.log('New stop ID:', idStop)

        const newStop = {
            address: '',
            name: '',
            point: {
                lat: '',
                lon: '',
            },
            isBreakStop: false,
            isHandoverStop: false,
            id: idStop,
        }
        const pin = new window.google.maps.marker.PinElement({
            scale: 1,
            background: '#EA4335',
            borderColor: '#B31412',
            glyphColor: '#B31412',
        })

        const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map: this.state.map,
            position: { lat: 0, lng: 0 },
            title: '',
            content: pin.element,
        })

        const newMarker = {
            id: Math.random(),
            item: marker,
            idStop: idStop,
            pin: pin,
        }

        console.log('New stop and marker:', { newStop, newMarker })

        this.setState(
            {
                stops: [...this.state.stops, newStop],
                markers: [...this.state.markers, newMarker],
                errors,
            },
            () => {
                console.log('State after adding stop:', {
                    stops: this.state.stops,
                    markers: this.state.markers,
                })
            },
        )
    }

    removeItem(index, id) {
        let objIndex = this.state.markers.findIndex(obj => obj.idStop == id)
        if (this.state.markers[objIndex] != null && this.state.markers[objIndex].item) {
            let marker = this.state.markers[objIndex].item
            marker.setMap(null)
        }
        let errors = this.state.errors
        errors.items.splice(objIndex, 1)
        this.setState({
            stops: this.state.stops.filter(i => i.id != id),
            markers: this.state.markers.filter(i => i.idStop != id),
            errors,
        })
    }

    onChangeZones = (event, newValue) => {
        let errors = this.state.errors
        errors.zone.message = ''
        let stops = [
            {
                id: Math.random(),
                name: '',
                address: '',
                point: {
                    lat: '',
                    lon: '',
                },
            },
        ]
        if (newValue) {
            if (newValue.stops != null && newValue.stops.length > 0) {
                stops = newValue.stops
                errors.items = []
                newValue.stops.forEach(function (element, i) {
                    errors.items.push({
                        address: { result: true, message: '' },
                        name: { result: true, message: '' },
                        lat: { result: true, message: '' },
                        lon: { result: true, message: '' },
                    })
                })

                this.props.setStops(newValue.stops)
            } else {
                errors.items.push({
                    address: { result: true, message: '' },
                    name: { result: true, message: '' },
                    lat: { result: true, message: '' },
                    lon: { result: true, message: '' },
                })
                this.props.setStops([])
            }
            this.props.changeZone()
        } else {
            stops = []
        }

        this.setState({
            zone: newValue ?? '',
            stops,
            errors,
            markers: [],
            editingIndex: null,
            editingMarker: null,
        })
    }

    onChange(event) {
        let { name, value, checked, type } = event.target
        let stops = this.state.stops
        let errors = this.state.errors
        let array = name.split('_')
        if (type === 'checkbox') stops[array[1]][array[0]] = checked
        else stops[array[1]][array[0]] = value
        if (value) errors.items[array[1]][array[0]].message = ''
        this.setState({ stops })
    }

    cleanValueAddress(errors, index) {
        this.setState({
            initialPosition: { address: '', location: null },
        })
        errors.items[index]['address'].message = ''
        if (this.state.markers[index] != null && this.state.markers[index].item) {
            let marker = this.state.markers[index].item
            marker.setMap(null)
        }
    }

    processAddressPoint(location, newValue, stops, index, errors) {
        let map = this.state.map
        stops[index]['address'] = newValue.description
        stops[index]['point'] = {
            lat: location.lat,
            lon: location.lng,
        }
        let objIndex = this.state.markers.findIndex(obj => obj.idStop == stops[index].id)
        let marker
        if (this.state.markers[objIndex] && this.state.markers[objIndex].item) {
            marker = this.state.markers[objIndex].item
            marker.position = location
            marker.setMap(map)
        } else {
            marker = new window.google.maps.Marker({
                map: map,
                animation: window.google.maps.Animation.DROP,
                position: {
                    lat: location.lat,
                    lng: location.lng,
                },
            })
        }
        errors.items[index]['address'].message = ''
        if (objIndex == -1 && this.state.markers.length == 0) {
            this.state.markers.push({
                id: Math.random(),
                item: marker,
                idStop: stops[index].id,
            })
        } else {
            this.state.markers[objIndex].item = marker
        }
        this.setState({
            stops,
        })
        this.props.setStops(stops)
    }

    onChangeAddress = (newValue, event) => {
        const array = event.nativeEvent.currentTarget.activeElement.name.split('_')
        let index = array[1]
        let stops = this.state.stops
        let errors = this.state.errors
        if (newValue) {
            googleMapsService.getLocation(newValue.description).then(location => {
                // check if the point is inside the zone
                if (factory._isInPolygon(location, this.state.zone.points)) {
                    this.processAddressPoint(location, newValue, stops, index, errors)
                } else {
                    store.dispatch(alertActions.error(i18next.t('zones.stops.stopNotFound')))
                    errors.items[index]['address'] = utils.locationOutSideZone()
                    stops[index]['address'] = ''
                    stops[index]['point'] = {}
                }
            })
        } else {
            this.cleanValueAddress(errors, index)
        }
    }

    processAddressLatLon(index, stops, lat, lon, errors) {
        let map = this.state.map
        let objIndex = this.state.markers.findIndex(obj => obj.idStop == stops[index].id)
        let marker
        if (this.state.markers[objIndex] && this.state.markers[objIndex].item) {
            marker = this.state.markers[objIndex].item
            marker.position = { lat: lat, lng: lon }
            marker.setMap(map)
        } else {
            marker = new window.google.maps.AdvancedMarkerElement({
                map: map,
                position: { lat: lat, lng: lon },
            })
        }
        googleMapsService.getAddress(lat, lon).then(result => {
            stops[index].address = result.formatted_address
            emitCustomEvent('updateAddress', {
                index,
                address: result.formatted_address,
            })
        })
        errors.items[index]['address'].message = ''
        if (objIndex == -1 && this.state.markers.length == 0) {
            this.state.markers.push({
                id: Math.random(),
                item: marker,
                idStop: stops[index].id,
            })
        } else {
            this.state.markers[objIndex].item = marker
        }
        this.setState({ stops })
    }

    cleanMarkerLatLon(errors, index, stops) {
        errors.items[index]['address'].message = ''
        if (this.state.markers[index] != null && this.state.markers[index].item) {
            let marker = this.state.markers[index].item
            marker.setMap(null)
        }
        this.setState({ stops })
    }

    onChangeLatLon(event) {
        let { name, value } = event.target
        let stops = this.state.stops
        let errors = this.state.errors
        let array = name.split('_')
        let index = array[1]
        stops[index].point[array[0]] = parseFloat(value)
        errors.items[index][array[0]].message = ''
        let lat = stops[index].point.lat
        let lon = stops[index].point.lon
        if (lat != 0 && lon != 0) {
            if (factory._isInPolygon({ lat: lat, lng: lon }, this.state.zone.points)) {
                this.processAddressLatLon(index, stops, lat, lon, errors)
            } else {
                store.dispatch(alertActions.error(i18next.t('zones.stops.stopNotFound')))
                errors.items[index]['address'] = utils.locationOutSideZone()
                stops[index]['address'] = ''
            }
        } else {
            this.cleanMarkerLatLon(errors, index, stops)
        }
    }

    checkLatLonInsidePolygon(element, state, stops, i) {
        if (element.lat.result && element.lon.result) {
            if (
                !factory._isInPolygon(
                    {
                        lat: stops[i].point.lat,
                        lng: stops[i].point.lon,
                    },
                    state.zone.points,
                )
            )
                element.address = utils.locationOutSideZone()
            else element.address = { result: true, message: '' }
        }
    }

    validateForm() {
        let errors = this.state.errors
        let state = this.state
        let stops = this.state.stops
        const check = this.checkLatLonInsidePolygon
        errors.items.forEach(function (element, i) {
            element.name = utils.required(stops[i].name)
            if (stops[i].point.lat == '0' && stops[i].point.lon == '0') {
                element.address = utils.required(stops[i].address)
            } else {
                if (stops[i].point.lat != '0' && stops[i].point.lon == '0') {
                    element.lon = utils.minValue(stops[i].point.lon, 1)
                    element.lat = utils.minValue(stops[i].point.lat, 1)
                } else {
                    element.lat = utils.minValue(stops[i].point.lat, 1)
                }
            }
            if (element.address.result) {
                if (utils.isEmptyObject(stops[i].point)) {
                    element.address = utils.locationOutSideZone()
                }
            } else {
                check(element, state, stops, i)
            }
        })
        this.setState({ errors })
    }

    isFormValid() {
        let valid = true
        this.state.errors.items.forEach(element => {
            let properties = Object.values(element)
            properties.forEach(value => {
                if (!value.result) valid = false
            })
        })
        return valid
    }

    cleanStops() {
        this.setState({
            stops: [],
            zone: '',
        })
    }

    onSubmit() {
        this.validateForm()
        if (this.isFormValid()) {
            const stops = factory.createStops(this.state.stops)
            this.props.getServicesFromStops(this.state.zone.id, stops, this.cleanStops)
            //this.props.addStops(this.state.zone.id, stops, this.cleanStops)
        }
    }

    async renderMarkers(map, maps) {
        if (!map || !maps) {
            console.error('Map or Maps is not defined. Cannot render markers.')
            return
        }

        const points = []
        this.setState({ map: map, maps: maps })

        const { zoneReducer } = this.props
        let bounds = new window.google.maps.LatLngBounds()

        for (const element of points) {
            bounds.extend(element)
        }

        this.state.zone.points.forEach(item => {
            let point = {
                lat: item.lat,
                lng: item.lon,
            }
            points.push(point)
            bounds.extend(point)
        })

        const zone = new maps.Polygon({
            paths: points,
            strokeColor: '#0000FF',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#0000FF',
            fillOpacity: 0.35,
        })
        zone.setMap(map)

        let array = this.state.markers
        array = []
        let contentString = ''
        let infoWindow = new maps.InfoWindow({
            content: contentString,
        })

        if (!maps.marker || !maps.marker.PinElement || !maps.marker.AdvancedMarkerElement) {
            await maps?.importLibrary('marker')
        }

        zoneReducer.zoneStops.forEach(function (element, i) {
            const pin = new maps.marker.PinElement({
                scale: 1,
                background: '#EA4335',
                borderColor: '#B31412',
                glyphColor: '#B31412',
            })

            const marker = new maps.marker.AdvancedMarkerElement({
                map: map,
                position: { lat: element.point.lat, lng: element.point.lon },
                title: element.name,
                content: pin.element,
            })

            contentString = element.name
            maps.event.addListener(
                marker,
                'mouseover',
                (function (marker, contentString, infoWindow) {
                    return function () {
                        infoWindow.setContent(contentString)
                        infoWindow.open(map, marker)
                    }
                })(marker, contentString, infoWindow),
            )
            maps.event.addListener(marker, 'mouseout', function () {
                infoWindow.close()
            })

            const item = { id: Math.random(), idStop: element.id, item: marker, pin: pin }
            array.push(item)
        })

        this.setState({
            markers: array,
        })

        map.fitBounds(bounds)

        maps.event.addListener(zone, 'click', event => {
            const clickedLatLng = event.latLng
            emitCustomEvent('mapClicked', { lat: clickedLatLng.lat(), lng: clickedLatLng.lng() })
        })
    }

    loadXMLfile = async e => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async e => {
            const text = e.target.result
            let xml = new XMLParser().parseFromString(text)
            this.setState(
                {
                    XMLtext: text,
                    jsonTransormed: xml.getElementsByTagName('PlaceMark'),
                },
                () => {
                    this.jsonTransformedToText(this.state.jsonTransormed)
                },
            )
        }
        reader.readAsText(e.target.files[0])
    }

    jsonTransformedToText = previousJson => {
        let name = ''
        let coords = null
        if (previousJson) {
            if (previousJson.length > 0) {
                previousJson.forEach(place => {
                    place.children.forEach(placeChildren => {
                        if (placeChildren.name == 'name') {
                            name = placeChildren.value
                        }
                        if (placeChildren.name == 'Point') {
                            coords = placeChildren.children[0]?.value.split(',', 2) || null
                        }
                    })
                    let map = this.state.map
                    let errors = this.state.errors
                    let maps = this.state.maps
                    let array = this.state.markers
                    if (coords != null && name != '') {
                        this.state.errors.items.push({
                            address: { result: true, message: '' },
                            name: { result: true, message: '' },
                            lat: { result: true, message: '' },
                            lon: { result: true, message: '' },
                        })
                        let idStop = Math.random()
                        let point = {
                            lat: parseFloat(coords[1]),
                            lon: parseFloat(coords[0]),
                            x: parseFloat(coords[0]),
                            y: parseFloat(coords[1]),
                        }
                        googleMapsService.getAddressAndName(point.lat, point.lon, name).then(
                            result => {
                                this.processStop(
                                    point,
                                    this.state.stops.length + 1,
                                    result.nameStop,
                                    result.formatted_address,
                                    this.state.zone.points,
                                    errors,
                                    map,
                                    maps,
                                    idStop,
                                    array,
                                )
                            },
                            error => {
                                this.processStop(
                                    point,
                                    this.state.stops.length + 1,
                                    error.toString().split(',')[1],
                                    error.toString().split(',')[1],
                                    this.state.zone.points,
                                    errors,
                                    map,
                                    maps,
                                    idStop,
                                    array,
                                )
                            },
                        )
                    }
                    this.setState({
                        stops: this.state.stops,
                        errors: this.state.errors,
                        markers: array,
                    })
                })
            }
        }
        //console.log("this.state.stopList => ", this.state.stops)
    }

    processStop(point, index, nameStop, address, points, errors, map, maps, idStop, array) {
        this.state.stops.push({
            id: idStop,
            name: nameStop,
            address: address,
            point: point,
            isBreakStop: false,
            isHandoverStop: false,
        })
        if (factory._isInPolygon(point, points))
            errors.items[index - 1]['address'] = { result: true, message: '' }
        else {
            store.dispatch(alertActions.error(i18next.t('zones.stops.stopNotFound')))
            errors.items[index - 1]['address'] = utils.locationOutSideZone()
        }

        let marker = new window.google.maps.Marker({
            map: map,
            animation: window.google.maps.Animation.DROP,
            position: {
                lat: parseFloat(point.lat),
                lng: parseFloat(point.lon),
            },
        })
        let contentString = address
        let infoWindow = new maps.InfoWindow({
            content: contentString,
        })
        maps.event.addListener(
            marker,
            'mouseover',
            (function (marker, contentString, infoWindow) {
                return function () {
                    infoWindow.setContent(contentString)
                    infoWindow.open(map, marker)
                }
            })(marker, contentString, infoWindow),
        )
        maps.event.addListener(marker, 'mouseout', function () {
            infoWindow.close()
        })
        const item = { id: Math.random(), idStop: idStop, item: marker }
        array.push(item)
        emitCustomEvent('updateAddress', { index, address: address })
    }

    render() {
        const { t, zoneReducer } = this.props
        const zones = zoneReducer.results
        return (
            <Grid className={styles.root} container>
                <MapClickListener onMapClick={this.handleMapClick} />
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    style={{ marginBottom: 25, marginTop: 25, padding: '0 10px' }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        container
                    >
                        <FormControl fullWidth>
                            <Autocomplete
                                id="zones"
                                value={this.state.zone || []}
                                onChange={this.onChangeZones}
                                options={zones}
                                getOptionLabel={option =>
                                    option.Name ? option.Name : option.name ? option.name : ''
                                }
                                getOptionSelected={(option, value) =>
                                    option.id == value || option.id == value.id
                                }
                                disabled={this.props.readOnly || this.props.readEdit}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('services_comp.form.page.zones')}
                                        helperText={this.state.errors.zone.message}
                                        name="zones"
                                        error={this.state.errors.zone.message.length !== 0}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {this.state.zone != '' ? (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            container
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size={'medium'}
                                startIcon={<PublishIcon />}
                                onClick={() => document.getElementById('kmlFile').click()}
                            >
                                {t('zones.stops.form.import')}
                                <input
                                    type="file"
                                    id="kmlFile"
                                    accept=".kml"
                                    style={{ display: 'none' }}
                                    onChange={e => this.loadXMLfile(e)}
                                />
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
                {!zoneReducer.changeZone ? (
                    <Grid container>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <StopsForm
                                state={this.state}
                                onChangeAddress={this.onChangeAddress}
                                onChange={this.onChange}
                                addStop={this.addStop}
                                removeItem={this.removeItem}
                                onChangeLatLon={this.onChangeLatLon}
                                onSubmit={this.onSubmit}
                                zoneReducer={this.props.zoneReducer}
                                onMapEditMode={this.onMapEditMode}
                                {...this.props}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <MapZoneStops
                                {...this.props}
                                points={this.state.zone}
                                renderMarkers={this.renderMarkers}
                            />
                        </Grid>
                        <AlertDialog
                            open={zoneReducer.openModalConflictZones}
                            title={i18next.t('services.zones.stopsServiceLine')}
                            content={zoneReducer.contentToModal}
                            onClickCancel={() => this.props.closeModal()}
                            onClickAccept={() => {
                                const stops = factory.createStops(this.state.stops)
                                this.props.addStops(this.state.zone.id, stops, this.cleanStops)
                                this.props.closeModal()
                            }}
                        />
                    </Grid>
                ) : null}
            </Grid>
        )
    }
}

export default ZoneStopView
