import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


class CancelTripModal extends React.Component {

    constructor(props) {
        super()
        this.state = {
            motive: '71'
        }
        this.handleChange = this.handleChange.bind(this)
        this.onAccept = this.onAccept.bind(this)
    }

    handleChange(event) {
        this.setState({
            motive: event.target.value
        })
    }

    onAccept() {
        if (this.state.motive == '72')
            this.props.cancelTripDriver(this.props.itemSelected.tripId)
        else
            this.props.cancelTrip(this.props.itemSelected.tripId, this.props.startDate, this.props.endDate)
        this.props.onClickCancel()
    }

    render() {
        const { t } = this.props
        return (
                <Dialog
                    open={this.props.open && Object.keys(this.props.itemSelected).length > 0}
                    onClose={this.props.onClickCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('customers.trips.cancelTripModal.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                        <FormControl>
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup aria-label="motive" name="motive" value={this.state.motive} onChange={this.handleChange}>
                                <FormControlLabel value="71" control={<Radio color="primary" />} label={t('customers.trips.cancelTripModal.user')} />
                                <FormControlLabel value="72" control={<Radio color="primary" />} label={t('customers.trips.cancelTripModal.driver')} />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={this.props.onClickCancel} variant="contained" size="small" color="primary">
                            {t('alert-dialog.cancel')}
                        </Button>

                        <Button onClick={this.onAccept} variant="contained" size="small" color="primary" autoFocus>
                            {t('alert-dialog.accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

export default withTranslation('common')(CancelTripModal);