import React from 'react'
import {
    FormControl,
    Grid,
    Hidden,
    TextField,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core'
import { makeStyles, withTheme } from '@material-ui/core/styles'
import FormContactOperator from './extraFields/formContact'
import OtpFields from './extraFields/otpFields'
import utils from '../../../helpers/validations'
import { factory } from '../../../helpers/factory'
import Spinner from '../../shared/spinner'
import { history } from '../../../helpers/history'
import { allowAccessRole } from '../../../helpers/roles'
import { ADMIN_PROFILE, MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'
import { getRoleNameTranslated } from '../../roles/rolesHelper'
import i18next from 'i18next'
import { EMPTY_ID } from '../../../constants/generics'

const styles = makeStyles(theme => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}))

class OperatorFormView extends React.Component {
    constructor(props) {
        super()
        const user = props.user ? props.user : {}
        const defaultProfile = this.formatProfile(
            this.getProfileById(props.profiles, user.profileConfigId),
        )
        const isAdmin = props.authProfile === ADMIN_PROFILE
        let defaultTenant = ''

        if (!user) {
            defaultTenant = isAdmin ? '' : props.theme.idTenant
        } else {
            defaultTenant = user.tenantId || (isAdmin ? '' : props.theme.idTenant)
        }

        this.state = {
            id: user.id || '',
            name: user.name || '',
            surname1: user.surname1 || '',
            surname2: user.surname2 || '',
            email: user.email || '',
            identityDocumentCode: user.identityDocumentCode || '',
            phoneNumber: user.phoneNumber || '',
            emailFT: user.emailSecondary || '',
            phoneNumberFT: user.phoneNumberSecondary || '',
            otp: user.otpId ? user.otpId : '',
            zone: user.zone || '',
            rolSelected: defaultProfile || {},
            tenant: defaultTenant,
            otpNewName: '',
            otpVatNumber: '',
            otpContactPerson: '',
            otpEmail: '',
            otpPhoneNumber: '',
            isAdmin: user.profile === ADMIN_PROFILE,
            errors: {
                name: { result: false, message: '' },
                surname1: { result: false, message: '' },
                surname2: { result: true, message: '' },
                email: { result: false, message: '' },
                identityDocumentCode: { result: false, message: '' },
                phoneNumber: { result: !!props.detail, message: '' },
                rolSelected: { result: false, message: '' },
                tenant: { result: false, message: '' },
                emailFT: { result: true, message: '' },
                phoneNumberFT: { result: true, message: '' },
                otp: { result: true, message: '' },
                zone: { result: true, message: '' },
                otpNewName: { result: true, message: '' },
                otpVatNumber: { result: true, message: '' },
                otpEmail: { result: true, message: '' },
                otpContactPerson: { result: true, message: '' },
                otpPhoneNumber: { result: true, message: '' },
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.cleanFields = this.cleanFields.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        let errors = this.state.errors
        errors[name].message = ''
        this.setState({ [name]: value })
    }

    cleanFields() {
        this.setState({
            phoneNumberFT: '',
            emailFT: '',
            otp: '',
            zone: '',
        })
    }

    onClickBack() {
        history.goBack()
    }

    validateForm() {
        let errors = this.state.errors
        errors.name = utils.validateName(this.state.name)
        if (errors.name.result) errors.name = utils.validateMinLength(this.state.name, 2)
        errors.surname1 = utils.validateLastName(this.state.surname1)
        errors.surname2 =
            this.state.surname2 != ''
                ? utils.validateLastName(this.state.surname2)
                : { result: true, message: '' }
        errors.email = utils.validateEmail(this.state.email)
        errors.phoneNumber = utils.required(this.state.phoneNumber)
        if (errors.phoneNumber.result && !this.props.detail) {
            errors.phoneNumber = utils.validatePhone(this.state.phoneNumber)
        }
        errors.identityDocumentCode = utils.validateID(this.state.identityDocumentCode)
        if (this.props.theme.isAmtu) {
            errors.tenant = utils.required(this.state.tenant)
        }

        errors.rolSelected = utils.required(this.state.rolSelected)

        const rolSystemProfile = this.state.rolSelected.systemProfile

        switch (true) {
            case rolSystemProfile == '10' ||
                rolSystemProfile == '20' ||
                rolSystemProfile == '30' ||
                rolSystemProfile == '50':
                errors.emailFT =
                    this.state.emailFT != ''
                        ? utils.validateEmail(this.state.emailFT)
                        : { result: true, message: '' }
                errors.phoneNumberFT =
                    this.state.phoneNumberFT != ''
                        ? utils.validatePhone(this.state.phoneNumberFT)
                        : { result: true, message: '' }
                break
            case rolSystemProfile == '40':
                // sup otp
                // errors.otp = utils.required(this.state.otp)
                if (this.state.otp == 'createOTP') {
                    errors.otpNewName = utils.required(this.state.otpNewName)
                    errors.otpVatNumber = utils.validateCIF_Nif(this.state.otpVatNumber)
                    errors.otpContactPerson = utils.required(this.state.otpContactPerson)
                    errors.otpPhoneNumber = utils.required(this.state.otpPhoneNumber)
                    if (errors.otpPhoneNumber.result && !this.props.detail) {
                        errors.otpPhoneNumber = utils.validatePhone(this.state.otpPhoneNumber)
                    }
                    errors.otpEmail = utils.required(this.state.otpEmail)
                    if (errors.otpEmail.result)
                        errors.otpEmail = utils.validateEmail(this.state.otpEmail)
                } else {
                    errors.otpNewName = { result: true, message: '' }
                    errors.otpVatNumber = { result: true, message: '' }
                    errors.otpContactPerson = { result: true, message: '' }
                    errors.otpPhoneNumber = { result: true, message: '' }
                    errors.otpEmail = { result: true, message: '' }
                }
                break
            default:
                break
        }
        this.setState({ errors })
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    onChangeTenant = (event, newValue) => {
        let errors = this.state.errors
        errors.tenant.message = ''
        if (newValue && newValue.inputValue) {
            this.setState({
                tenant: newValue.inputValue.toUpperCase(),
                model: '',
            })
            this.props.clearModels()
            return
        }
        this.setState({
            tenant: newValue ? newValue.tenantName : '',
            model: '',
        })
        this.props.clearModels()
        if (newValue && newValue.tenantId) {
            this.props.getModeldsByBrands(newValue.tenantId)
        }
    }

    onSubmit = event => {
        event.preventDefault()
        this.validateForm()
        let otpName = null
        if (this.props.entity != 'Profile') {
            const otps = this.props.otpReducer.results
            if (
                this.state.otp != null &&
                this.state.otp != '' &&
                this.state.otp != EMPTY_ID &&
                this.state.otp != 'createOTP'
            ) {
                otpName = otps.find(o => o.id == this.state.otp).name
            }
        }
        if (this.isFormValid()) {
            const otp = {
                name: this.state.otpNewName,
                vatNumber: this.state.otpVatNumber,
                contactPerson: this.state.otpContactPerson,
                phoneNumber: this.state.otpPhoneNumber,
                email: this.state.otpEmail,
            }

            const operator = factory.createOpuser(
                this.state.id,
                this.state.email,
                this.state.name,
                this.state.surname1,
                this.state.surname2,
                this.state.identityDocumentCode,
                this.state.phoneNumber,
                this.state.rolSelected.systemProfile,
                this.state.rolSelected.id,
                this.state.tenant,
                this.state.phoneNumberFT,
                this.state.emailFT,
                this.state.otp != 'createOTP' ? this.state.otp : '',
                otpName,
                this.state.otp == 'createOTP',
                otp,
            )
            this.props.sendOperator(operator)
        }
    }

    renderSwitch() {
        const rolSystemProfile = this.state.rolSelected.systemProfile
        switch (true) {
            case rolSystemProfile == '10' ||
                rolSystemProfile == '20' ||
                rolSystemProfile == '30' ||
                rolSystemProfile == '50':
                return (
                    <FormContactOperator
                        cleanFields={this.cleanFields}
                        handleChange={this.handleChange}
                        state={this.state}
                        {...this.props}
                    />
                )
            case rolSystemProfile == '40':
                // sup otp
                return (
                    <OtpFields
                        handleChange={this.handleChange}
                        cleanFields={this.cleanFields}
                        state={this.state}
                        {...this.props}
                    />
                )
            default:
                return null
        }
    }

    componentWillUnmount() {
        this.props.clearOperator()
    }

    getFormattedProfiles(profiles) {
        if (profiles == null) return
        return profiles.map(profile => this.formatProfile(profile))
    }

    formatProfile(profile) {
        if (profile == null) return
        return {
            id: profile.id,
            systemProfile: profile.systemProfile,
            name: getRoleNameTranslated(profile, i18next.language),
        }
    }

    getProfileById(profiles, id) {
        if (profiles == null) return
        return profiles.find(elem => elem.id === id)
    }

    render() {
        const { t, operatorReducer, tenantReducer, rolesReducer } = this.props
        const profiles = this.getFormattedProfiles(this.props.profiles)
        const tenants = tenantReducer.results
        return (
            <React.Fragment>
                <Spinner loading={operatorReducer.pending || rolesReducer?.pending} />
                <h2 style={{ padding: 50 }}>{this.props.title}</h2>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <Grid className={styles.root} container spacing={3}>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.name')}
                                    value={this.state.name || ''}
                                    variant="outlined"
                                    name="name"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.name.message.length !== 0}
                                    helperText={this.state.errors.name.message}
                                    inputProps={{ readOnly: this.props.readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.surname1')}
                                    value={this.state.surname1 || ''}
                                    variant="outlined"
                                    name="surname1"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.surname1.message.length !== 0}
                                    helperText={this.state.errors.surname1.message}
                                    inputProps={{ readOnly: this.props.readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.surname2')}
                                    value={this.state.surname2 || ''}
                                    variant="outlined"
                                    name="surname2"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.surname2.message.length !== 0}
                                    helperText={this.state.errors.surname2.message}
                                    inputProps={{ readOnly: this.props.readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />

                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.document')}
                                    value={this.state.identityDocumentCode || ''}
                                    variant="outlined"
                                    name="identityDocumentCode"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={
                                        this.state.errors.identityDocumentCode.message.length !== 0
                                    }
                                    helperText={this.state.errors.identityDocumentCode.message}
                                    inputProps={{ readOnly: this.props.readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.email')}
                                    value={this.state.email || ''}
                                    variant="outlined"
                                    name="email"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.email.message.length !== 0}
                                    helperText={this.state.errors.email.message}
                                    inputProps={{
                                        readOnly: this.props.readOnly || this.props.edit,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    label={t('operators.form.page.phone')}
                                    value={this.state.phoneNumber || ''}
                                    variant="outlined"
                                    name="phoneNumber"
                                    fullWidth
                                    type={'tel'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.phoneNumber.message.length !== 0}
                                    helperText={this.state.errors.phoneNumber.message}
                                    inputProps={{ readOnly: this.props.readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />
                        {this.props.profiles ? (
                            <React.Fragment>
                                <Grid item md={1} implementation="css" smDown component={Hidden} />
                                <Grid item md={3} xs={12} sm={9}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="profileLabel">
                                            {t('operators.form.page.profile')}
                                        </InputLabel>
                                        <Select
                                            labelId="profileLabel"
                                            id="profile"
                                            name="rolSelected"
                                            value={this.state.rolSelected}
                                            renderValue={() => this.state.rolSelected.name}
                                            onChange={this.handleChange}
                                            error={
                                                this.state.errors.rolSelected.message.length !== 0
                                            }
                                            displayEmpty
                                            inputProps={{
                                                readOnly: this.props.readOnly || this.state.isAdmin,
                                            }}
                                        >
                                            {profiles.map(item => (
                                                <MenuItem key={item.id} value={item}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {this.state.errors.rolSelected.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8} implementation="css" smDown component={Hidden} />
                                {this.renderSwitch()}
                            </React.Fragment>
                        ) : (
                            this.props.renderSwitch(
                                this.state.rolSelected,
                                this.cleanFields,
                                this.handleChange,
                                this.state,
                                t,
                            )
                        )}
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        {tenants &&
                        this.props.theme.isAmtu &&
                        this.props.authProfile === ADMIN_PROFILE ? (
                            <React.Fragment>
                                <Grid item md={3} xs={12} sm={9}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink id="tenantLabel">
                                            {t('operators.form.page.tenant')}
                                        </InputLabel>
                                        <Select
                                            labelId="tenantLabel"
                                            id="tenant"
                                            name="tenant"
                                            value={this.state.tenant}
                                            onChange={this.handleChange}
                                            error={this.state.errors.tenant.message.length !== 0}
                                            displayEmpty
                                            inputProps={{
                                                readOnly:
                                                    this.props.readOnly ||
                                                    this.props.readEdit ||
                                                    this.props.entity === 'Profile',
                                            }}
                                        >
                                            {tenants.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {this.state.errors.tenant.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8} implementation="css" smDown component={Hidden} />
                            </React.Fragment>
                        ) : null}
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={9}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={this.onClickBack}
                            >
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid item md={1} xs={1} implementation="css" smDown component={Hidden} />
                        {allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage) && (
                            <Grid item md={2} xs={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    size="large"
                                >
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        )}
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                    </Grid>
                </form>
            </React.Fragment>
        )
    }
}

export default withTheme(OperatorFormView)
