import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const zoneService = {
    getAll,
    getAllByType,
    getById,
    insert,
    update,
    _delete,
    addStop,
    getServicesFromStops,
    getAllActiveStops,
}

function getAll(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const url =
        serviceId == null
            ? '/services/v1/serviceZones/getZones'
            : `/services/v1/serviceZones/getZones?serviceId=${serviceId}`
    return customFetch(stringConstants.URL_ApiGateway + url, requestOptions).then(handleResponse)
}

function getAllByType() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/zones/getZonesByType',
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/zones/' + id,
        requestOptions,
    ).then(handleResponse)
}

function insert(zone) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/areas/v1/zones/add', requestOptions).then(
        handleResponse,
    )
}

function addStop(zoneId, stops) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(stops),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/zones/addStop?zoneId=' + zoneId,
        requestOptions,
    ).then(handleResponse)
}

function getServicesFromStops(zoneId, stops) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/services/getServicesFromStops?zoneId=' +
            zoneId +
            '&removedStops=' +
            stops,
        requestOptions,
    ).then(handleResponse)
}

function update(zone) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    const id = zone.id
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/zones/updateZone?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/zones/remove?zoneId=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getAllActiveStops() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/serviceZones/getAllActiveStops',
        requestOptions,
    ).then(handleResponse)
}
