import { Action } from 'redux'
import { Trip } from 'model/Trip'
import { Service, ServiceLine, Stop, ZoneStop } from 'model/Service'
import { RelatedCustomer } from 'model/Customer'

export interface TripState {
    pending: boolean
    results: Trip[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    markers: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    modalSelectedService: boolean
    modalReturnTrip: boolean
    modalExpeditions: boolean
    originStop: Stop | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    destinationStop: Stop | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    services: Service[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    typesTrips: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    originDistanceTime: any | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    destinationDistanceTime: any | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    filterStartDate: Date
    filterEndDate: Date
    tripsKPI: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    allTrips: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    filterTrips: { status: any[] }[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    requestTripError: boolean
    requestTrip?: Trip | null
    tableFilters: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    isReturn: boolean
    tripToReturn: string | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    trip?: Trip | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    backupTrip: Trip | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    error: string | null
    pendingTripsKPI: boolean
    possibleOrigins: Stop[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    possibleDestinations: Stop[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    serviceSelected: Service | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    customerId: string | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    serviceLine: ServiceLine | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    serviceLineDirection: 'Outbound' | 'Return' | null
    expeditions: any[] // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    modalMessage: boolean
    message: string | null
    isLoading: boolean
    previewTrip: Trip | null // Reemplaza `any` con el tipo adecuado si tienes la estructura.
    modalSelectScheduledPickUp: boolean
    tripSuccess: boolean
    currentCustomer?: string | null
    pickUpLocation: Location | null
    dropOffLocation: Location | null
    numPassengers: number | null
    quantityLuggage: number | null
    wheelChairs: boolean | null
    observation: string | null
    resultsTrips: Trip[] | null
    openModalReturnTrip: boolean
    pickUpDate: Date | null
    posibleSchedule: ExpeditionOption[] | null
    tripToModify: Trip | null
    all: Trip[]
    customerToRequest: CustomerRequest | null
    isRequestByDropOff: boolean
}

interface ExpeditionOption {
    regularLineId: string
    expeditionId: string
    hour: string
}

interface CustomerRequest {
    customerId?: string
    name?: string
    isOnRelatedCustomerBehalf?: boolean
    birthDate?: Date
    hasWheelChair?: boolean
    relatedCustomers?: RelatedCustomer
}

export const GetStopFromZoneStop = (zoneStop: ZoneStop): Stop => {
    const stop: Stop = {
        address: zoneStop.address,
        id: zoneStop.id,
        lat: zoneStop.point.lat,
        lon: zoneStop.point.lon,
        name: zoneStop.name,
    }
    return stop
}

export interface TripAction extends Action {
    type: string
    [key: string]: any
}

export const initialStateTrip: TripState = {
    pending: false,
    results: [],
    markers: [],
    modalSelectedService: false,
    modalReturnTrip: false,
    modalExpeditions: false,
    originStop: null,
    destinationStop: null,
    services: [],
    typesTrips: [],
    originDistanceTime: null,
    destinationDistanceTime: null,
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    tripsKPI: [],
    allTrips: [],
    filterTrips: [
        {
            status: [],
        },
    ],
    requestTripError: false,
    tableFilters: [],
    isReturn: false,
    tripToReturn: null,
    trip: null,
    backupTrip: null,
    pendingTripsKPI: false,
    error: null,
    expeditions: [],
    isLoading: false,
    numPassengers: null,
    wheelChairs: null,
    quantityLuggage: null,
    observation: null,
    resultsTrips: [],
    possibleDestinations: [],
    possibleOrigins: [],
    serviceSelected: null,
    customerId: null,
    dropOffLocation: null,
    message: null,
    requestTrip: null,
    serviceLine: null,
    serviceLineDirection: null,
    pickUpLocation: null,
    previewTrip: null,
    modalSelectScheduledPickUp: false,
    modalMessage: false,
    tripSuccess: false,
    currentCustomer: null,
    openModalReturnTrip: false,
    pickUpDate: null,
    posibleSchedule: [],
    tripToModify: null,
    all: [],
    isRequestByDropOff: false,
    customerToRequest: null,
}
