import { driverConstants } from '../../constants/driver_constants'
import { history } from '../../helpers/history'
import { driverService } from '../../services/driverService'
import { alertActions } from '../actions/alert_actions'
import i18next from 'i18next'
import { ERROR_CODES } from '../../constants/types'
import { EMPTY_ID } from '../../constants/generics'
import { ACTION_TYPES } from '../../constants/types'

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value.toString())

const DETAIL_ERROR_CODES = {
    EmailAlreadyExists: 'EmailAlreadyExists',
    DocumentCodeAlreadyExists: 'DocumentCodeAlreadyExists',
    UserEmailAlreadyExists: 'UserEmailAlreadyExists',
    InvalidDocument: 'InvalidDocument',
    ProfileCannotInsert: 'ProfileCannotInsert',
    ResetPasswordEmailNotSent: 'ResetPasswordEmailNotSent',
    DriverDisabled: 'DriverDisabled',
    DriverNotDisabled: 'DriverNotDisabled',
    TripOngoing: 'TripOngoing',
    TripNotCancelled: 'TripNotCancelled',
    Forbidden: 'ForbiddenUpdate'
}

export const driversActions = {
    getAll,
    getById,
    insert,
    update,
    clearResults,
    disable,
    enable,
    remove,
    validate,
    getByIdWithVehicles,
    updatePassword,
    updateUserPasswordFromEmailChange,
    getDriversForChat,
    forgotPassword,
    getDriversFullName,
    cleanDriversFullName,
    setCurrentPage,
    getPosibleDriversToAssing,
    closeModalToAssing,
    getDriversFromService,
    cleanServicesName,
    getResetPasswordLink,
    getDriverRequests,
    getDriversKPI,
    cleanPushToken,
    saveDriverFilters,
}

function setCurrentPage(page) {
    return dispatch => {
        dispatch(request(page))
    }

    function request(page) {
        return { type: driverConstants.DRIVER_SET_PAGE_TABLE, page }
    }
}

function closeModalToAssing() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: driverConstants.CHECK_DRIVERS_CLOSE }
    }
}

function getDriversForChat(opUserProfile, otpId) {
    return dispatch => {
        dispatch(request())
        driverService.getDriversForChat(opUserProfile, otpId).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.GET_FOR_CHAT_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.GET_FOR_CHAT_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.GET_FOR_CHAT_FAILURE, error }
    }
}

function getDriverRequests(status, startDate, endDate) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            driverService.getDriverRequests(status, startDate, endDate).then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    dispatch(failure(error))
                    reject(error)
                },
            )
        })
    }

    function request() {
        return { type: driverConstants.GET_REQUEST_DRIVERS_SERVICES_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.GET_REQUEST_DRIVERS_SERVICES_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.GET_REQUEST_DRIVERS_SERVICES_FAILURE, error }
    }
}

function cleanDriversFullName() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: driverConstants.CLEAN_DRIVERS_FULLNAME }
    }
}

function cleanServicesName() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: driverConstants.CLEAN_SERVICES_NAME }
    }
}

function getDriversFullName(otpId) {
    return dispatch => {
        dispatch(request())
        driverService.getDriversFullName(otpId).then(
            results => {
                results.unshift({
                    id: EMPTY_ID,
                    fullName: i18next.t('incidents.details.none'),
                })
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.GET_FULLNAME_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.GET_FULLNAME_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.GET_FULLNAME_FAILURE, error }
    }
}

function getPosibleDriversToAssing(trip) {
    return dispatch => {
        dispatch(request())
        driverService.getPosibleDriversToAssing(trip).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.CHECK_DRIVERS_TO_ASSING_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.CHECK_DRIVERS_TO_ASSING_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.CHECK_DRIVERS_TO_ASSING_FAILURE, error }
    }
}

function getDriversFromService(serviceId) {
    return dispatch => {
        dispatch(request())
        driverService.getDriversFromService(serviceId).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.CHECK_DRIVERS_SERVICES_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.CHECK_DRIVERS_SERVICES_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.CHECK_DRIVERS_SERVICES_FAILURE, error }
    }
}

function getAll(filters = null) {
    return dispatch => {
        dispatch(request())
        driverService.getAll().then(
            results => {
                let all = results
                if (filters != null) {
                    results = filterPlainArray(
                        results.map(a => a.driver),
                        filters,
                    )
                }
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.drivers.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.GETALL_REQUEST }
    }

    function success(results, all) {
        return { type: driverConstants.GETALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: driverConstants.GETALL_FAILURE, error }
    }
}

function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true
            return filters[key].find(filter => getValue(filter) === getValue(item[key]))
        })
    })
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        driverService.getById(id).then(
            driver => {
                dispatch(success(driver.driver))
                if (redirect) {
                    history.push('/drivers/' + id)
                }
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.drivers.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: driverConstants.DETAIL_REQUEST, id }
    }

    function success(driver) {
        return { type: driverConstants.DETAIL_SUCCESS, driver }
    }

    function failure(error) {
        return { type: driverConstants.DETAIL_FAILURE, error }
    }
}

function getByIdWithVehicles(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        driverService.getByIdWithVehicles(id).then(
            driver => {
                dispatch(success(driver))
                if (redirect) {
                    history.push('/drivers/' + id)
                }
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.drivers.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: driverConstants.DETAIL_REQUEST, id }
    }

    function success(driver) {
        return { type: driverConstants.DETAIL_SUCCESS, driver }
    }

    function failure(error) {
        return { type: driverConstants.DETAIL_FAILURE, error }
    }
}

function update(driver) {
    return dispatch => {
        dispatch(request())

        driverService.update(driver).then(
            driver => {
                dispatch(success(driver))
                history.push('/drivers')
                dispatch(alertActions.success(i18next.t('services.drivers.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.repeatEmail'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.DocumentCodeAlreadyExists:
                                    dispatch(
                                        alertActions.error(i18next.t('services.drivers.repeatDoc')),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.UserEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.InvalidDocument:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.InvalidDocument'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ProfileCannotInsert:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.ProfileCannotInsert'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.updateError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.EDIT_REQUEST }
    }

    function success(driver) {
        return { type: driverConstants.EDIT_SUCCESS, driver }
    }

    function failure(error) {
        return { type: driverConstants.EDIT_FAILURE, error }
    }
}

function enable(id) {
    return dispatch => {
        dispatch(request());
        driverService.enable(id).then(
            response => {
                dispatch(success(id));
                dispatch(alertActions.success(i18next.t('services.drivers.enableSuccess')));
                dispatch(getAll());
            },
            error => {
                handleCommonErrors(error, dispatch, ACTION_TYPES.ENABLE);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: driverConstants.ENABLE_REQUEST };
    }

    function success(id) {
        return { type: driverConstants.ENABLE_SUCCESS, id };
    }

    function failure(error) {
        return { type: driverConstants.ENABLE_FAILURE, error };
    }
}

function disable(id) {
    return dispatch => {
        dispatch(request());
        driverService.disable(id).then(
            response => {
                dispatch(success(id));
                dispatch(alertActions.success(i18next.t('services.drivers.disableSuccess')));
                dispatch(getAll());
            },
            error => {
                handleCommonErrors(error, dispatch, ACTION_TYPES.DISABLE);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: driverConstants.DISABLE_REQUEST };
    }

    function success(id) {
        return { type: driverConstants.DISABLE_SUCCESS, id };
    }

    function failure(error) {
        return { type: driverConstants.DISABLE_FAILURE, error };
    }
}

const handleCommonErrors = (error, dispatch, actionType) => {
    try {
        let parsedError = JSON.parse(error);
        let detailError = parsedError.detail;

        switch (detailError) {
            case DETAIL_ERROR_CODES.DriverDisabled:
                dispatch(alertActions.error(i18next.t('services.drivers.alreadyDisabled')));
                break;
            case DETAIL_ERROR_CODES.DriverNotDisabled:
                dispatch(alertActions.error(i18next.t('services.drivers.notDisabled')));
                break;
            case DETAIL_ERROR_CODES.TripOngoing:
                dispatch(alertActions.error(i18next.t('services.drivers.tripOngoing')));
                break;
            case DETAIL_ERROR_CODES.TripNotCancelled:
                dispatch(alertActions.error(i18next.t('services.drivers.tripNotCancelled')));
                break;
            case DETAIL_ERROR_CODES.Forbidden:
                dispatch(alertActions.error(i18next.t('services.drivers.forbiddenUpdate')));
                break;
            default:
                dispatch(alertActions.error(
                    actionType === ACTION_TYPES.ENABLE
                        ? i18next.t('services.drivers.enableError')
                        : i18next.t('services.drivers.disableError')
                ));
        }
    } catch (parseError) {
        dispatch(alertActions.error(
            actionType === ACTION_TYPES.ENABLE
                ? i18next.t('services.drivers.enableError')
                : i18next.t('services.drivers.disableError')
        ));
        console.error('Error parsing response:', parseError);
    }
};

function cleanPushToken(vehicleId, driverId) {
    return dispatch => {
        dispatch(request())
        driverService.cleanPushToken(vehicleId, driverId).then(
            driver => {
                dispatch(success())
                dispatch(getByIdWithVehicles(driverId, false))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.DELETE_PUSH_TOKEN_REQUEST }
    }

    function success() {
        return { type: driverConstants.DELETE_PUSH_TOKEN_SUCCESS }
    }

    function failure(error) {
        return { type: driverConstants.DELETE_PUSH_TOKEN_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: driverConstants.CLEAR_RESULTS }
    }
}

function insert(driver) {
    return dispatch => {
        dispatch(request())
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
        driverService.insert(driver, url).then(
            driver => {
                dispatch(success(driver))
                dispatch(alertActions.success(i18next.t('services.drivers.insertSuccess')))
                history.push('/drivers')
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.EmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.repeatEmail'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.DocumentCodeAlreadyExists:
                                    dispatch(
                                        alertActions.error(i18next.t('services.drivers.repeatDoc')),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.UserEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.UserEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ResetPasswordEmailNotSent:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.sendEmailError'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.drivers.insertRepeat'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.ADD_REQUEST }
    }

    function success(driver) {
        return { type: driverConstants.ADD_SUCCESS, driver }
    }

    function failure(error) {
        return { type: driverConstants.ADD_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        driverService._delete(id).then(
            driver => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.drivers.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.drivers.forbiddenDelete')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.drivers.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: driverConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: driverConstants.DELETE_FAILURE, error }
    }
}

function validate(id) {
    return dispatch => {
        dispatch(request())
        driverService.validate(id).then(
            driver => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.drivers.validateSuccess')))
                dispatch(getAll())
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.drivers.validateError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: driverConstants.VALIDATE_REQUEST }
    }

    function success(id) {
        return { type: driverConstants.VALIDATE_SUCCESS, id }
    }

    function failure(error) {
        return { type: driverConstants.VALIDATE_FAILURE, error }
    }
}

function updatePassword(email, newPassword, urlRedirect) {
    return dispatch => {
        dispatch(request())
        driverService.updatePassword(email, newPassword).then(
            result => {
                dispatch(success())
                dispatch(alertActions.success(i18next.t('updatePassword.page.successUpdate')))
                if (urlRedirect != '') {
                    setTimeout(function () {
                        window.location = urlRedirect
                    }, 1800)
                }
            },
            error => {
                dispatch(failure(error))
                let detailError = ''
                let status = ''
                try {
                    let parsedError = JSON.parse(error)
                    detailError = parsedError.detail
                    status = parsedError.status
                } catch (e) {
                    detailError = i18next.t('updatePassword.page.errorUpdate')
                }

                if (status === 400) {
                    switch (detailError) {
                        case 'PasswordAlreadyUsed':
                            detailError = i18next.t('updatePassword.page.passwordAlreadyUsed')
                            break
                        default:
                            detailError = detailError
                    }
                    dispatch(alertActions.error(detailError))
                } else {
                    dispatch(alertActions.error(detailError))
                }
            },
        )
    }

    function request() {
        return { type: driverConstants.UPDATE_PSW_REQUEST }
    }

    function success() {
        return { type: driverConstants.UPDATE_PSW_SUCCESS }
    }

    function failure(error) {
        return { type: driverConstants.UPDATE_PSW_FAILURE, error }
    }
}

function updateUserPasswordFromEmailChange(email, newPassword, urlRedirect) {
    return dispatch => {
        dispatch(request())
        driverService.updateUserPasswordFromEmailChange(email, newPassword).then(
            result => {
                dispatch(success())
                dispatch(alertActions.success(i18next.t('updatePassword.page.successUpdate')))
                if (urlRedirect != '') {
                    setTimeout(function () {
                        window.location = urlRedirect
                    }, 1800)
                }
            },
            error => {
                dispatch(failure(error))
                let detailError = ''
                let status = ''
                try {
                    let parsedError = JSON.parse(error)
                    detailError = parsedError.detail
                    status = parsedError.status
                } catch (e) {
                    detailError = i18next.t('updatePassword.page.errorUpdate')
                }

                if (status === 400) {
                    switch (detailError) {
                        case 'PasswordAlreadyUsed':
                            detailError = i18next.t('updatePassword.page.passwordAlreadyUsed')
                        default:
                            detailError = detailError
                    }
                    dispatch(alertActions.error(detailError))
                } else {
                    dispatch(alertActions.error(detailError))
                }
            },
        )
    }

    function request() {
        return { type: driverConstants.UPDATE_PSW_REQUEST }
    }

    function success() {
        return { type: driverConstants.UPDATE_PSW_SUCCESS }
    }

    function failure(error) {
        return { type: driverConstants.UPDATE_PSW_FAILURE, error }
    }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request())
        driverService.checkDriverUser(email.toLowerCase()).then(result => {
            if (result) {
                let lng = i18next.language
                let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
                driverService.sendResetPasswordEmail(email.toLowerCase(), lng, url).then(
                    data => {
                        dispatch(success(data))
                        dispatch(
                            alertActions.success(
                                i18next.t('services.login.resendPasswordToEmailSuccess'),
                            ),
                        )
                    },
                    error => {
                        dispatch(failure(error))
                        dispatch(
                            alertActions.error(
                                i18next.t('services.login.resendPasswordToEmailError'),
                            ),
                        )
                    },
                )
            } else {
                dispatch(failure(result))
                dispatch(alertActions.error(i18next.t('services.login.resendPasswordToEmailError')))
            }
        })
    }

    function request() {
        return { type: driverConstants.RESET_PSW_REQUEST }
    }
    function success() {
        return { type: driverConstants.RESET_PSW_SUCCESS }
    }
    function failure(error) {
        return { type: driverConstants.RESET_PSW_FAILURE, error }
    }
}

function getResetPasswordLink(email) {
    return dispatch => {
        let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
        driverService.getResetPasswordLink(email, url).then(
            link => {
                navigator.clipboard.writeText(link).then(
                    success => {
                        dispatch(
                            alertActions.success(
                                i18next.t('services.drivers.getLinkRecoverySuccess'),
                            ),
                        )
                    },
                    error => {
                        dispatch(
                            alertActions.error(i18next.t('services.drivers.getLinkRecoveryError')),
                        )
                    },
                )
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.drivers.getLinkRecoveryError')))
            },
        )
    }
}

function getDriversKPI() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            driverService.getDriversKPI().then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                    dispatch(failure(error))
                    reject('error')
                },
            )
        })
    }

    function request() {
        return { type: driverConstants.GET_DRIVERS_KPI_REQUEST }
    }

    function success(results) {
        return { type: driverConstants.GET_DRIVERS_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: driverConstants.GET_DRIVERS_KPI_FAILURE, error }
    }
}

function saveDriverFilters(tableState) {
    return dispatch => {
        dispatch(save(tableState))
    }

    function save(tableState) {
        return { type: driverConstants.SAVE_TABLE_FILTERS, tableState }
    }
}
