import moment from 'moment'

export enum KPI_FILTER_OPTIONS {
    services = 'Services',
    otps = 'OTPS',
    tenant = 'Tenant',
    region = 'Region',
    municipalities = 'Municipios',
}

export const initialStateKPIFilter: KPIFiltersState = {
    dateStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    range: 'month',
    type: KPI_FILTER_OPTIONS.services,
    filterBy: [],
}

export type KPIFilterRange = 'month' | 'year'

export interface KPIFiltersState {
    dateStart: string
    dateEnd: string
    range: KPIFilterRange
    type: KPI_FILTER_OPTIONS
    filterBy: string[]
}

export interface KPIFiltersAction {
    type: string
    dateStart?: string
    dateEnd?: string
    range?: KPIFilterRange
    typeSelected?: KPI_FILTER_OPTIONS
    filterBy?: string[]
}
