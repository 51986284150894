export const initialStateVehicle: VehicleState = {
    currentAvailableVehicleCapacity: null,
    pending: false,
    results: [],
    brands: [],
    models: [],
    locations: [],
    resultsByOTP: [],
    priorities: [],
    allVehicles: [],
    vehicle: { excludedPeriods: [] },
    ranges: [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ],
    filterBrand: [],
    filterModel: [],
    filterOTP: [],
    filters: null,
    vehiclesGeneralDataKPI: [],
    tableFilters: [],
}

export interface ExcludedPeriod {
    startDate: Date
    endDate: Date
}

interface Range {
    startDate: Date
    endDate: Date
    key: string
}

interface Vehicle {
    id?: string
    excludedPeriods: ExcludedPeriod[] | null
    disabled?: boolean
    [key: string]: any
}

export interface VehicleState {
    currentAvailableVehicleCapacity: number | null
    pending: boolean
    results: Vehicle[]
    brands: string[]
    models: string[]
    locations: string[]
    resultsByOTP: Vehicle[]
    priorities: string[]
    allVehicles: Vehicle[]
    vehicle?: Vehicle | null
    ranges: Range[]
    filterBrand: string[]
    filterModel: string[]
    filterOTP: string[]
    filters: any
    vehiclesGeneralDataKPI: any[]
    tableFilters: any[]
    error?: string
    otp?: string
    operator?: string
}

export interface VehicleAction {
    type: string
    results?: Vehicle[]
    all?: Vehicle[]
    error?: string
    vehicle?: Vehicle
    id?: string
    otp?: string
    operator?: string
    range?: Range[]
    brands?: string[]
    models?: string[]
    locations?: string[]
    priorities?: string[]
    element?: ExcludedPeriod
    index?: number
    prop?: 'brand' | 'model' | 'OTP'
    values?: string[]
    filters?: any
    tableFilters?: any[]
    vehicles?: Vehicle[]
}
