import { modulesConstants } from '../../../constants/modules_constants'
import { ModulesState, ModulesAction, initialStateModule } from './types'

export function modulesReducer(state = initialStateModule, action: ModulesAction): ModulesState {
    switch (action.type) {
        case modulesConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case modulesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results || [],
            }

        case modulesConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error ?? 'An error occurred',
            }

        default:
            return state
    }
}
