import React from 'react'
import Spinner from '../../shared/spinner'
import CustomerForm from '../form'

class CustomerDetailsView extends React.Component {
    render() {
        const { customerReducer } = this.props
        const wait = customerReducer.customer == null || customerReducer.pending
        return (
            <React.Fragment>
                {wait ? <Spinner loading={wait} /> : <CustomerForm {...this.props} />}
            </React.Fragment>
        )
    }
}

export default CustomerDetailsView
