import { alertConstants } from '../../../constants/alert_constants'
import { AlertState, AlertAction } from './types'

const initialState: AlertState = {
    messages: [],
}

export function alertReducer(state = initialState, action: AlertAction): AlertState {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'success',
                messages: [...state.messages, action.message!],
            }

        case alertConstants.ERROR:
            return {
                type: 'error',
                messages: [...state.messages, action.message!],
            }

        case alertConstants.WARNING:
            return {
                type: 'warning',
                messages: [...state.messages, action.message!],
            }

        case alertConstants.INFO:
            return {
                type: 'info',
                messages: [...state.messages, action.message!],
            }

        case alertConstants.CLEAR:
            return { ...state, messages: [] }

        default:
            return state
    }
}
