import { KPIConstants } from '../../../constants/kpi_constant'
import { KPIState, KPIAction, initialStateKPI } from './types'

export function kpiReducer(state = initialStateKPI, action: KPIAction): KPIState {
    switch (action.type) {
        case KPIConstants.GET_CONFIRMED_TRIPS_REQUEST:
            return { ...state, confirmTripsLoading: true }

        case KPIConstants.GET_CONFIRMED_TRIPS_SUCCESS:
            return { ...state, confirmTripsLoading: false, confirmTrips: action.confirmTrips || [] }

        case KPIConstants.GET_CONFIRMED_TRIPS_FAILURE:
            return {
                ...state,
                confirmTripsLoading: false,
                confirmTrips: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_RESERVATIONS_REQUEST:
            return { ...state, reservationsLoading: true }

        case KPIConstants.GET_RESERVATIONS_SUCCESS:
            return { ...state, reservationsLoading: false, reservations: action.reservations || [] }

        case KPIConstants.GET_RESERVATIONS_FAILURE:
            return {
                ...state,
                reservationsLoading: false,
                reservations: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_ALERTS_REQUEST:
            return { ...state, alertsLoading: true }

        case KPIConstants.GET_ALERTS_SUCCESS:
            return { ...state, alertsLoading: false, alerts: action.alerts || [] }

        case KPIConstants.GET_ALERTS_FAILURE:
            return { ...state, alertsLoading: false, alerts: [], error: action.error ?? null }

        case KPIConstants.GET_CUSTOMERS_REQUEST:
            return { ...state, customersLoading: true }

        case KPIConstants.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customersLoading: false,
                customers: action.customers || [],
                customersPerService: action.customersPerService || [],
            }

        case KPIConstants.GET_CUSTOMERS_FAILURE:
            return { ...state, customersLoading: false, customers: [], error: action.error ?? null }

        case KPIConstants.GET_DRIVERS_REQUEST:
            return { ...state, driversLoading: true }

        case KPIConstants.GET_DRIVERS_SUCCESS:
            return { ...state, driversLoading: false, drivers: action.drivers || [] }

        case KPIConstants.GET_DRIVERS_FAILURE:
            return { ...state, driversLoading: false, drivers: [], error: action.error || null }

        case KPIConstants.GET_DRIVER_REQUEST_REQUEST:
            return { ...state, driverRequestLoading: true }

        case KPIConstants.GET_DRIVER_REQUEST_SUCCESS:
            return {
                ...state,
                driverRequestLoading: false,
                driverRequest: action.driverRequest || [],
            }

        case KPIConstants.GET_DRIVER_REQUEST_FAILURE:
            return {
                ...state,
                driverRequestLoading: false,
                driverRequest: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_JOURNEYS_REQUEST:
            return { ...state, journeysLoading: true }

        case KPIConstants.GET_JOURNEYS_SUCCESS:
            return { ...state, journeysLoading: false, journeys: action.journeys || [] }

        case KPIConstants.GET_JOURNEYS_FAILURE:
            return { ...state, journeysLoading: false, journeys: [], error: action.error ?? null }

        case KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_REQUEST:
            return { ...state, confirmGroupedTripsLoading: true }

        case KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_SUCCESS:
            return {
                ...state,
                confirmGroupedTripsLoading: false,
                confirmGroupedTrips: action.confirmTrips || [],
            }

        case KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_FAILURE:
            return {
                ...state,
                confirmGroupedTripsLoading: false,
                confirmGroupedTrips: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_DRIVER_CALENDAR_REQUEST:
            return { ...state, driverCalendarLoading: true }

        case KPIConstants.GET_DRIVER_CALENDAR_SUCCESS:
            return {
                ...state,
                driverCalendarLoading: false,
                driverCalendar: action.driverCalendar || [],
            }

        case KPIConstants.GET_DRIVER_CALENDAR_FAILURE:
            return {
                ...state,
                driverCalendarLoading: false,
                driverCalendar: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_TRIP_BOOKING_REQUEST:
            return { ...state, tripBookingLoading: true }

        case KPIConstants.GET_TRIP_BOOKING_SUCCESS:
            return { ...state, tripBookingLoading: false, tripBooking: action.tripBooking || [] }

        case KPIConstants.GET_TRIP_BOOKING_FAILURE:
            return {
                ...state,
                tripBookingLoading: false,
                tripBooking: [],
                error: action.error ?? null,
            }

        case KPIConstants.GET_VEHICLE_GENERAL_DATA_REQUEST:
            return { ...state, vehicleGeneralDataLoading: true }

        case KPIConstants.GET_VEHICLE_GENERAL_DATA_SUCCESS:
            return {
                ...state,
                vehicleGeneralDataLoading: false,
                vehicleGeneralData: action.vehicleGeneralData || [],
            }

        case KPIConstants.GET_VEHICLE_GENERAL_DATA_FAILURE:
            return {
                ...state,
                vehicleGeneralDataLoading: false,
                vehicleGeneralData: [],
                error: action.error ?? null,
            }

        case KPIConstants.CLEAN_KPI:
            return { ...initialStateKPI }

        default:
            return state
    }
}
