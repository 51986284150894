import React from "react";
import Dashboard from "../dashboard";
import VehiclesView from "./page";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { vehiclesActions } from "../../redux/actions/vehicles_actions";
import { serviceActions } from "../../redux/actions/services_actions";

class Vehicles extends React.Component {

    componentDidMount() {
        const { vehicleReducer } = this.props;
        this.props.getAll(vehicleReducer.filters);
        this.props.getWeekDays();
        this.props.cleanVehicle();
    }

    render() {
        return (
            <Dashboard
                component={
                    <VehiclesView
                        {...this.props}
                    />
                } />
        );
    }
}

function mapState(state) {
    const { vehicleReducer, serviceReducer } = state;
    return { vehicleReducer, serviceReducer };
}

const actionCreators = {
    cleanVehicle: vehiclesActions.cleanVehicle,
    getAll: vehiclesActions.getAll,
    getById: vehiclesActions.getById,
    enable: vehiclesActions.enable,
    disable: vehiclesActions.disable,
    remove: vehiclesActions.remove,
    getWeekDays: serviceActions.getWeekDays,
    unassignDriverVehicle: vehiclesActions.unassignDriverVehicle,
    setVehicleFilter: vehiclesActions.setVehicleFilter,
    //getRoutesFromVehicleId: serviceActions.getRoutesFromVehicle
    saveTableFilters: vehiclesActions.saveTableFilters,
    cleanFilters: vehiclesActions.cleanFilters
};

export default compose(
    withTranslation("common"),
    connect(mapState, actionCreators)
)(Vehicles);
