import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const vehiclesService = {
    getAll,
    getById,
    insert,
    update,
    _delete,
    disable,
    enable,
    getVehiclesFromOTP,
    getVehiclesByService,
    getAllBrands,
    getAllModelsByBrand,
    getActiveVehiclesWithLocations,
    unassignDriverVehicle,
    getPrioritiesVehicle,
    setCalendar,
    getAllModelsByBrandString,
    validateExpedition,
    getCalendarDriversKPI,
    validateExpeditionRegular,
}

function setCalendar(calendar, idVehicle) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(calendar),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/api/otps/v1/Vehicles/SetCalendar?id=${idVehicle}&lang=en`,
        requestOptions,
    ).then(handleResponse)
}

function unassignDriverVehicle(vehicleId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/Vehicles/UnassignDriverVehicle?vehicleId=' +
            vehicleId,
        requestOptions,
    ).then(handleResponse)
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/getVehicles',
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    let lng = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/vehicles/getVehicleById?id=' +
            id +
            '&lang=' +
            lng,
        requestOptions,
    ).then(handleResponse)
}

function getAllBrands() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicleBrandModel/getAllVehicleBrands',
        requestOptions,
    ).then(handleResponse)
}

function getAllModelsByBrand(brandId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/vehicleBrandModel/getAllVehicleModelsByBrand?brandId=' +
            brandId,
        requestOptions,
    ).then(handleResponse)
}

function getAllModelsByBrandString(brand) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/vehicleBrandModel/getAllVehicleModelsByBrandString?brand=' +
            brand,
        requestOptions,
    ).then(handleResponse)
}

function getVehiclesFromOTP(otpId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/getVehiclesFromOtp?otpId=' + otpId,
        requestOptions,
    ).then(handleResponse)
}

function getVehiclesByService(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/vehicles/getVehiclesByService?serviceId=' +
            serviceId,
        requestOptions,
    ).then(handleResponse)
}

function getActiveVehiclesWithLocations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/getAllActiveVehiclesAndLocation',
        requestOptions,
    ).then(handleResponse)
}

function getPrioritiesVehicle() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    let lng = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/getVehiclePriority?lang=' + lng,
        requestOptions,
    ).then(handleResponse)
}

function validateExpedition(time, day, vehicleId, serviceId, expeditionMinutes) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/otps/v1/vehicles/validateExpedition?time=' +
            time +
            '&day=' +
            day +
            '&vehicleId=' +
            vehicleId +
            '&serviceId=' +
            serviceId +
            '&expeditionMinutes=' +
            expeditionMinutes,
        requestOptions,
    ).then(handleResponse)
}

function validateExpeditionRegular(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/validateRegularExpedition',
        requestOptions,
    ).then(handleResponse)
}

function insert(vehicle) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(vehicle),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/add',
        requestOptions,
    ).then(handleResponse)
}

function update(vehicle) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(vehicle),
    }
    const id = vehicle.id
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/update?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function enable(vehicleId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify({vehicleId: vehicleId}),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/enableVehicle?vehicleId=' + vehicleId,
        requestOptions,
    ).then(handleResponse)
}

function disable(vehicleId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify({vehicleId: vehicleId}),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/disableVehicle?vehicleId=' + vehicleId,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/vehicles/remove?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getCalendarDriversKPI() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/KPI/GetCalendarDriversKPI',
        requestOptions,
    ).then(handleResponse)
}
