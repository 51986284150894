import React from 'react'
import {
    Box,
    Container,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import { UserBookingInfo } from 'redux/reducers/service/types'

export default function ServiceUserList() {
    const classes = useStyles()
    const { t } = useTranslation()

    const { userList, isUserListPending } = useSelector((state: RootState) => state.serviceReducer)

    if (isUserListPending) {
        return <Skeleton variant={'rect'} style={{ minHeight: '250px' }} />
    }

    return (
        <Container className={classes.wrapper} maxWidth={false}>
            <h3 className={classes.title}>{t('services_comp.userList.title')}</h3>
            {userList.length > 0 ? (
                <UserBookingsTable users={userList} />
            ) : (
                <div>
                    <p>{t('services_comp.userList.emptyState')}</p>
                </div>
            )}
        </Container>
    )
}

const UserBookingsTable = ({ users }: { users: UserBookingInfo[] }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <TableContainer component={Box} className={classes.table}>
            <Table arial-label="reservation table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('services_comp.userList.header.cell1')}</TableCell>
                        <TableCell align="right">
                            {t('services_comp.userList.header.cell2')}
                        </TableCell>
                        <TableCell align="right">
                            {t('services_comp.userList.header.cell3')}
                        </TableCell>
                        <TableCell align="right">
                            {t('services_comp.userList.header.cell4')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(row => (
                        <TableRow key={row.customerId}>
                            <TableCell component="th" scope="row">
                                {row.customerName}
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="primary">
                                    {row.bookingsFromCustomerService
                                        ? row.bookingsFromCustomerService
                                        : 0}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="primary">
                                    {row.bookingsFromAPP ? row.bookingsFromAPP : 0}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="primary">
                                    {row.bookingsFromWebCustomer ? row.bookingsFromWebCustomer : 0}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles({
    wrapper: {
        display: 'grid',
        justifyContent: 'center',
        margin: '0 auto',
        padding: '20px 0',
    },

    title: {
        textAlign: 'center',
        fontWeight: 600,
    },

    table: {
        minWidth: 900,
    },

    header: {
        display: 'flex',
        gap: '12px',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'auto',
        padding: '0 16px 0 16px',
    },
    titleWrapper: {
        display: 'flex',
        gap: '12px',
    },
    svgWrapper: {
        background: '#D6ECF2',
        padding: '12px 5px',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subtitle: {
        color: '#868997',
    },

    total: {
        fontSize: '24px',
        fontWeight: 600,
    },
})
