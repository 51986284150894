import { createTheme } from '@material-ui/core/styles'

const theme = props => {
    return createTheme({
        //shadows: Array(25).fill('0.5'),
        spacing: 8,
        nameTenant: props.nameTenant,
        idTenant: props.idTenant,
        isAmtu: props.isAmtu,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        palette: {
            white: {
                main: '#FFFFFF',
            },
            black: {
                main: '#000000',
            },
            grey: {
                main: '#E0E0E0',
            },
            primary: {
                // light: will be calculated from palette.primary.main,
                main: props.primary,
                contrastText: '#FFFFFF',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                //light: '#0066ff',
                main: props.secondary,
                // dark: will be calculated from palette.secondary.main,
                contrastText: '#80cae9',
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
        typography: {
            fontFamily: 'Montserrat',
            fontSize: 12.5,
        },
    })
}

export default theme
