import React from 'react'
import MaterialTableExtended from '../../../material-table'
import AlertDialog from '../../../alert-dialog'
import { TextField } from '@material-ui/core'
import moment from 'moment'
import { green } from '@material-ui/core/colors'
import { Lens } from '@material-ui/icons'
import ErrorIcon from '@material-ui/icons/Error'

class PenaltiesAndAbsences extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openModalPenalties: false,
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModal = this.onClickAcceptModal.bind(this)
    }

    onClickCancelModal() {
        this.setState({ openModalPenalties: false })
    }

    onClickCheckUpdate(oldData, newData, absences, customer) {
        if (newData.absences > newData.maxAbsences) {
            this.setState({
                openModalPenalties: true,
                oldData: oldData,
                newData: newData,
                absences: absences,
                customer: customer,
            })
        } else {
            this.onClickAcceptModal(oldData, newData, absences, customer)
        }
    }

    onClickAcceptModal(oldData, newData, absences, customer) {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                const dataUpdate = [absences]
                const index = oldData.tableData.id
                dataUpdate[0][index] = newData
                this.props.setAbsences([...dataUpdate[0]], customer.id)
                resolve()
            }, 100)
        })
        this.setState({
            openModalPenalties: false,
        })
    }

    render() {
        const { t, customerReducer } = this.props
        let absences = customerReducer.customer ? customerReducer.customer.absences : []
        let customer = customerReducer.customer ? customerReducer.customer : null
        const readOnly = this.props.readOnly
        return (
            <React.Fragment>
                <MaterialTableExtended
                    title={t('customers.form.page.penalties.title')}
                    columns={[
                        {
                            title: t('customers.form.page.penalties.columns.service'),
                            field: 'serviceName',
                            editable: 'never',
                            align: 'left',
                            render: rowData =>
                                rowData.isInServicePenalty ? (
                                    // <Tooltip
                                    //     title={`${t('profile.shared.absencesAndPenalties.penaltyEndDate')} :
                                    //     ${moment(rowData.penalties.penaltyEndDate).local().format('DD/MM/HH hh:mm:ss')}`}
                                    //     arrow
                                    // >
                                    <div style={{ flexDirection: 'row' }}>
                                        <ErrorIcon
                                            color="error"
                                            style={{
                                                fontSize: 15,
                                                marginRight: 10,
                                            }}
                                        />
                                        <label>{rowData.serviceName}</label>
                                    </div>
                                ) : (
                                    // </Tooltip>
                                    <div style={{ flexDirection: 'row' }}>
                                        <Lens
                                            style={{
                                                fontSize: 15,
                                                color: green[500],
                                                marginRight: 10,
                                            }}
                                        />
                                        <label>{rowData.serviceName}</label>
                                    </div>
                                ),
                        },
                        {
                            title: t('customers.form.page.penalties.columns.maxAbsences'),
                            field: 'maxAbsences',
                            editable: 'never',
                            align: 'center',
                        },
                        {
                            title: t('customers.form.page.penalties.columns.absences'),
                            field: 'absences',
                            editable: 'onUpdate',
                            type: 'numeric',
                            align: 'center',
                            editComponent: props => (
                                <TextField
                                    defaultValue={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                            ),
                        },
                        {
                            title: t('customers.form.page.penalties.columns.penalties'),
                            field: 'isInServicePenalty',
                            editable: 'never',
                            align: 'center',
                            render: rowData =>
                                rowData.isInServicePenalty ? (
                                    <label>{t('customers.form.page.penalties.columns.yes')}</label>
                                ) : (
                                    <label>{t('customers.form.page.penalties.columns.no')}</label>
                                ),
                        },
                        {
                            title: t('customers.form.page.penalties.columns.penaltyEndDate'),
                            editable: 'never',
                            align: 'center',
                            field: 'isInServicePenalty',
                            render: rowData =>
                                rowData.isInServicePenalty ? (
                                    <label>
                                        {moment(rowData.currentPenaltyEndDate)
                                            .local()
                                            .format('DD/MM/YYYY HH:mm:ss')}
                                    </label>
                                ) : (
                                    <label>-</label>
                                ),
                        },
                        {
                            title: t('customers.form.page.penalties.columns.tot'),
                            editable: 'never',
                            align: 'center',
                            render: rowData => rowData.penalties.length,
                        },
                    ]}
                    editable={{
                        isEditHidden: rowData => rowData.isInServicePenalty || readOnly,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                this.onClickCheckUpdate(oldData, newData, absences, customer)
                                resolve()
                            }),
                        icon: 'edit',
                        isDeleteHidden: rowData => !rowData.isInServicePenalty || readOnly,
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [absences]
                                    const index = oldData.tableData.id
                                    const absencesId = oldData.id
                                    this.props.removeCurrentPenalty(absencesId)
                                    oldData.isInServicePenalty = false
                                    oldData.absences = oldData.maxAbsences
                                    oldData.penalties.pop()
                                    dataDelete[0][index] = oldData

                                    resolve()
                                }, 100)
                            }),
                    }}
                    data={absences}
                    pageSize={5}
                    actionsColumnIndex={6}
                />
                <AlertDialog
                    open={this.state.openModalPenalties}
                    title={t('customers.form.page.penalties.alert_dialog.titlePenalty')}
                    onClickAccept={() =>
                        this.onClickAcceptModal(
                            this.state.oldData,
                            this.state.newData,
                            this.state.absences,
                            this.state.customer,
                        )
                    }
                    onClickCancel={this.onClickCancelModal}
                />
            </React.Fragment>
        )
    }
}

export default PenaltiesAndAbsences
