import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const areaService = {
    getAll,
    getById,
    insert,
    update,
    _delete,
    getAllMunicipalities,
    getAllRegions,
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/areas/GetAreasWithZones',
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/areas/getAreaWithZonesById?areaId=' + id,
        requestOptions,
    ).then(handleResponse)
}

function insert(zone) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/areas/v1/areas/add', requestOptions).then(
        handleResponse,
    )
}

function update(zone) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    const id = zone.id
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/areas/update?areaId=' + id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/areas/remove?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getAllMunicipalities() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/Municipalities/GetMunicipalities',
        requestOptions,
    ).then(handleResponse)
}

function getAllRegions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/areas/v1/Regions/GetRegions',
        requestOptions,
    ).then(handleResponse)
}
