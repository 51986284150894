import { Action } from 'redux'

export const initialStateTenant: TenantState = {
    pending: false,
    results: [],
}

export interface TenantState {
    pending: boolean
    results: any[]
    tenant?: any
    error?: string
}

export interface TenantAction extends Action {
    type: string
    results?: any[]
    tenant?: any
    error?: string
}
