import React, { forwardRef, useEffect } from 'react'
import { Button, Grid, Hidden } from '@material-ui/core'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import i18next from 'i18next'
import es from 'date-fns/locale/es'
import en from 'date-fns/locale/en-US'
import ca from 'date-fns/locale/ca'
import { makeStyles } from '@material-ui/core/styles'
import EventIcon from '@material-ui/icons/Event'
import { useSelector } from 'react-redux'
import { DaysToggle } from '../../shared/daysToggle'

const useStyles = makeStyles({
    span: {
        fontWeight: 'bold',
        fontSize: '14.5px',
    },
})

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Grid item md={12}>
        <Button fullWidth endIcon={<EventIcon />} onClick={onClick} ref={ref} variant="contained">
            {value}
        </Button>
    </Grid>
))

const MultipleBookPage = ({
    weekDays,
    minDate,
    daysOfWeek,
    setDayOffWeek,
    endDate,
    setEndDate,
}) => {
    const classes = useStyles()

    useEffect(() => {
        let lng = i18next.language
        let mapToLocale = {
            es: es,
            en: en,
            ca: ca,
        }
        registerLocale(lng, mapToLocale[lng])
    }, [i18next.language])

    const multipleTripReducer = useSelector(state => state.multipleTripReducer)
    const daysToLimit = multipleTripReducer.maxRequestTripDays

    return (
        <Grid container spacing={2}>
            <Grid item md={4}>
                <span className={classes.span}>{i18next.t('trips.multipleBook.repeat')}</span>
            </Grid>
            <Grid item md={9} implementation="css" smDown component={Hidden} />

            <Grid item md={12}>
                <DaysToggle value={daysOfWeek} onChange={setDayOffWeek} weekDays={weekDays} />
            </Grid>

            <Grid item md={9} sm={9} xs={12}>
                <span className={classes.span}>{i18next.t('trips.multipleBook.end')}</span>
                <Grid item md={8} sm={10} xs={12}>
                    <div data-testId="datePicker-endDate" style={{ paddingTop: 20 }}>
                        <DatePicker
                            minDate={moment(minDate).toDate()}
                            locale={i18next.language}
                            customInput={<CustomInput />}
                            withPortal
                            autoComplete="off"
                            dateFormat="d MMMM yyyy"
                            selected={endDate}
                            maxDate={moment(minDate).add(daysToLimit, 'days').toDate()}
                            onChange={date => setEndDate(date)}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MultipleBookPage
