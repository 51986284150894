import React from 'react'
import { history } from '../../helpers/history'
import AlertDialog from '../alert-dialog'
import {
    Tooltip,
    Button,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import MaterialTableExtend from '../material-table'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import Spinner from '../shared/spinner'
import { allowAccessRole } from '../../helpers/roles'
import { withTheme } from '@material-ui/core/styles';
import { MODULES, OPERATIONS_ALLOWED } from '../roles/constants'

class OperatorsView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            openModal: false,
            openResetPasswordModal: false,
            openModalDisable: false,
            openModalEnable: false,
            item: {},
            user: {},
            languageSelected: '',
        }
        this.onClickCancelModal = this.onClickCancelModal.bind(this)
        this.onClickAcceptModalDelete = this.onClickAcceptModalDelete.bind(this)
        this.onClickAcceptModalDisable = this.onClickAcceptModalDisable.bind(this)
        this.onClickAcceptModalEnable = this.onClickAcceptModalEnable.bind(this)
    }

    addOnClick() {
        history.push('/operators/create')
    }

    onClickCancelModal() {
        this.setState({
            openModal: false,
            openResetPasswordModal: false,
            openModalDisable: false,
            openModalEnable: false,
        })
    }

    onClickAcceptModalDelete() {
        this.setState({ openModal: false })
        this.props.remove(this.state.item.id)
    }

    onClickOpenResetPasswordModal(data) {
        this.setState({ openResetPasswordModal: true, user: data })
    }

    onClickAcceptModalDisable() {
        this.setState({ openModalDisable: false })
        this.props.disable(this.state.item.id)
    }

    onClickAcceptModalEnable() {
        this.setState({ openModalEnable: false })
        this.props.enable(this.state.item.id)
    }

    onClickOpenModalEnable(data) {
        if (!data.isActive)
            this.setState({ openModalEnable: true, item: data })
    }

    onClickOpenModalDisable(data) {
        if (data.isActive)
            this.setState({ openModalDisable: true, item: data })
    }

    onClickOpenModal(data) {
        const { t } = this.props
        const tickets = () => (
            <React.Fragment>
                <Grid container>
                    {data.tickets.length > 0 && <Grid item md={12}>
                        <Typography>
                            {t('operators.page.alert_dialog.ticketsAssociated')}
                        </Typography>
                        <List>
                            {data.tickets.map((item) => (
                                <ListItem key={item}>
                                    <ListItemText>{item}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>}
                </Grid>
            </React.Fragment>
        )
        this.setState({ openModal: true, item: data, tickets: tickets })
    }

    onClickAcceptModalResetPassword = () => {
        this.props.sendResetPasswordEmail(this.state.user.email)
        this.setState({ openResetPasswordModal: false })
    }

    handleChangeLanguage = event => {
        this.setState({ languageSelected: event.target.value })
    }

    render() {
        const { operatorReducer, t } = this.props
        const Wrapper = () => (
            <React.Fragment>
                {allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage) && (
                    <Button
                        onClick={this.addOnClick}
                        variant="contained"
                        style={{ marginLeft: '24px' }}
                        color="primary"
                        size="large">
                        {t('operators.page.wrapper.add')}
                    </Button>
                )}
            </React.Fragment>
        )

        const Content = () => (
            <React.Fragment>
                <label style={{ marginTop: -15, display: 'flex' }}>
                    {t('operators.page.alert_dialog.sendInformation')}{' '}
                    {this.state.user.email}
                </label>
            </React.Fragment>
        )

        return (
            <React.Fragment>
                {!operatorReducer.pending ? (
                    <React.Fragment>
                        <MaterialTableExtend
                            buttons={<Wrapper />}
                            title={t('operators.page.title')}
                            setCurrentPage={this.props.setCurrentPage}
                            currentPage={operatorReducer.currentPage}
                            columns={[
                                {
                                    title: t('operators.page.columns.names'),
                                    field: 'fullName',
                                    render: rowData => {
                                        return rowData &&
                                            (rowData.hasPendingOTPId ||
                                                !rowData.hasSetPassword) &&
                                            rowData.email !== '' ? (
                                            <React.Fragment>
                                                {rowData &&
                                                    rowData.hasPendingOTPId && (
                                                        <Tooltip
                                                            title={t('operators.page.columns.tooltipHasPendingOTPId')}
                                                            arrow
                                                        >
                                                            <WarningIcon
                                                                htmlColor="orange"
                                                                style={{
                                                                    fontSize: 15,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                {rowData &&
                                                    !rowData.hasSetPassword && (
                                                        <Tooltip
                                                            title={t('operators.page.columns.tooltipHasSetPassword')}
                                                            arrow
                                                        >
                                                            <InfoIcon
                                                                color="primary"
                                                                style={{
                                                                    fontSize: 15,
                                                                    marginRight: 2,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                <label>
                                                    {rowData.fullName}
                                                </label>
                                            </React.Fragment>
                                        ) : (
                                            rowData.fullName
                                        )
                                    },
                                },
                                {
                                    title: t('operators.page.columns.document'),
                                    field: 'identityDocumentCode',
                                },
                                { title: 'id', field: 'id', export: false, hidden: true },
                                {
                                    title: t('operators.page.columns.phone'),
                                    field: 'phoneNumber',
                                },
                                {
                                    title: t('operators.page.columns.email'),
                                    field: 'email',
                                },
                            ]}
                            data={operatorReducer.results}
                            actionsColumnIndex={10}
                            actions={[
                                {
                                    icon: 'view_list',
                                    tooltip: t('operators.page.actions.detail'),
                                    onClick: (event, rowData) =>
                                        this.props.getById(rowData.id, true),
                                },
                                rowData => ({
                                    icon: 'link',
                                    tooltip: t('customers.page.actions.linkRecovery'),
                                    onClick: (event, rowData) =>
                                        this.props.getResetPasswordLink(rowData.email),
                                    hidden: rowData.email === '' || !allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'email_rounded',
                                    tooltip: t('operators.page.actions.sendResetPasswordEmail'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenResetPasswordModal(rowData),
                                    hidden: rowData.email === '' || !allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'toggle_off_icon',
                                    iconProps: { style: { color: 'black' } },
                                    tooltip: t('operators.page.actions.enable'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalEnable(rowData),
                                    hidden:
                                        rowData.isActive ||
                                        !allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'toggle_on_icon',
                                    iconProps: { style: { color: this.props.theme.palette.primary.main } },
                                    tooltip: t('operators.page.actions.disable'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModalDisable(rowData),
                                    hidden:
                                        !rowData.isActive ||
                                        !allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage),
                                }),
                                rowData => ({
                                    icon: 'delete',
                                    tooltip: t('operators.page.actions.delete'),
                                    onClick: (event, rowData) =>
                                        this.onClickOpenModal(rowData),
                                    hidden: !allowAccessRole(MODULES.BO_Profiles, OPERATIONS_ALLOWED.manage)
                                }),
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={`${t("operators.page.alert_dialog.titleDelete")} (${this.state.item.fullName})`}
                            onClickAccept={this.onClickAcceptModalDelete}
                            onClickCancel={this.onClickCancelModal}
                            content={this.state.tickets}
                        />
                        <AlertDialog
                            open={this.state.openModalDisable}
                            title={t('operators.page.alert_dialog.titleDisable')}
                            onClickAccept={this.onClickAcceptModalDisable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openModalEnable}
                            title={t('operators.page.alert_dialog.titleEnable')}
                            onClickAccept={this.onClickAcceptModalEnable}
                            onClickCancel={this.onClickCancelModal}
                        />
                        <AlertDialog
                            open={this.state.openResetPasswordModal}
                            title={t('operators.page.alert_dialog.titleResetPassword')}
                            onClickAccept={this.onClickAcceptModalResetPassword}
                            onClickCancel={this.onClickCancelModal}
                            content={Content}
                        />
                    </React.Fragment>
                ) : (
                    <Spinner loading={operatorReducer.pending} />
                )}
            </React.Fragment>
        )
    }
}

export default withTheme(OperatorsView)
