export const initialStateReport: ReportState = {
    pending: false,
    reports: [],
    report: null,
    periodicity: null,
    error: null,
}

export interface Report {
    id: string
    name: string
    description: string
    createdAt: string
    updatedAt?: string
}

export interface Periodicity {
    daily?: boolean
    weekly?: boolean
    monthly?: boolean
    [key: string]: any
}

export interface ReportState {
    pending: boolean
    reports: Report[]
    report?: Report | null
    periodicity?: Periodicity | null
    error?: string | null
}

export interface ReportAction {
    type: string
    results?: Report[] | Periodicity
    result?: Report
    id?: string
    error?: string
}
