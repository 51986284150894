import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import React from 'react'
import PersonalData from './personalData'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '40em',
        maxHeight: '40em',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

class FormRelatedCustomers extends React.Component {
    render() {
        const classes = this.props.styles
        const { t } = this.props
        return (
            <Dialog
                fullWidth
                open={this.props.open}
                onClose={this.props.closeModal}
                maxWidth={'lg'}
                classes={{ paper: classes.dialogPaper }}
                TransitionComponent={Transition}
            >
                <DialogTitle>{t('customers.form.page.relatedCustomer.title')}</DialogTitle>
                <DialogContent>
                    <PersonalData
                        withAction={true}
                        isModal={true}
                        closeModal={this.props.closeModal}
                        {...this.props}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeModal} color="primary">
                        {t('customers.form.page.goBack')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

function Hook(props) {
    const classes = useStyles()
    return <FormRelatedCustomers styles={classes} {...props} />
}

export default Hook
