import { driverConstants } from 'constants/driver_constants'
import { Driver } from 'model/Driver'

export const initialStateDriver: DriverState = {
    pending: false,
    results: [],
    allDrivers: [],
    driversForChat: [],
    driverFullName: [],
    currentPage: 0,
    modalToAssignDriver: false,
    driversToAssign: [],
    driversService: [],
    driversKPI: [],
    driversFilters: [],
    driver: null,
}

export interface DriverResult {
    driver: Driver
}

export interface DriverState {
    pending: boolean
    results: DriverResult[]
    allDrivers: Driver[]
    driversForChat: Driver[]
    driverFullName: string[]
    currentPage: number
    modalToAssignDriver: boolean
    driver: Driver | null
    driversToAssign: Driver[]
    driversService: Driver[]
    driversKPI: any[]
    driversFilters: any[]
    error?: string
}

export interface UpdateDriverStatusAction {
    type: typeof driverConstants.UPDATE_DRIVER_STATUS_SUCCESS
    id: string
    isValidated: boolean
    isActived: boolean
}

export interface DriverAction {
    type: string
    results?: DriverResult[] | Driver[] | string[]
    all?: Driver[]
    driver: Driver | null
    error?: string
    id?: string
    page?: number
    tableState?: any[]
    isValidated?: boolean
    isActived?: boolean
}
