import React from 'react'
import Dashboard from '../../dashboard'
import CustomerNotificationsPush from './CustomerNotificationsPush'

const CustomerNotifications = (props) => {
    

    return (
        <Dashboard
            component={
                <CustomerNotificationsPush
                    {...props}
                />
            }
        />
    )
}


export default (CustomerNotifications)