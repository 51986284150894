import { incidentConstants } from '../../../constants/incident_constants'
import { IncidentState, IncidentAction, initialStateIncident } from './types'

export function incidentReducer(
    state = initialStateIncident,
    action: IncidentAction,
): IncidentState {
    switch (action.type) {
        case incidentConstants.GET_ALL_REQUEST:
        case incidentConstants.GET_SPECIFIC_INCIDENT_REQUEST:
        case incidentConstants.ASIGN_INCIDENCE_TO_USER_REQUEST:
        case incidentConstants.CLOSE_INCIDENCE_REQUEST:
        case incidentConstants.REOPEN_INCIDENCE_REQUEST:
        case incidentConstants.GET_CAUSES_REQUEST:
        case incidentConstants.GET_PRIORITIES_REQUEST:
        case incidentConstants.GET_TICKET_STATUS_REQUEST:
        case incidentConstants.ADD_ACTIVITY_REQUEST:
        case incidentConstants.SET_INCIDENCE_PRIORITY_REQUEST:
        case incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_REQUEST:
        case incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_REQUEST:
        case incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_REQUEST:
        case incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST:
        case incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST:
        case incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_REQUEST:
        case incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_REQUEST:
        case incidentConstants.SET_PENDING_TO_REVIEW_REQUEST:
        case incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_REQUEST:
        case incidentConstants.GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_REQUEST:
        case incidentConstants.MODIFY_INCIDENCE_REQUEST:
            return { ...state, pending: true }

        case incidentConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                results: action.results || [],
                allIncidents: action.all || [],
                pending: false,
            }

        case incidentConstants.GET_SPECIFIC_INCIDENT_SUCCESS:
        case incidentConstants.MODIFY_INCIDENCE_SUCCESS:
            return {
                ...state,
                incident: action.incident,
                pending: false,
            }

        case incidentConstants.ASIGN_INCIDENCE_TO_USER_SUCCESS:
            return {
                ...state,
                userAsigned: action.results,
                pending: false,
            }

        case incidentConstants.CLOSE_INCIDENCE_SUCCESS:
        case incidentConstants.REOPEN_INCIDENCE_SUCCESS:
            return {
                ...state,
                results: action.results || [],
                pending: false,
            }

        case incidentConstants.GET_CAUSES_SUCCESS:
            return {
                ...state,
                causes: action.causes || [],
                pendingCauses: false,
            }

        case incidentConstants.GET_PRIORITIES_SUCCESS:
            return {
                ...state,
                priorities: action.priorities || [],
                pendingPriorities: false,
            }

        case incidentConstants.GET_TICKET_STATUS_SUCCESS:
            return {
                ...state,
                ticketStatus: action.status || [],
                pendingStatus: false,
            }

        case incidentConstants.ADD_ACTIVITY_SUCCESS:
            return {
                ...state,
                activity: action.activity,
                pending: false,
            }

        case incidentConstants.SET_INCIDENCE_PRIORITY_SUCCESS:
            return {
                ...state,
                priority: action.priority,
                pending: false,
            }

        case incidentConstants.GET_ALL_ACTIVITIES_IN_INCIDENCE_SUCCESS:
            return {
                ...state,
                activities: action.activities || [],
                pending: false,
            }

        case incidentConstants.RECEIVE_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                userWantsToReceiveEmails: action.userWantsToReceiveEmails ?? false,
                pending: false,
            }

        case incidentConstants.RECEIVE_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                userWantsToReceivePush: action.userWantsToReceivePush ?? false,
                pending: false,
            }

        case incidentConstants.GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                opUserWantsEmail: action.opUserWantsEmail ?? false,
                pending: false,
            }

        case incidentConstants.GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                customerWantsEmail: action.customerWantsEmail ?? false,
                pending: false,
            }

        case incidentConstants.GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                opUserWantsPush: action.opUserWantsPush ?? false,
                pending: false,
            }

        case incidentConstants.GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                customerWantsPush: action.customerWantsPush ?? false,
                pending: false,
            }

        case incidentConstants.SET_PENDING_TO_REVIEW_SUCCESS:
            return {
                ...state,
                pendingToReview: action.pendingToReview,
                pending: false,
            }

        case incidentConstants.IS_THERE_SOMETHING_TO_REVIEW_SUCCESS:
            return {
                ...state,
                isThereSomethingToReview: action.isThereSomethingToReview ?? false,
                pending: false,
            }

        case incidentConstants.SET_FILTER_INCIDENTS:
            return {
                ...state,
                incident_filterPriority:
                    action.prop === 'priority'
                        ? action.values ?? []
                        : state.incident_filterPriority,
                incident_filterTicketStatus:
                    action.prop === 'ticketStatus'
                        ? action.values ?? []
                        : state.incident_filterTicketStatus,
                incident_filterCreated:
                    action.prop === 'created' ? action.values ?? [] : state.incident_filterCreated,
                filters: action.filters,
            }

        case incidentConstants.SET_FILTER_DATE_INCIDENCES:
            return {
                ...state,
                filterStartDate:
                    action.typeDate === 'startDate' ? action.value : state.filterStartDate,
                filterEndDate: action.typeDate === 'endDate' ? action.value : state.filterEndDate,
            }

        default:
            return state
    }
}
