import { Customer, KPI, RelatedCustomer } from 'model/Customer'

export const initialStateCustomer: CustomerState = {
    pending: false,
    results: [],
    customer: null,
    relatedCustomers: [],
    customerAddresses: [],
    isSubmittingGDPR: false,
    favouriteAddresses: [],
    customersKPI: [],
    currentPage: 0,
    documentTypes: [],
    tableState: [],
    isMenuOpen: true,
    edit: false,
    add: false,
    userEdit: null,
}

export interface TableState {
    [key: string]: any
}

export interface CustomerState {
    pending: boolean
    results: Customer[]
    customer?: Customer | null
    relatedCustomers: RelatedCustomer[] | Customer[]
    customerAddresses: string[]
    isSubmittingGDPR: boolean
    favouriteAddresses: string[]
    customersKPI: KPI[]
    currentPage: number
    documentTypes: DocumentType[]
    tableState: TableState[]
    isMenuOpen: boolean
    error?: string | null
    GDPRurlFile?: string
    currentCustomer?: string
    countMax?: number
    fullName?: string
    address?: string
    name?: string
    surname?: string
    customerBirthDate?: string
    langNotification?: string
    customerPushToken?: string
    hasWheelChair?: boolean
    canOperate?: boolean
    add: boolean
    edit: boolean
    userEdit: any
}

export interface CustomerAction {
    type: string
    results?: Customer[] | KPI[] | DocumentType[]
    customer?: Customer
    absences?: any[]
    id?: string
    countMax?: number
    fullName?: string
    address?: string
    name?: string
    surname?: string
    favouriteAddresses?: string[]
    relatedCustomers?: RelatedCustomer[]
    customerDateOfBirth?: string
    lang?: string
    pushToken?: string
    hasWheelChair?: boolean
    result?: boolean
    data?: string | DocumentType[]
    error?: string
    tableState?: TableState[]
    page?: number
    typeOperation?: 'add' | 'edit'
    user?: any
    tripToReturn?: any
    isReturn?: boolean
}
