import { DelayedNotification, Notification } from 'model/Notification'

export const initialStateNotifications: NotificationState = {
    pending: false,
    isFetchLoading: false,
    isDeviationLoading: false,
    results: [],
    deviations: [],
    notificationTypes: [],
    uncheckedNotifications: 0,
}

export interface NotificationState {
    pending: boolean
    isFetchLoading: boolean
    isDeviationLoading: boolean
    results: Notification[]
    deviations: DelayedNotification[]
    notificationTypes: string[]
    uncheckedNotifications: number
    error?: string | null
}

export interface NotificationAction {
    type: string
    results?: Notification[] | DelayedNotification[] | number
    notificationTypes?: string[]
    notifications?: Notification[]
    idList?: string[]
    error?: string
}
