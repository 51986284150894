export const initialStateArea: AreaState = {
    pending: false,
    results: [],
    municipalities: [],
    regions: [],
}

export interface Area {
    id: string
    name: string
}

export interface AreaState {
    pending: boolean
    results: Area[]
    municipalities: Area[]
    regions: Area[]
    area?: Area
    error?: string
}

export interface AreaAction {
    type: string
    area?: Area
    results?: Area[]
    id?: string
    error?: string
}
