import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const chatService = {
    getAllConversationsBySenderId,
    removeConversation,
    createConversation,
    getAllMessagesfromConversation,
    sendMessage,
    deleteMessage,
    updateMessage,
    conversationReadMessages,
    getNumberOfUnreadMessagesBySenderId,
    getDriverPushToken,
}

function getDriverPushToken(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        `${stringConstants.URL_ApiGateway}/drivers/v1/Chat/GetDriverPushToken?driverId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getNumberOfUnreadMessagesBySenderId(senderId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Conversation/GetNumberOfUnreadMessagesBySenderId?senderId=${senderId}`,
        requestOptions,
    ).then(handleResponse)
}
function conversationReadMessages(conversationId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Conversation/ReadMessages?conversationId=${conversationId}`,
        requestOptions,
    ).then(handleResponse)
}

function updateMessage(body) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(body),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Message/${'messageId'}`,
        requestOptions,
    ).then(handleResponse)
}

function deleteMessage(messageId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Message/${messageId}`, //?lang=1
        requestOptions,
    ).then(handleResponse)
}

function sendMessage(body, language) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body),
        redirect: 'follow',
    }
    //myHeaders.append("Content-Type", "application/json");
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Message/SendMessage?lang=${language}`,
        requestOptions,
    ).then(handleResponse)
}

function getAllMessagesfromConversation(body) {
    const { senderId, receiverId } = body
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Message/GetAllMessagesFromSenderAndRecipient?senderId=${senderId}&recipientId=${receiverId}&includeRemovedEntries=false`,
        requestOptions,
    ).then(handleResponse)
}

function createConversation(body) {
    const { senderId, recipientId, senderName, recipientName } = body
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(body),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Conversation/CreateEmptyConversation?senderId=${senderId}&recipientId=${recipientId}&senderName=${senderName}&recipientName=${recipientName}`,
        requestOptions,
    ).then(handleResponse)
}

function removeConversation(conversationId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Conversation/RemoveConversation?id=${conversationId}`,
        requestOptions,
    ).then(handleResponse)
}

function getAllConversationsBySenderId(senderId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/chat/v1/Conversation/GetAllConversationsBySenderId?senderId=${senderId}`,
        requestOptions,
    ).then(handleResponse)
}
