export const notificationsConstants = {
    GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

    GET_DEVIATION_REQUEST: "GET_DEVIATION_REQUEST",
    GET_DEVIATION_SUCCESS: "GET_DEVIATION_SUCCESS",
    GET_DEVIATION_FAILURE: "GET_DEVIATION_FAILURE",

    TYPES_REQUEST: 'OPERATORS_TYPES_REQUEST',
    TYPES_SUCCESS: 'OPERATORS_TYPES_SUCCESS',
    TYPES_FAILURE: 'OPERATORS_TYPES_FAILURE',

    CHECK_REQUEST: 'NOTIFICATIONS_CHECK_REQUEST',
    CHECK_SUCCESS: 'NOTIFICATIONS_CHECK_SUCCESS',
    CHECK_FAILURE: 'NOTIFICATIONS_CHECK_FAILURE',

    CHECK_DEVIATION_REQUEST: 'CHECK_DEVIATION_REQUEST',
    CHECK_DEVIATION_SUCCESS: 'NOTIFICATIONS_DEVIATION_CHECK_SUCCESS',
    CHECK_DEVIATION_FAILURE: 'NOTIFICATIONS_DEVIATION_CHECK_FAILURE',

    GET_NOTIFICATIONS_COUNT: 'GET_NOTIFICATIONS_COUNT',

    REQUEST_TRIP_FROM_CALL: 'NOTIFICATIONS_REQUEST_TRIP_FROM_CALL',

    GET_CUSTOMER_NOTIFICATIONS_REQUEST: 'GET_CUSTOMER_NOTIFICATIONS_REQUEST',
    GET_CUSTOMER_NOTIFICATIONS_SUCCESS: 'GET_CUSTOMER_NOTIFICATIONS_SUCCESS',
    GET_CUSTOMER_NOTIFICATIONS_FAILURE: 'GET_CUSTOMER_NOTIFICATIONS_FAILURE',


    DELETE_LIST_REQUEST: 'DELETE_LIST_REQUEST',
    DELETE_LIST_SUCCESS: 'DELETE_LIST_SUCCESS',
    DELETE_LIST_FAILURE: 'DELETE_LIST_FAILURE',

    DELETE_DEVIATION_LIST_REQUEST: 'DELETE_DEVIATION_LIST_REQUEST',
    DELETE_DEVIATION_LIST_SUCCESS: 'DELETE_DEVIATION_LIST_SUCCESS',
    DELETE_DEVIATION_LIST_FAILURE: 'DELETE_DEVIATION_LIST_FAILURE',

    GET_DEVIATION_NOTIFICATION_TYPES_REQUEST: 'GET_DEVIATION_NOTIFICATION_TYPES_REQUEST',
    GET_DEVIATION_NOTIFICATION_TYPES_SUCCESS: 'GET_DEVIATION_NOTIFICATION_TYPES_SUCCESS',
    GET_DEVIATION_NOTIFICATION_TYPES_FAILURE: 'GET_DEVIATION_NOTIFICATION_TYPES_FAILURE',
}