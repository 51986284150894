import { useSelector } from "react-redux";
import { KPIS_PAGES } from "../../../constants/types";
import { CsvBuilder } from "../helpers/csvBuilder";
import i18next from "i18next";

const selectDataByPage = (state, page) => {
    switch (page) {
        case KPIS_PAGES.summary:
            return {
                reservations: state.kpiReducer.reservations,
                confirmTrips: state.kpiReducer.confirmTrips,
                alerts: state.kpiReducer.alerts,
                customers: state.kpiReducer.customers,
            };
        case KPIS_PAGES.trips:
            return {
                confirmTrips: state.kpiReducer.confirmTrips
            };
        case KPIS_PAGES.journey:
            return {
                journeys: state.kpiReducer.journeys
            };
        case KPIS_PAGES.reservations:
            return {
                reservations: state.kpiReducer.reservations,
                tripBooking: state.kpiReducer.tripBooking,
            };
        case KPIS_PAGES.users:
            return {
                customers: state.kpiReducer.customers,
                confirmTrips: state.kpiReducer.confirmTrips,
                isLoadingConfirmTrips: state.kpiReducer.confirmTripsLoading,
                isLoadingCustomers: state.kpiReducer.customersLoading,
            };
        case KPIS_PAGES.drivers:
            return {
                drivers: state.kpiReducer.drivers,
                driverCalendar: state.kpiReducer.driverCalendar,
                weekDays: state.serviceReducer.weekDays,
                journeys: state.kpiReducer.journeys,
                driverRequest: state.kpiReducer.driverRequest,
                confirmTrips: state.kpiReducer.confirmTrips,
            };
        case KPIS_PAGES.vehicles:
            return {
                vehicleGeneralData: state.kpiReducer.vehicleGeneralData,
                journeys: state.kpiReducer.journeys
            };
        case KPIS_PAGES.economyData:
            return {
                confirmTrips: state.kpiReducer.confirmTrips,
            };
        case KPIS_PAGES.originDestination:
            return {
                confirmGroupedTrips: state.kpiReducer.confirmGroupedTrips,
            }
        default:
            return {};
    }
};

export default function useDownloadCSV({ page }) {
    const { type, range, dateStart, dateEnd } = useSelector(state => state.kpiFiltersReducer)

    const data = useSelector(state => selectDataByPage(state, page));

    let csvString = ''
    const csvList = []

    switch (page) {
        case KPIS_PAGES.summary:
            csvList.push({ title: i18next.t("commandBox.trips.tripStateTable.title"), data: CsvBuilder.trips.buildTripStateTable(data.reservations) })
            csvList.push({ title: i18next.t("commandBox.trips.totalTripsChart.title"), data: CsvBuilder.trips.buildTotalTripsChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.summary.alertTable.title"), data: CsvBuilder.summary.buildAlertTable(data.alerts) })
            csvList.push({ title: i18next.t("commandBox.users.customersChart.title"), data: CsvBuilder.users.buildTotalCustomersChart(data.customers, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.summary.reservationTable.title"), data: CsvBuilder.reservations.buildReservationTable(data.reservations) })
            csvList.push({ title: i18next.t("commandBox.trips.timeSlotChart.title"), data: CsvBuilder.trips.buildTimeSlotChart(data.confirmTrips, type) })
            break;
        case KPIS_PAGES.trips:
            csvList.push({ title: i18next.t("commandBox.trips.totalTripsChart.title"), data: CsvBuilder.trips.buildTotalTripsChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.trips.punctualityTripsChart.title"), data: CsvBuilder.trips.buildPunctualityChart(data.confirmTrips, type) })
            csvList.push({ title: i18next.t("commandBox.trips.timeSlotChart.title"), data: CsvBuilder.trips.buildTimeSlotChart(data.confirmTrips, type) })
            csvList.push({ title: i18next.t("commandBox.trips.avgDistance.title"), data: CsvBuilder.trips.buildAvgDistanceChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            break;

        case KPIS_PAGES.journey:
            csvList.push({ title: i18next.t("commandBox.journeys.totalJourneys.title"), data: CsvBuilder.journeys.buildTotalJourneysChart(data.journeys, type, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.journeys.tripsJourney.title"), data: CsvBuilder.journeys.buildTripsJourneysChart(data.journeys, type) })
            csvList.push({ title: i18next.t("commandBox.journeys.numberPassengersChart.title"), data: CsvBuilder.journeys.buildNumberPassengersChart(data.journeys, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.journeys.journeysDistanceChart.title"), data: CsvBuilder.journeys.buildJourneysDistanceChart(data.journeys, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.journeys.journeysDurationChart.title"), data: CsvBuilder.journeys.buildJourneysDurationChart(data.journeys, type, range, dateStart, dateEnd) })
            break;
        case KPIS_PAGES.reservations:
            csvList.push({ title: i18next.t("commandBox.summary.reservationTable.title"), data: CsvBuilder.reservations.buildReservationTable(data.reservations) })
            csvList.push({ title: i18next.t("commandBox.trips.timeSlotChart.title"), data: CsvBuilder.reservations.buildReservationTimeSlotsChat(data.tripBooking, type) })
            break;
        case KPIS_PAGES.users:
            if (data.isLoadingConfirmTrips || data.isLoadingCustomers) break

            csvList.push({ title: i18next.t("commandBox.users.customersChart.title"), data: CsvBuilder.users.buildTotalCustomersChart(data.customers, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.users.tripsPerUser.title"), data: CsvBuilder.users.buildTripsPerUser(data.confirmTrips, data.customers, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.users.totalTripsByUserChart.title"), data: CsvBuilder.users.buildTotalTripByUserChart(data.confirmTrips, type) })
            break;

        case KPIS_PAGES.drivers:
            csvList.push({ title: i18next.t("commandBox.drivers.totalDriversChart.title"), data: CsvBuilder.driver.buildTotalDriversChart(data.drivers, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.drivers.timePerDriverChart.title"), data: CsvBuilder.driver.buildTimePerDriverChart(data.driverCalendar, data.weekDays) })
            csvList.push({ title: i18next.t("commandBox.journeys.journeysDistanceChart.title"), data: CsvBuilder.driver.buildDriverDistanceChart(data.journeys) })
            csvList.push({ title: i18next.t("commandBox.drivers.wheelJourneyDriversTable.title"), data: CsvBuilder.driver.buildWheelJourneyDriversTable(data.driverRequest) })
            csvList.push({ title: i18next.t("commandBox.drivers.driverTable.title"), data: CsvBuilder.driver.buildDriversJourneyTable(data.journeys, data.confirmTrips) })
            break
        case KPIS_PAGES.vehicles:
            csvList.push({ title: i18next.t("commandBox.vehicles.vehicleCapacityChart.title"), data: CsvBuilder.vehicle.buildVehicleCapacityChart(data.vehicleGeneralData) })
            csvList.push({ title: i18next.t("commandBox.vehicles.vehicleDistanceChart.title"), data: CsvBuilder.vehicle.buildVehicleDistanceChart(data.journeys) })
            break
        case KPIS_PAGES.economyData:
            csvList.push({ title: `${i18next.t("commandBox.economyData.costChart.title")} ${type}`, data: CsvBuilder.economyData.buildCostChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.economyData.totalCostChart.title"), data: CsvBuilder.economyData.buildTotalCostChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            csvList.push({ title: i18next.t("commandBox.economyData.userPayFeeChart.title"), data: CsvBuilder.economyData.buildUserPayFeeChart(data.confirmTrips, type, range, dateStart, dateEnd) })
            break
        case KPIS_PAGES.originDestination:
            csvList.push({ title: i18next.t("commandBox.originDestination.title"), data: CsvBuilder.originDestination.buildOriginDestinationChart(data.confirmGroupedTrips) })
    }



    return { csvString, csvList };
}