export const initialStateModule: ModulesState = {
    pending: false,
    results: [],
    error: null,
}

export interface Module {
    id: string
    name: string
}

export interface ModulesState {
    pending: boolean
    results: Module[]
    error: string | null
}

export interface ModulesAction {
    type: string
    results?: Module[]
    error?: string
}
