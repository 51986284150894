import i18next from 'i18next'
import { parsePhoneNumber } from 'libphonenumber-js'
import {
    validateSpanishId,
    spainIdType,
    validDNI,
    validNIE,
    validCIF,
} from 'spain-id'

const utils = {
    validateEmail,
    validateName,
    validateLastName,
    validateID,
    validatePassword,
    spainIDType,
    required,
    requiredNifCif,
    requiredObject,
    validateArray,
    validatePhone,
    validateInternationalPhone,
    validateCIF_Nif,
    validateNIF,
    validateCIF,
    validateMatricula,
    isEmptyObject,
    locationOutSideZone,
    validateMinLength,
    validateMaxLength,
    minValue,
    maxValue,
    calculateAge,
    validateIncidenceTitle,
    validateIncidenceDescription,
    validateIncidenceAddress,
    validateIncidenceOnBehalfOf,
    validateIncidenceCause,
    validateIncidenceCustomer,
    objectContainsKey,
    validateFloat
}

let NAME_REGEX =
    /^[a-zA-ZÀ-ÿ\-'`\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
let LASTNAME_REGEX =
    /^[a-zA-ZÀ-ÿ\-'`\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
let EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
let PASSWORD_REGEX =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
let PASSPORT_REGEX = /^[a-z]{3}[0-9]{6}[a-z]?$/i
let DNI_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
let NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
let PHONE_REGEX = /^(\+34|0034|34)?[ -]*(6|7|9)[ -]*([0-9][ -]*){8}$/
let MATRICULA_REGEX = /^([A-Z]{1,2})?\d{4}([A-Z]{2,3})$/

function validateEmail(email) {
    const regex = EMAIL_REGEX
    let result = regex.test(String(email.trim()).toLowerCase())
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.email') }
}

function validateName(name) {
    const regex = NAME_REGEX
    let result = regex.test(String(name.trim()))
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.name') }
}

function validateFloat(price) {
    let result = !isNaN(price)
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.number') }

}

function validateIncidenceTitle(title) {
    let result = title !== ''
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceTitle') }
}

function validateIncidenceDescription(description) {
    let result = description !== ''
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceDescription') }
}

function validateIncidenceAddress(address) {
    let result = address !== ''
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceAddress') }
}

function validateIncidenceCause(cause) {
    let result = cause !== ''
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceCause') }
}

function validateIncidenceCustomer(customer) {
    let result = customer !== ''
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceCustomer') }
}

function validateIncidenceOnBehalfOf(customer) {
    const regex = NAME_REGEX
    let result = regex.test(String(customer.trim()))
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.incidenceOnBehalfOf') }
}

function validateLastName(lastName) {
    const regex = LASTNAME_REGEX
    let result = regex.test(String(lastName.trim()))
    //console.log("result lastName => ", result)
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.lastName') }
}

function validatePassword(password) {
    const regex = PASSWORD_REGEX
    let result = regex.test(String(password.trim()))
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.password') }
}

function validateMinLength(field, min) {
    let result = field.trim().length >= min
    return result
        ? { result, message: '' }
        : {
            result,
            message:
                i18next.t('validations.register.minLength') + min.toString(),
        }
}

function validateMaxLength(field, max) {
    let result = field.trim().length <= max
    return result
        ? { result, message: '' }
        : {
            result,
            message:
                i18next.t('validations.register.maxLength') + max.toString(),
        }
}

function validateArray(array) {
    const result = array.length > 0
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.required') }
}

function locationOutSideZone() {
    return { result: false, message: i18next.t('zones.stops.stopNotFound') }
}

function objectContainsKey(object, key) {
    const result = Object.keys(object).includes(key)
    return { result: result, message: result ? '' : i18next.t('validations.almostOnePriceTypology') }
}

function minValue(field, min) {
    let result = parseFloat(field.toString().trim()) >= min
    return result
        ? { result, message: '' }
        : {
            result,
            message:
                i18next.t('validations.register.minValue') + min.toString(),
        }
}

function maxValue(field, max) {
    let result = parseFloat(field.toString().trim()) <= max
    return result ? { result, message: '' } : { result, message: (i18next.t('validations.register.maxValue')) + max.toString() }
}

function validateInternationalPhone(phone, countryCode, dialCode) {
    const validPhone = { result: true, message: '' }
    const invalidPhone = { result: false, message: i18next.t('validations.register.phone') }
    if (!countryCode || !dialCode || !phone)
        return invalidPhone
    try {
        const countryCodeResult = countryCode.toUpperCase()
        const phoneNumber = parsePhoneNumber(phone, countryCodeResult);
        if (phoneNumber.isValid())
            return validPhone
        return invalidPhone
    } catch (e) {
        return invalidPhone
    }
}

function validatePhone(phone) {
    const regex = PHONE_REGEX
    let result = regex.test(String(phone.trim()))
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.register.phone') }
}


function validateDNI(dni, isNIE) {
    let numero = dni.substr(0, dni.length - 1)
    let letr = dni.substr(dni.length - 1, 1)
    numero = numero % 23
    let letra = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letra = letra.substring(numero, numero + 1)
    if (letra !== letr.toUpperCase()) {
        if (isNIE == null) {
            console.log('DNI erroneo, la letra del NIF no se corresponde')
        } else {
            console.log('NIF erroneo, la letra del NIF no se corresponde')
        }
        return {
            result: false,
            message: i18next.t('validations.register.document'),
        }
    } else {
        if (isNIE == null) {
            console.log('DNI correcto')
        } else {
            console.log('NIE correcto')
        }
        return { result: true, message: '' }
    }
}

function validateMatricula(matricula) {
    const regex = MATRICULA_REGEX
    let result = regex.test(String(matricula.trim()))
    return result
        ? { result, message: '' }
        : { result, message: i18next.t('validations.matricula') }
}

function validateCIF_Nif(value) {
    let result =
        validCIF(value.trim()) ||
        validDNI(value.trim()) ||
        validNIE(value.trim())
    return result
        ? { result: true, message: '' }
        : { result: false, message: i18next.t('validations.register.document') }
}

export function validateNIF(value) {
    let result = validDNI(value.trim()) || validNIE(value.trim());
    return result
        ? { result: true, message: '' }
        : { result: false, message: i18next.t('validations.register.document') };
}

export function validateCIF(value) {
    const regex = /^[A-Z0-9]{1,9}$/;
    if (!value || regex.test(value)) {
        return { result: true, message: '' };
    } else {
        return { result: false, message: 'Debe introducir un CIF válido' };
    }
}

function validatePassport(passport) {
    console.log('Pasaporte. No se puede validar ya que no hay métodos.')
    return { result: true, message: '' }
}

function required(value, callback = null) {
    if (!value && value !== null && value.length === 0) {
        return { result: false, message: i18next.t('validations.required') }
    } else {
        if (callback) {
            return callback
        }
        return { result: true, message: '' }
    }
}

function requiredNifCif(value, callback = null) {
    if (!value && value !== null && value.length === 0) {
        return { result: false, message: i18next.t('validations.requiredNifCif') }
    } else {
        if (callback) {
            return callback
        }
        return { result: true, message: '' }
    }
}

function requiredObject(value, callback = null) {
    if (value === null || value === "" || Object.keys(value).length === 0) {
        return { result: false, message: i18next.t('validations.required') }
    } else {
        if (callback) {
            return callback
        }
        return { result: true, message: '' }
    }
}

function validateNIE(nie) {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = nie.charAt(0)
    switch (nie_prefix) {
        case 'X':
            nie_prefix = 0
            break
        case 'Y':
            nie_prefix = 1
            break
        case 'Z':
            nie_prefix = 2
            break
        default:
            return {
                result: false,
                message: i18next.t('validations.register.document'),
            }
    }
    let result = validateDNI(nie_prefix + nie.substr(1), true)
    return result
}

function spainIDType(str) {
    if (str.match(DNI_REGEX)) {
        return 'DNI'
    }
    if (str.match(PASSPORT_REGEX)) {
        //pendent
        return 'PASSPORT'
    }
    if (str.match(NIE_REGEX)) {
        return 'NIE'
    }
    return 'INVALID'
}

function getDocumentTypeString(type) {
    switch (Number(type)) {
        case 10:
            return "DNI";
        case 20:
            return "NIE";
        case 30:
            return "PASSPORT";
        case 40:
            return "FAMILYBOOK";
        default:
            return "";
    }
}

function validateID(id, documentType) {
    if (id == null) return {
        result: false,
        message: i18next.t('validations.register.document'),
    }

    let type;

    if (documentType) {
        type = getDocumentTypeString(documentType);
    } else {
        type = spainIDType(id.trim())
    }

    console.log('ID Type => ', type)
    switch (type) {
        case 'DNI':
            //'we\'ll call validateDNI() and return the result'
            return validateDNI(id.trim())
        case 'NIE':
            //'we\'ll call validateNIE() and return the result'
            return validateNIE(id.trim())
        case 'PASSPORT':
            //'we\'ll call validatePassport() and return the result'
            return validatePassport(id.trim())
        case 'FAMILYBOOK':
            // Same call as validatePassport as they basically do the same.
            return validatePassport(id.trim())
        default:
            return {
                result: false,
                message: i18next.t('validations.register.document'),
            }
    }
}

function calculateAge(birthDate, minAge) {
    let from = birthDate.split('-')
    let birthdateTimeStamp = new Date(from[0], from[1] - 1, from[2])
    let cur = new Date()
    let diff = cur - birthdateTimeStamp
    // This is the difference in milliseconds
    let currentAge = Math.floor(diff / 31557600000)
    // Divide by 1000*60*60*24*365.25

    return currentAge < minAge
        ? {
            result: false,
            message: i18next.t('validations.register.birthDate'),
        }
        : { result: true, message: '' }
}

function isEmptyObject(obj) {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) return false
    }

    return true
}

export default utils