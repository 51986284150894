import React from 'react'
import MaterialTableExtended from '../../../material-table'
import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddBox'
import FormRelatedCustomers from './formRelatedCustomers'

class ListRelatedCustomers extends React.Component {
    constructor(props) {
        super()
        this.addOnClick = this.addOnClick.bind(this)
        this.editOnClick = this.editOnClick.bind(this)
    }

    addOnClick() {
        this.props.typeOperation('add')
        this.props.openModal()
    }

    editOnClick(item) {
        this.props.typeOperation('edit', item)
        this.props.openModal()
    }

    render() {
        const { t, customerReducer } = this.props
        const translations = t('material_table.tableStrings', {
            returnObjects: true,
        })

        const data = customerReducer?.relatedCustomers?.map(element => (
            {
                name: element.name,
                surname1: element.surname1,
                email: element.email,
                identityDocumentCode: element.identityDocumentCode,
                phoneNumber: `${element.phone.prefix}${element.phone.phoneNumber}`
            }
        ))

        const Wrapper = () => (
            <React.Fragment>
                <Tooltip
                    title={t('customers.form.page.relatedCustomer.wrapper.add')}
                >
                    <IconButton onClick={this.addOnClick}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        )
        return (
            <React.Fragment>
                <MaterialTableExtended
                    title={t('customers.form.page.relatedCustomer.title')}
                    localization={translations}
                    buttons={!this.props.readOnly ? <Wrapper /> : null}
                    pageSize={5}
                    actionsColumnIndex={7}
                    columns={[
                        {
                            title: t(
                                'customers.form.page.relatedCustomer.columns.name',
                            ),
                            field: 'name',
                        },
                        {
                            title: t(
                                'customers.form.page.relatedCustomer.columns.surname',
                            ),
                            field: 'fullSurname',
                        },
                        {
                            title: t(
                                'customers.form.page.relatedCustomer.columns.email',
                            ),
                            field: 'email',
                        },
                        {
                            title: t(
                                'customers.form.page.relatedCustomer.columns.document',
                            ),
                            field: 'identityDocumentCode',
                        },
                        {
                            title: t(
                                'customers.form.page.relatedCustomer.columns.phone',
                            ),
                            field: 'phoneNumber',
                        },
                    ]}
                    data={data || []}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: t(
                                'customers.form.page.relatedCustomer.actions.edit',
                            ),
                            hidden: this.props.readOnly,
                            onClick: (event, rowData) =>
                                this.editOnClick(rowData),
                        },
                        {
                            icon: 'cancel',
                            tooltip: t(
                                'customers.form.page.relatedCustomer.actions.remove',
                            ),
                            hidden: this.props.readOnly,
                            onClick: (event, rowData) => {
                                this.props.removeRelatedCustomer(rowData)
                            },
                        },
                    ]}
                />
                <FormRelatedCustomers
                    open={this.props.open}
                    closeModal={this.props.closeModal}
                    t={this.props.t}
                    saveRelatedCustomer={this.props.saveRelatedCustomer}
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

export default ListRelatedCustomers
