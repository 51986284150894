import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTableExtend from '../../material-table'
import Spinner from '../../shared/spinner'
import moment from 'moment'
import { notificationActions } from '../../../redux/actions/notification_actions'
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { getDataFromColumnRow } from '../../../helpers/tools'

const CustomerNotificationsPush = ({ match }) => {
    const { t } = useTranslation()
    
    const { customerId } = match.params
    const dispatch = useDispatch()
    const notificationReducer = useSelector(state => state.notificationReducer)

    useEffect(() => {
        dispatch(notificationActions.getCustomerNotifications(customerId))
    }, [customerId, dispatch])

    const obj = {}
    notificationReducer.notificationTypes.forEach(element => {
        obj[element.id] = element.name
    })

    if (notificationReducer.pending) {
        return <Spinner loading={notificationReducer.pending} />
    }

    const language = i18next.language

    const columns = [
        {
            title: t('notifications.page.columns.title'),
            field: 'title',
            customFilterAndSearch: (term, rowData) => {
                switch (language) {
                    case 'ca':
                        return rowData.titleCa.toLowerCase().includes(term.toLowerCase())
                    case 'es':
                        return rowData.titleEs.toLowerCase().includes(term.toLowerCase())
                    case 'en':
                        return rowData.titleEn.toLowerCase().includes(term.toLowerCase())
                    default:
                        return rowData.titleEn.toLowerCase().includes(term.toLowerCase())
                }
            },
            render: rowData => {
                switch (language) {
                    case 'ca':
                        return rowData.titleCa
                    case 'es':
                        return rowData.titleEs
                    case 'en':
                        return rowData.titleEn
                    default:
                        return rowData.titleEn
                }
            }
        },
        {
            title: t('notifications.page.columns.date'),
            field: 'notificationDate',
            customFilterAndSearch: (term, rowData) =>
                rowData.notificationDate
                    ? moment(rowData.notificationDate).format('DD/MM/YYYY HH:mm').includes(term)
                    : '',
            render: rowData =>
                rowData.notificationDate
                    ? `${moment(rowData.notificationDate).format('DD/MM/YYYY HH:mm')}`
                    : '',
        },
        {
            title: t('notifications.page.columns.message'),
            field: 'body',
            customFilterAndSearch: (term, rowData) => {
                switch (language) {
                    case 'ca':
                        return rowData.bodyCa.toLowerCase().includes(term.toLowerCase())
                    case 'es':
                        return rowData.bodyEs.toLowerCase().includes(term.toLowerCase())
                    case 'en':
                        return rowData.bodyEn.toLowerCase().includes(term.toLowerCase())
                    default:
                        return rowData.bodyEn.toLowerCase().includes(term.toLowerCase())
                }
            },
            render: rowData => {
                switch (language) {
                    case 'ca':
                        return rowData.bodyCa
                    case 'es':
                        return rowData.bodyEs
                    case 'en':
                        return rowData.bodyEn
                    default:
                        return rowData.bodyEn
                }
            }
        },
        {
            title: t('notifications.page.columns.service'),
            field: 'service',
        },
        {
            title: t('notifications.page.columns.line'),
            field: 'line',
        },
        {
            title: t('notifications.page.columns.typeAutMan'),
            field: 'typeAutMan',
            lookup: obj,
        },
    ]

    const exportPdf = (columns, data) => {
        const doc = new jsPDF({ orientation: 'l' })

        columns = columns.filter(columnDef => !columnDef.hidden && columnDef.field && columnDef.export !== false)

        const columnTitles = columns.map(columnDef => columnDef.title)
        
        const pdfData = data.map(rowData =>
            columns.map(columnDef => {
                let value = getDataFromColumnRow(columnDef, rowData)

                return value
            })
        )

        const title = t('notifications.page.title2')
        doc.text(title, 15, 8, { baseline: 'top' })
        doc.autoTable({
            head: [columnTitles],
            body: pdfData,
            didDrawPage: function (data) {
                doc.setFontSize(20)
                doc.setTextColor(40)
            }
        })

        doc.save(`${title}.pdf`)
    }

    return (
            <MaterialTableExtend
                title={t('notifications.page.title2')}
                columns={columns}
                data={notificationReducer.results}
                actionsColumnIndex={4}
                exportPdf={exportPdf}
            />
    )
}

export default CustomerNotificationsPush