import { journeyConstants } from '../../../constants/journey_constants'
import { initialStateJourney, JourneyAction } from './types'

export function journeyReducer(state = initialStateJourney, action: JourneyAction) {
    switch (action.type) {
        case journeyConstants.SET_JOURNEY_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case journeyConstants.SET_JOURNEY_SUCCESS:
            return {
                ...state,
                journey: action.journey,
                isLoading: false,
            }

        case journeyConstants.SET_JOURNEY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }

        default:
            return state
    }
}
