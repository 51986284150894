export const driverConstants = {
    GET_FOR_CHAT_REQUEST: 'DRIVER_GET_DRIVERS_FOR_CHAT_REQUEST',
    GET_FOR_CHAT_SUCCESS: 'DRIVER_GET_DRIVERS_FOR_CHAT_SUCCESS',
    GET_FOR_CHAT_FAILURE: 'DRIVER_GET_DRIVERS_FOR_CHAT_FAILURE',

    GET_FULLNAME_REQUEST: 'DRIVER_GET_FULLNAME_REQUEST',
    GET_FULLNAME_SUCCESS: 'DRIVER_GET_FULLNAME_SUCCESS',
    GET_FULLNAME_FAILURE: 'DRIVER_GET_FULLNAME_FAILURE',

    ADD_REQUEST: 'DRIVER_REGISTER_REQUEST',
    ADD_SUCCESS: 'DRIVER_REGISTER_SUCCESS',
    ADD_FAILURE: 'DRIVER_REGISTER_FAILURE',

    DETAIL_REQUEST: 'DRIVER_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'DRIVER_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'DRIVER_DETAIL_FAILURE',

    GETALL_REQUEST: 'DRIVER_GETALL_REQUEST',
    GETALL_SUCCESS: 'DRIVER_GETALL_SUCCESS',
    GETALL_FAILURE: 'DRIVER_GETALL_FAILURE',

    GETBYID_REQUEST: 'DRIVER_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'DRIVER_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'DRIVER_GETBYID_FAILURE',

    DELETE_REQUEST: 'DRIVER_DELETE_REQUEST',
    DELETE_SUCCESS: 'DRIVER_DELETE_SUCCESS',
    DELETE_FAILURE: 'DRIVER_DELETE_FAILURE',

    DISABLE_REQUEST: 'DRIVER_DISABLE_REQUEST',
    DISABLE_SUCCESS: 'DRIVER_DISABLE_SUCCESS',
    DISABLE_FAILURE: 'DRIVER_DISABLE_FAILURE',

    ENABLE_REQUEST: 'DRIVER_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'DRIVER_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'DRIVER_ENABLE_FAILURE',

    VALIDATE_REQUEST: 'DRIVER_VALIDATE_REQUEST',
    VALIDATE_SUCCESS: 'DRIVER_VALIDATE_SUCCESS',
    VALIDATE_FAILURE: 'DRIVER_VALIDATE_FAILURE',

    UPDATE_PSW_REQUEST: 'DRIVER_UPDATE_PSW_REQUEST',
    UPDATE_PSW_SUCCESS: 'DRIVER_UPDATE_PSW_SUCCESS',
    UPDATE_PSW_FAILURE: 'DRIVER_UPDATE_PSW_FAILURE',

    EDIT_REQUEST: 'DRIVER_EDIT_REQUEST',
    EDIT_SUCCESS: 'DRIVER_EDIT_SUCCESS',
    EDIT_FAILURE: 'DRIVER_EDIT_FAILURE',

    RESET_PSW_REQUEST: 'RESET_PSW_REQUEST',
    RESET_PSW_SUCCESS: 'RESET_PSW_SUCCESS',
    RESET_PSW_FAILURE: 'RESET_PSW_FAILURE',

    CHECK_DRIVERS_TO_ASSING_REQUEST: 'CHECK_DRIVERS_TO_ASSING_REQUEST',
    CHECK_DRIVERS_TO_ASSING_SUCCESS: 'CHECK_DRIVERS_TO_ASSING_SUCCESS',
    CHECK_DRIVERS_TO_ASSING_FAILURE: 'CHECK_DRIVERS_TO_ASSING_FAILURE',

    CHECK_DRIVERS_SERVICES_REQUEST: 'CHECK_DRIVERS_SERVICES_REQUEST',
    CHECK_DRIVERS_SERVICES_SUCCESS: 'CHECK_DRIVERS_SERVICES_SUCCESS',
    CHECK_DRIVERS_SERVICES_FAILURE: 'CHECK_DRIVERS_SERVICES_FAILURE',

    DELETE_PUSH_TOKEN_REQUEST: 'DRIVER_DELETE_PUSH_TOKEN_REQUEST',
    DELETE_PUSH_TOKEN_SUCCESS: 'DRIVER_DELETE_PUSH_TOKEN_SUCCESS',
    DELETE_PUSH_TOKEN_FAILURE: 'DRIVER_DELETE_PUSH_TOKEN_FAILURE',

    CHECK_DRIVERS_CLOSE: 'CHECK_DRIVERS_TO_ASSING_CLOSE',

    CLEAN_DRIVERS_FULLNAME: 'CLEAN_DRIVERS_FULLNAME',
    CLEAN_SERVICES_NAME: 'CLEAN_SERVICES_NAME',
    DRIVER_SET_PAGE_TABLE: 'DRIVER_SET_TABLE_PAGE',

    GET_REQUEST_DRIVERS_SERVICES_REQUEST: 'GET_REQUEST_DRIVERS_SERVICES_REQUEST',
    GET_REQUEST_DRIVERS_SERVICES_SUCCESS: 'GET_REQUEST_DRIVERS_SERVICES_SUCCESS',
    GET_REQUEST_DRIVERS_SERVICES_FAILURE: 'GET_REQUEST_DRIVERS_SERVICES_FAILURE',

    GET_DRIVERS_KPI_REQUEST: 'GET_DRIVERS_KPI_REQUEST',
    GET_DRIVERS_KPI_SUCCESS: 'GET_DRIVERS_KPI_SUCCESS',
    GET_DRIVERS_KPI_FAILURE: 'GET_DRIVERS_KPI_FAILURE',

    SAVE_TABLE_FILTERS: 'DRIVERS_SAVE_TABLE_FILTERS',
    UPDATE_DRIVER_STATUS_SUCCESS: 'UPDATE_DRIVER_STATUS_SUCCESS',
}
