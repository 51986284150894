import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import { Paper, Typography } from '@material-ui/core';
import { getDataFromColumnRow } from '../../helpers/tools';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash'


const MaterialTableExtend = ({ columns, data, title, buttons, toolBar, fixedColumns, alignButtonsCenter, exportCsv, exportPdf, selection, actionsColumnIndex, setCurrentPage, deleteText, deleteTooltip, ...props }) => {
    const [pageSize, setPageSize] = useState(5);
    const [key, setKey] = useState(0);
    const table = useRef();

    useEffect(() => {
        if (data?.length > 0) {
            const calculatePageSize = (length) => {
                if (length <= 5) return 5;
                if (length <= 10) return 10;
                if (length <= 20) return 20;
                if (length <= 30) return 30;
                if (length <= 40) return 40;
                if (length <= 50) return 50;
                if (length <= 60) return 60;
                if (length <= 70) return 70;
                if (length <= 80) return 80;
                if (length <= 90) return 90;
                return 100;
            };

            const newPageSize = calculatePageSize(data.length);
            if (newPageSize !== pageSize) {
                setPageSize(newPageSize);
                setKey(prevKey => prevKey + 1);
            }
        }
    }, [data]);

    const Title = ({ title }) => (
        <Typography style={{ fontWeight: 500, margin: '20px 0' }} variant='h5' component='h2'>
            {title}
        </Typography>
    );

    let translations = i18next.t('material_table.tableStrings', { returnObjects: true }) || {};
    if (deleteText && deleteTooltip) {
        translations.body = {
            ...translations.body,
            deleteTooltip: deleteTooltip,
            editRow: { deleteText: deleteText }
        };
    }

    return (
        <div style={{ overflowX: 'auto' }}>
            <MaterialTable
                key={key}
                {...props}
                columns={columns}
                title={<Title title={title} />}
                data={data}
                tableRef={(ref) => (table.current = ref)}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            {buttons}
                            {toolBar}
                        </>
                    ),
                    Container: (props) => <Paper {...props} elevation={0} />,
                }}
                localization={translations}
                options={{
                    filtering: true,
                    exportButton: true,
                    fixedColumns: fixedColumns || null,
                    actionsCellStyle: alignButtonsCenter
                        ? { margin: '0 auto', flexGrow: 1, justifyContent: 'center', border: 'none' }
                        : null,
                    exportCsv: (columns, renderData) => {
                        if (exportCsv) {
                            exportCsv(columns, renderData, table.current);
                        } else {
                            const filteredColumns = columns.filter(col => col.field && col.export !== false);
                            new CsvBuilder(`${title}.csv`)
                                .setColumns(filteredColumns.map(col => col.title))
                                .addRows(renderData.map(rowData => filteredColumns.map(col => getDataFromColumnRow(col, rowData))))
                                .exportFile();
                        }
                    },
                    exportPdf: (columns, renderData) => {
                        if (exportPdf) {
                            exportPdf(columns, renderData, table.current);
                        } else {
                            const doc = new jsPDF({ orientation: 'l' });
                            const filteredColumns = columns.filter(col => col.field && col.export !== false);
                            const columnTitles = filteredColumns.map(col => col.title);
                            const pdfData = renderData.map(rowData =>
                                filteredColumns.map(col => getDataFromColumnRow(col, rowData))
                            );
                            doc.text(title, 15, 8, { baseline: 'top' });
                            doc.autoTable({ 
                                head: [columnTitles], 
                                body: pdfData,
                                styles: {
                                    overflow: 'linebreak', 
                                    valign: 'middle',     
                                    fontSize: 9,         
                                },
                            });
                            doc.save(`${title}.pdf`);
                        }
                    },
                    initialPage: 0,
                    selection: selection,
                    actionsColumnIndex: actionsColumnIndex,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    pageSize: pageSize,
                    rowStyle: rowData => ({
                        backgroundColor: rowData.tableData.checked ? 'rgb(228, 229, 229)' : 'inherit',
                        whiteSpace: 'pre-line'
                    }),
                    headerStyle: { whiteSpace: 'pre-line' }
                }}
                onChangePage={(page) => setCurrentPage?.(page)}
            />
        </div>
    );
};

MaterialTableExtend.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    buttons: PropTypes.node,
    toolBar: PropTypes.node,
    fixedColumns: PropTypes.object,
    alignButtonsCenter: PropTypes.bool,
    exportCsv: PropTypes.func,
    exportPdf: PropTypes.func,
    selection: PropTypes.bool,
    actionsColumnIndex: PropTypes.number,
    setCurrentPage: PropTypes.func,
    deleteText: PropTypes.string,
    deleteTooltip: PropTypes.string,
};

export default React.memo(MaterialTableExtend, (prevProps, nextProps) => prevProps.columns === nextProps.columns || isEqual(prevProps.data, nextProps.data))
