import { servicesConstants } from 'constants/services_constants'
import { ExcludedPeriod, initialStateVehicle, VehicleAction, VehicleState } from './types'
import { vehiclesConstants } from 'constants/vehicles_constants'

export function vehicleReducer(
    state: VehicleState = initialStateVehicle,
    action: VehicleAction,
): VehicleState {
    switch (action.type) {
        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_REQUEST:
            return { ...state, currentAvailableVehicleCapacity: null }

        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_SUCCESS:
            return { ...state, currentAvailableVehicleCapacity: action.results?.length || 0 }

        case servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_FAILURE:
            return { ...state, currentAvailableVehicleCapacity: null }

        case vehiclesConstants.REMOVE_EXCLUDE_DAYS: {
            const filtered =
                state.vehicle?.excludedPeriods?.filter((_, i) => i !== action.index) || []
            return {
                ...state,
                vehicle: { ...state.vehicle, excludedPeriods: filtered },
            }
        }

        case vehiclesConstants.SET_EXCLUDE_DAYS: {
            const updatedPeriods = [...(state.vehicle?.excludedPeriods || []), action.element]
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    excludedPeriods: updatedPeriods.filter(
                        (period): period is ExcludedPeriod => period !== undefined,
                    ),
                },
                ranges: [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
            }
        }

        case vehiclesConstants.SET_RANGE_CALENDAR:
            return { ...state, ranges: action.range || [] }

        case vehiclesConstants.DETAIL_REQUEST:
        case vehiclesConstants.GETALL_REQUEST:
        case vehiclesConstants.ADD_REQUEST:
        case vehiclesConstants.EDIT_REQUEST:
        case vehiclesConstants.GETBYOTP_REQUEST:
        case vehiclesConstants.DELETE_REQUEST:
        case vehiclesConstants.GETALL_BRAND_REQUEST:
        case vehiclesConstants.GET_MODELS_BYBRAND_REQUEST:
        case vehiclesConstants.GETALL_LOCATION_REQUEST:
        case vehiclesConstants.GET_PRIORITY_REQUEST:
        case vehiclesConstants.GETBYSERVICE_REQUEST:
        case vehiclesConstants.GET_VEHICLES_GENERAL_DATA_KPI_REQUEST:
            return { ...state, pending: true }

        case vehiclesConstants.DETAIL_SUCCESS:
            return { ...state, pending: false, vehicle: action.vehicle }

        case vehiclesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results || [],
                allVehicles: action.all || [],
            }

        case vehiclesConstants.ADD_SUCCESS:
            return { ...state, pending: false, otp: action.otp }

        case vehiclesConstants.EDIT_SUCCESS:
            return { ...state, pending: false, operator: action.operator }

        case vehiclesConstants.ENABLE_SUCCESS:
            return {
                ...state,
                results: state.results.map(vehicle =>
                    vehicle.id === action.id ? { ...vehicle, disabled: false } : vehicle,
                ),
            }

        case vehiclesConstants.DISABLE_SUCCESS:
            return {
                ...state,
                results: state.results.map(vehicle =>
                    vehicle.id === action.id ? { ...vehicle, disabled: true } : vehicle,
                ),
            }

        case vehiclesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(v => v.id !== action.id),
            }

        case vehiclesConstants.GETALL_BRAND_SUCCESS:
            return { ...state, pending: false, brands: action.brands || [] }

        case vehiclesConstants.GET_MODELS_BYBRAND_SUCCESS:
            return { ...state, pending: false, models: action.models || [] }

        case vehiclesConstants.GETALL_LOCATION_SUCCESS:
            return { ...state, pending: false, locations: action.locations || [], error: undefined }

        case vehiclesConstants.GET_PRIORITY_SUCCESS:
            return {
                ...state,
                pending: false,
                priorities: action.priorities || [],
                error: undefined,
            }

        case vehiclesConstants.SET_FILTER_VEHICLE:
            return {
                ...state,
                filterBrand: action.prop === 'brand' ? action.values || [] : state.filterBrand,
                filterModel: action.prop === 'model' ? action.values || [] : state.filterModel,
                filterOTP: action.prop === 'OTP' ? action.values || [] : state.filterOTP,
                filters: action.filters,
            }

        case vehiclesConstants.CLEAN_FILTER_VEHICLE:
            return {
                ...state,
                filterBrand: [],
                filterModel: [],
                filterOTP: [],
                tableFilters: [],
                filters: null,
            }

        case vehiclesConstants.CLEAR_VEHICLE:
            return { ...state, vehicle: null }

        case vehiclesConstants.GETBYSERVICE_SUCCESS:
        case vehiclesConstants.GETBYOTP_SUCCESS:
            return { ...state, pending: false, results: action.vehicles || [] }

        case vehiclesConstants.GET_VEHICLES_GENERAL_DATA_KPI_SUCCESS:
            return { ...state, pending: false, vehiclesGeneralDataKPI: action.results || [] }

        case vehiclesConstants.CLEAN_VEHICLE_STATE:
            return { ...state, vehicle: { excludedPeriods: [] } }

        case vehiclesConstants.SAVE_TABLE_FILTERS:
            return { ...state, tableFilters: action.tableFilters || [] }

        default:
            return state
    }
}
