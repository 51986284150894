import moment from 'moment'
import { multipleTripConstants } from '../../../constants/multipleTrip_constants'
import { customerConstants } from '../../../constants/customer_constants'
import { tripConstants } from '../../../constants/trip_constants'
import {
    MultipleTripState,
    MultipleTripAction,
    TripResult,
    initialStateMultipleTrip,
} from './types'

export function multipleTripReducer(
    state = initialStateMultipleTrip,
    action: MultipleTripAction,
): MultipleTripState {
    switch (action.type) {
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST:
        case multipleTripConstants.GET_TRIPS_MULTIPLE_REQUEST:
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST:
        case multipleTripConstants.CANCEL_TRIP_REQUEST:
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_REQUEST:
            return { ...state, pending: true }

        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                executeMultipleTrips: true,
                openModalSelectFrecuency: false,
                previewTrip:
                    action.results && 'previewTrip' in action.results
                        ? action.results.previewTrip
                        : undefined,
                results: Array.isArray(action.results)
                    ? action.results
                    : action.results?.results || [],
                selectedTripsToReturn: Array.isArray(action.results)
                    ? action.results.map((item: TripResult) => ({
                          id: item.tripId,
                          checked: false,
                      }))
                    : [],
            }

        case multipleTripConstants.GET_TRIPS_MULTIPLE_SUCCESS:
            return {
                ...state,
                pending: false,
                executeMultipleTrips: true,
                openModalSelectFrecuency: false,
                results: action.results as TripResult[],
                selectedTripsToReturn: (action.results as TripResult[]).map(item => ({
                    id: item.tripId,
                    checked: false,
                    schedulePickUpTime: item.requestPickUpTime,
                })),
            }

        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE:
        case multipleTripConstants.GET_TRIPS_MULTIPLE_FAILURE:
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE:
        case multipleTripConstants.CANCEL_TRIP_FAILURE:
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }

        case multipleTripConstants.SET_PREVIEW_TRIP:
            return { ...state, previewTrip: action.trip }

        case customerConstants.SET_CUSTOMER:
            return {
                ...state,
                executeMultipleTrips: false,
                results: [],
                selectedTripsToReturn: [],
            }

        case multipleTripConstants.UPDATE_SELECTED_TRIPS:
            return { ...state, selectedTripsToReturn: action.selectedValues || [] }

        case multipleTripConstants.OPEN_MODAL_SELECT_FRECUENCY:
            return {
                ...state,
                executeMultipleTrips: false,
                openModalSelectFrecuency: action.value as boolean,
                tripIdToPlanOutbound: action.tripId as string,
                requestPickUpStartTime: action.requestPickUpStartTime ?? null,
                serviceType: action.serviceType,
                maxRequestTripDays: action.maxRequestTimeDays,
            }

        case multipleTripConstants.SHOW_MODAL_DETAILS:
            return { ...state, openModalShowResults: action.value as boolean }

        case multipleTripConstants.SET_TRIP_ID_PLAN:
            return { ...state, tripIdToPlanOutbound: action.value as string }

        case multipleTripConstants.UPDATE_LIST_TRIPS_ITEM:
            return {
                ...state,
                pending: false,
                results: state.results?.filter(item => item.tripId !== action.tripId),
            }

        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS: {
            const updatedResults = action.result
                ? state.results?.map(item =>
                      item.tripId === action.tripIdToRetry ? action.result! : item,
                  )
                : state.results

            return { ...state, pending: false, results: updatedResults }
        }

        case multipleTripConstants.UPDATE_SCHEDULE_RETURN_PROCESS:
            return {
                ...state,
                areInReturnProcess: action.value as boolean,
                outboundTripId: action.value ? state.outboundTripId : undefined,
                selectedTripsToReturn: action.value
                    ? state.selectedTripsToReturn
                    : state.selectedTripsToReturn.map(item => ({
                          ...item,
                          checked: false,
                      })),
            }

        case tripConstants.SET_REQUEST_TRIP_INFO:
            return { ...state, outboundTripId: action.outboundTripId }

        case multipleTripConstants.UPDATE_TRIPS_TO_LIST_EXIST:
            return {
                ...state,
                results: [...(state.results as TripResult[]), ...(action.array || [])].sort(
                    (a, b) =>
                        moment(a.requestPickUpTime).unix() - moment(b.requestPickUpTime).unix(),
                ),
            }

        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_SUCCESS: {
            const firstReturnTrip =
                (Array.isArray(action.results)
                    ? action.results
                    : action.results?.results || []
                ).find(item => item.tripResponse !== null)?.tripId ?? null
            return {
                ...state,
                pending: false,
                idOriginalReturnTrip: firstReturnTrip,
                openModalSelectFrecuency: false,
            }
        }

        case multipleTripConstants.CLEAN_RESULTS:
            return { ...state, results: [], executeMultipleTrips: false }

        case multipleTripConstants.CLEAN_MULTIPLE_TRIPS:
            return initialStateMultipleTrip

        case multipleTripConstants.SET_ORIGINAL_OUTBOUND_TRIP:
            return { ...state, idOriginalOutboundTrip: action.tripId }

        case multipleTripConstants.SET_ORIGINAL_RETURN_TRIP:
            return { ...state, idOriginalReturnTrip: action.tripId }

        default:
            return state
    }
}
