import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import moment from 'moment'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const tripService = {
    getAll,
    getAllKPI,
    getById,
    insert,
    _delete,
    getTripsByCustomerId,
    cancelTrip,
    acceptTrip,
    rejectTrip,
    getTripServices,
    customerCanPerformTrip,
    getTripStatuses,
    modifyTrip,
    acceptModifyTrip,
    getTrips,
    getTripsPendingToDriver,
    confirmTripVehicle,
    cancelTripByDriverUnavailable,
    vehicleHasTripsPendingWithService,
    getConfirmedTripsKPI,
    processMultipleTrip,
    processMultipleTripRetry,
    delegateTripVehicle,
    getTripsFromMultipleRequest,
    processMultipleTripReturn,
    processMultipleTripOutboundAndReturn,
    getTripWithReturn,
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/services/v1/trips', requestOptions).then(
        handleResponse,
    )
}

function getAllKPI(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/KPI/getTripsKPI' +
            '?startDate=' +
            encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
        requestOptions,
    ).then(handleResponse)
}

function getTripsPendingToDriver(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const url = '/services/v1/Trips/getRejectedByAllDriversTrips'
    if ((startDate != null && startDate != '') & (finishDate != null && finishDate != ''))
        return customFetch(
            stringConstants.URL_ApiGateway +
                url +
                '?startDate=' +
                encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
                '&finishDate=' +
                encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    else
        return customFetch(stringConstants.URL_ApiGateway + url, requestOptions).then(
            handleResponse,
        )
}

function getTrips(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    if ((startDate != null && startDate != '') & (finishDate != null && finishDate != ''))
        return customFetch(
            stringConstants.URL_ApiGateway +
                '/services/v1/Trips/GetTrips' +
                '?startDate=' +
                encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
                '&finishDate=' +
                encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    else
        return customFetch(
            stringConstants.URL_ApiGateway + '/services/v1/Trips/GetTrips',
            requestOptions,
        ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/trips/GetTripById?tripId=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getTripsFromMultipleRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            `/services/v1/trips/getTripsFromMultipleRequest?tripId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getTripsByCustomerId(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/trips/getTripsFromCustomer?customerId=' +
            customerId,
        requestOptions,
    ).then(handleResponse)
}

function vehicleHasTripsPendingWithService(vehicleId, serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/trips/vehicleHasTripsPendingWithService?vehicleId=' +
            vehicleId +
            '&serviceId=' +
            serviceId,
        requestOptions,
    ).then(handleResponse)
}

function getTripStatuses() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/tripStatus/TripStatuses?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function customerCanPerformTrip(customerId, pickUpStartTime, relatedCustomerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    let url = `${stringConstants.URL_ApiGateway}/services/v1/trips/customerCanPerformTrip?customerId=${customerId}&pickupStartTime=${pickUpStartTime}`
    if (relatedCustomerId != '') {
        url = url.concat(`&relatedCustomerId=${relatedCustomerId}`)
    }
    return customFetch(url, requestOptions).then(handleResponse)
}

function insert(trip, customerId, serviceId, noRestriction, isRetry) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(trip),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            `/services/v1/trips/requestTrip?customerId=${customerId}&serviceId=${serviceId}&lang=${lang}&noRestriction=${noRestriction}&isRetry=${isRetry}`,
        requestOptions,
    ).then(handleResponse)
}

function processMultipleTrip(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTrip`,
        requestOptions,
    ).then(handleResponse)
}

async function processMultipleTripOutboundAndReturn(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/Trips/ProcessMultipleTripOutboundAndReturn`,
        requestOptions,
    ).then(handleResponse)
}

function processMultipleTripReturn(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTripReturn`,
        requestOptions,
    ).then(handleResponse)
}

function processMultipleTripRetry(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/processMultipleTripRetry`,
        requestOptions,
    ).then(handleResponse)
}

function modifyTrip(trip, customerId, serviceId) {
    // delete trip.pickUpLocation.id
    // delete trip.dropOffLocation.id
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(trip),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/trips/modifyTrip?customerId=' +
            customerId +
            '&serviceId=' +
            serviceId +
            '&lang=' +
            lang,
        requestOptions,
    ).then(handleResponse)
}

function getTripServices(trip, customerId, noRestriction) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(trip),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/trips/getTripServices?customerId=' +
            customerId +
            '&noRestriction=' +
            noRestriction +
            '&lang=' +
            lang,
        requestOptions,
    ).then(handleResponse)
}

function cancelTrip(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/TripStatus/SetTripToCancelledByCustomerService?id=' +
            id,
        requestOptions,
    ).then(handleResponse)
}

function cancelTripByDriverUnavailable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/TripStatus/SetTripToCancelledByCustomerServiceWheel?id=' +
            id,
        requestOptions,
    ).then(handleResponse)
}

function acceptTrip(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/TripStatus/SetTripToAcceptedByCustomerService?id=' +
            id,
        requestOptions,
    ).then(handleResponse)
}

function confirmTripVehicle(tripId, vehicleId, driverId, driverName) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            `/services/v1/Trips/ConfirmTripVehicle?tripId=${tripId}&driverId=${driverId}&vehicleId=${vehicleId}&driverName=${driverName}&lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function delegateTripVehicle(tripId, vehicleId, driverId, driverName) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
            `/services/v1/Trips/DelegateTripVehicle?tripId=${tripId}&driverId=${driverId}&vehicleId=${vehicleId}&driverName=${driverName}&lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function acceptModifyTrip(id, oldId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/Trips/AcceptModifyTrip?newTripId=' +
            id +
            '&oldTripId=' +
            oldId,
        requestOptions,
    ).then(handleResponse)
}

function rejectTrip(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/TripStatus/SetTripToRejectedByCustomerService?id=' +
            id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/trips/remove?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getConfirmedTripsKPI(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/KPI/GetConfirmedTripsKPI' +
            '?startDate=' +
            encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
        requestOptions,
    ).then(handleResponse)
}

async function getTripWithReturn(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/trips/getTripWithReturn?tripId=${id}`,
        requestOptions,
    ).then(handleResponse)
}
