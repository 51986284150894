import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import moment from 'moment'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const incidentService = {
    getAll,
    getSpecificIncident,
    asignIncidenceToUser,
    setIncidenceStatus,
    getPriorities,
    getCauses,
    getTicketStatus,
    addActivity,
    setIncidencePriority,
    closeIncidence,
    getAllActivitiesInIncidence,
    receiveEmailNotifications,
    receivePushNotifications,
    getOpUserWantsEmailNotifications,
    getOpUserWantsPushNotifications,
    getCustomerWantsEmailNotifications,
    getCustomerWantsPushNotifications,
    setPendingToReview,
    isThereSomethingToReview,
    subscribeToTopic,
    getNumberOfTicketsToReviewFromOpUser,
    getCustomerById,
    isertIncidence,
    addImagesToIncidence,
    modifyIncidence,
}

function getAll(startDate = null, finishDate = null) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    if ((startDate != null) & (finishDate != null))
        return customFetch(
            stringConstants.URL_ApiGateway +
                '/ticketing/v1/Ticketing/GetAllTickets' +
                '?startDate=' +
                encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
                '&finishDate=' +
                encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    else
        return customFetch(
            stringConstants.URL_ApiGateway + '/ticketing/v1/Ticketing/GetAllTickets',
            requestOptions,
        ).then(handleResponse)
}

function getSpecificIncident(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/GetTicketByTicketId?ticketId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function asignIncidenceToUser(incidence) {
    console.log(incidence)
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Status/AsignToUser?opUserId=${incidence.opId}&opUserName=${incidence.opName}&ticketId=${incidence.id}`,
        requestOptions,
    ).then(handleResponse)
}

function setIncidenceStatus(body) {
    let lang = i18next.language
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Status/SetTicketStatus?ticketId=${body.id}&ticketStatus=${body.ticketStatus}&lang=${lang}&receiveEmail=${body.receiveEmail}&receiveNotifications=${body.receiveNotifications}&pushToken=${body.pushToken}$email=${body.email}`,
        requestOptions,
    ).then(handleResponse)
}

function getPriorities() {
    let lang = i18next.language
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/Priorities?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function getCauses() {
    let lang = i18next.language
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/Causes?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function getTicketStatus() {
    let lang = i18next.language
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Status/TicketStatus?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function closeIncidence(body) {
    let lang = i18next.language
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Status/FinishTicket?ticketId=${body.id}&lang=${lang}&solution=${body.solution}&email=${body.email}&pushToken=${body.pushToken}&receiveEmail=${body.receiveEmail}&receiveNotifications=${body.receiveNotifications}`,
        requestOptions,
    ).then(handleResponse)
}

function setIncidencePriority(ticketId, priorityId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/setTicketPriority?ticketId=${ticketId}&priority=${priorityId}`,
        requestOptions,
    ).then(handleResponse)
}

function addActivity(body) {
    let lang = i18next.language

    const requestBody = {
        userName: body.userName,
        ticketId: body.ticketId,
        userType: body.userType,
        body: body.description,
        lang: lang,
        customerReceiveEmail: body.customerReceiveEmail,
        customerReceiveNotifications: body.customerReceiveNotifications,
        opUserReceiveEmail: body.opUserReceiveEmail,
        opUserReceiveNotifications: body.opUserReceiveNotifications,
        customerEmail: body.customerEmail,
        opUserEmail: body.opUserEmail,
        customerPushToken: body.customerPushToken,
        isPublic: body.isPublic,
    }

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Activity/AddActivity`,
        requestOptions,
    ).then(handleResponse)
}

function receiveEmailNotifications(opUserId, userWantsToReceiveEmails) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/opusers/v1/Ticketing/UpdateReceiveTicketingEmails?opUserId=${opUserId}&receiveMails=${userWantsToReceiveEmails}`,
        requestOptions,
    ).then(handleResponse)
}

function receivePushNotifications(opUserId, userWantsToReceiveNotifications) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/opusers/v1/Ticketing/UpdateReceiveTicketingNotifications?opUserId=${opUserId}&receiveNotifications=${userWantsToReceiveNotifications}`,
        requestOptions,
    ).then(handleResponse)
}

function getOpUserWantsEmailNotifications(opUserId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/opusers/v1/Ticketing/WantsTicketingEmails?opUserId=${opUserId}`,
        requestOptions,
    ).then(handleResponse)
}

function getOpUserWantsPushNotifications(opUserId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/opusers/v1/Ticketing/WantsTicketingNotifications?opUserId=${opUserId}`,
        requestOptions,
    ).then(handleResponse)
}

function getCustomerWantsEmailNotifications(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/customers/v1/Ticketing/WantsTicketingEmails?customerId=${customerId}`,
        requestOptions,
    ).then(handleResponse)
}

function getCustomerWantsPushNotifications(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/customers/v1/Ticketing/WantsTicketingNotifications?customerId=${customerId}`,
        requestOptions,
    ).then(handleResponse)
}

function getAllActivitiesInIncidence(ticketId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Activity/GetAllActivitiesInTicket?ticketId=${ticketId}`,
        requestOptions,
    ).then(handleResponse)
}

function setPendingToReview(ticketId, pendingToReview) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Activity/SetPendingToReview?ticketId=${ticketId}&pendingToReview=${pendingToReview}`,
        requestOptions,
    ).then(handleResponse)
}

function isThereSomethingToReview() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Activity/TicketingRequiresReview`,
        requestOptions,
    ).then(handleResponse)
}

function subscribeToTopic(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/opusers/v1/Ticketing/SubscribeToTopic?token=${token}`,
        requestOptions,
    ).then(handleResponse)
}

function getNumberOfTicketsToReviewFromOpUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Activity/GetNumberOfTicketsToReviewFromOpUser`,
        requestOptions,
    ).then(handleResponse)
}

function getCustomerById(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/customers/v1/Customers/${customerId}`,
        requestOptions,
    ).then(handleResponse)
}

function isertIncidence(body) {
    let lang = i18next.language
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/CreateTicket?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function addImagesToIncidence(images, incidenceId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(images),
    }

    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Files/AddImagesToTicket?ticketId=${incidenceId}`,
        requestOptions,
    )
        .then(handleResponse)
        .then(response => {
            return response
        })
}

function modifyIncidence(body) {
    const req = {
        subject: body.subject,
        body: body.description,
        priority: body.priority,
        ticketStatus: body.ticketStatus,
        customerId: body.customerId,
        customerName: body.customerName,
        location: body.location,
        cause: body.cause,
        opUserId: body.opUserId,
        opUserName: body.opUserName,
        comments: body.comments,
        // opUserReporterName: body.opUserReporterName,
        // createdByOpUser: body.createdByOpUser
    }
    let lang = i18next.language
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/ticketing/v1/Ticketing/UpdateTicket?ticketId=${body.id}&lang=${lang}&receiveEmail=${body.receiveEmail}&receiveNotifications=${body.receiveNotifications}&pushToken=${body.pushToken}&email=${body.email}&solution=${body.resolution}`,
        requestOptions,
    ).then(handleResponse)
}
