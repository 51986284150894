import moment from 'moment'
import i18next from 'i18next'
import { isDifferentYear } from '../../../helpers/dates'
import { calculateAVGFromBody, fillBodyWithZeros, formatJourneyData, getBodyWithTranslations, getFilterByName, getGroupedTrip, getKeyStringByRange, groupBy, orderChartBody, translateDateKey } from './utils'
import { DRIVER_REQUEST_STATUS, KPI_RANGE_TYPES } from '../../../constants/types'

// m a km
const conversionFactor = 1000

const getChartName = groupedBy => {
    let chartNameList = {
        municipalities: i18next.t('commandBox.groupedBy.municipalities'),
        regions: i18next.t('commandBox.groupedBy.regions'),
        otps: i18next.t('commandBox.groupedBy.otps'),
        services: i18next.t('commandBox.groupedBy.services'),
        serviceName: i18next.t('commandBox.groupedBy.services'),
        tenants: i18next.t('commandBox.groupedBy.tenants'),
    }
    return chartNameList[groupedBy]
}

export function getJourneysTripsKPI(journeys, filterBy, range, dateStart, dateEnd, isFilterByOne) {
    if (!journeys?.length) return { data: [] }

    const data = {}
    let total = 0

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((elem) => {
        let filter = isFilterByOne
            ? getKeyStringByRange(elem.date, range, printYear)
            : getFilterByName(elem, filterBy)

        total += elem.numTrips

        if (data.hasOwnProperty(filter)) {
            const entry = data[filter].filter((e) => e.numTrips === elem.numTrips)[0]
            if (!entry) {
                data[filter].push({ numTrips: elem.numTrips, rep: 1 })
            } else {
                entry.rep += 1
            }
        } else {
            data[filter] = [{ numTrips: elem.numTrips, rep: 1 }]
        }

    })

    const translate = isFilterByOne || range === KPI_RANGE_TYPES.year

    let res = formatJourneyData(data, filterBy, translate)

    if (translate) {
        const body = res.slice(1)
        res = [res[0], ...getBodyWithTranslations(body, range)]
    }

    return { data: res, total }

}

export function getJourneyPassengers(journeys, filterBy, range, dateStart, dateEnd) {
    if (!journeys?.length) return { total: 0, data: [] };
    const header = [i18next.t(`commandBox.${filterBy.toLowerCase()}`)]
    let body = []
    let totalPassengers = 0;
    let totalTrips = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((journey) => {
        if (!journey.date) return { total: 0, data: [] };

        totalPassengers += journey.numPassengers;
        totalTrips += journey.numTrips;

        const keyString = getKeyStringByRange(journey.date, range, printYear);
        const filterByName = getFilterByName(journey, filterBy);

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill(0)];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = (row[index] || 0) + journey.numPassengers / journey.numTrips;
            }
        });
    });

    body = fillBodyWithZeros(body, header.length)
    let orderBody = getBodyWithTranslations(orderChartBody(body), range)

    const chart = [header, ...orderBody];
    return { total: totalPassengers / totalTrips, data: chart };

}

export function getJourneyKms(journeys, filterBy, range, dateStart, dateEnd, isFilterByOne) {
    if (!journeys?.length) return { data: [] }

    const data = {};
    let total = 0;
    let totalJourneys = 0;
    const chartData = [[i18next.t(`commandBox.${filterBy.toLowerCase()}`), i18next.t("commandBox.journeys.journeysDistanceChart.label")]];

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((elem) => {
        let filter = isFilterByOne
            ? getKeyStringByRange(elem.date, range, printYear)
            : getFilterByName(elem, filterBy)

        const distanceKm = elem.distance / conversionFactor;
        total += distanceKm;
        totalJourneys += 1;

        if (data.hasOwnProperty(filter)) {
            data[filter].totalDistance += distanceKm;
            data[filter].count += 1;
        } else {
            data[filter] = { totalDistance: distanceKm, count: 1 };
        }
    });

    Object.entries(data).forEach(([key, val]) => {
        const { totalDistance, count } = val;
        const average = totalDistance / count;
        const roundedTwoDecimals = Math.round(average * 100) / 100;

        let title = key

        chartData.push([title, roundedTwoDecimals]);
    });

    const totalAverage = total / totalJourneys;
    const roundedTotalAverage = Math.round(totalAverage * 100) / 100;

    const translate = isFilterByOne || range === KPI_RANGE_TYPES.year

    let res = orderChartBody(chartData)

    if (translate) {
        const body = chartData.slice(1)
        res = [res[0], ...getBodyWithTranslations(body, range)]
    }

    return { data: res, total: roundedTotalAverage };
}

export function getJourneyKmsKPI(journeys, filterBy, range, dateStart, dateEnd) {
    if (!journeys?.length) return { total: 0, data: [] };

    const header = [i18next.t("commandBox.rangeTableTitle")]
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((journey) => {
        if (!journey.date) return { total: 0, data: [] };

        const distanceKm = journey.distance / conversionFactor
        total += distanceKm;

        let keyString = getKeyStringByRange(journey.date, range, printYear);
        const filterByName = getFilterByName(journey, filterBy);

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill([])];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = row[index]?.length ? [...row[index], distanceKm] : [distanceKm];
            }
        });
    });

    const totalAverage = total / journeys.length;
    const roundedTotalAverage = Math.round(totalAverage * 100) / 100;

    body = fillBodyWithZeros(body, header.length)
    const bodyWithAVG = calculateAVGFromBody(body, 2)
    let orderBody = getBodyWithTranslations(orderChartBody(bodyWithAVG), range)

    const chart = [header, ...orderBody];

    return { total: roundedTotalAverage, data: chart };
}

export function getJourneyTimeKPI(journeys, filterBy, range, dateStart, dateEnd) {
    if (!journeys?.length) return { total: 0, data: [] };

    const header = [i18next.t("commandBox.rangeTableTitle")]
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((journey) => {
        if (!journey.date) return { total: 0, data: [] };

        const durationMinutes = journey.duration > 0 ? Math.floor(journey.duration / 60000) : 0; // Convertimos milisegundos a minutos
        total += durationMinutes;

        let keyString = getKeyStringByRange(journey.date, range, printYear);
        const filterByName = getFilterByName(journey, filterBy);

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill([])];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = row[index]?.length ? [...row[index], durationMinutes] : [durationMinutes];
            }
        });
    });

    body.forEach((row) => {
        while (row.length < header.length) {
            row.push([]);
        }
    });


    const totalAverage = total / journeys.length;
    const roundedTotalAverage = Math.round(totalAverage * 100) / 100;

    body = fillBodyWithZeros(body, header.length)
    const bodyWithAVG = calculateAVGFromBody(body, 2)
    let orderBody = getBodyWithTranslations(orderChartBody(bodyWithAVG), range)

    const chart = [header, ...orderBody];

    return { total: roundedTotalAverage, data: chart };
}

function getTimeDistribution(data, filterBy, dateKey = "actualPickUpTime") {
    const res = {}

    const templateTimeSlots = {
        '00:00': 0,
        '01:00': 0,
        '02:00': 0,
        '03:00': 0,
        '04:00': 0,
        '05:00': 0,
        '06:00': 0,
        '07:00': 0,
        '08:00': 0,
        '09:00': 0,
        '10:00': 0,
        '11:00': 0,
        '12:00': 0,
        '13:00': 0,
        '14:00': 0,
        '15:00': 0,
        '16:00': 0,
        '17:00': 0,
        '18:00': 0,
        '19:00': 0,
        '20:00': 0,
        '21:00': 0,
        '22:00': 0,
        '23:00': 0,
    }

    data.forEach((elm) => {
        const filterByName = getFilterByName(elm, filterBy)

        if (elm[dateKey] == null) return
        const time = moment(elm[dateKey]).format('HH:00')

        if (res.hasOwnProperty(filterByName)) {
            res[filterByName] = {
                ...res[filterByName],
                [time]: res[filterByName][time] + 1
            }
        }
        else {
            res[filterByName] = {
                ...templateTimeSlots,
                [time]: 1
            }
        }
    })

    return res
}

export function getTripsTimeSlots(trips, filterBy) {
    const header = [i18next.t("commandBox.summary.hour")]

    const timeSlots = [
        ["00:00"],
        ["01:00"],
        ["02:00"],
        ["03:00"],
        ["04:00"],
        ["05:00"],
        ["06:00"],
        ["07:00"],
        ["08:00"],
        ["09:00"],
        ["10:00"],
        ["11:00"],
        ["12:00"],
        ["13:00"],
        ["14:00"],
        ["15:00"],
        ["16:00"],
        ["17:00"],
        ["18:00"],
        ["19:00"],
        ["20:00"],
        ["21:00"],
        ["22:00"],
        ["23:00"],
    ]


    const body = [...timeSlots]


    if (!trips?.length) return [header, ...body]

    const res = getTimeDistribution(trips, filterBy, "actualPickUpTime")

    Object.entries(res).forEach((v) => {
        header.push(v[0])

        Object.values(v[1]).forEach((val, index) => {
            body[index].push(val)
        })
    })

    return [header, ...body]
}

export function getAvgDistanceKPI(trips, filterBy, range, dateStart, dateEnd) {
    if (!trips?.length) return { total: 0, data: [] };

    const header = ['']
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    trips.forEach((trip) => {
        if (!trip.actualPickUpTime) return { total: 0, data: [] };

        const distanceKm = trip.distance / conversionFactor
        total += distanceKm;

        let keyString = getKeyStringByRange(trip.actualPickUpTime, range, printYear);
        const filterByName = getFilterByName(trip, filterBy);

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill([])];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = row[index]?.length ? [...row[index], distanceKm] : [distanceKm];
            }
        });
    })

    const totalAverage = total / trips.length;
    const roundedTotalAverage = parseFloat(totalAverage.toFixed(2));


    body = fillBodyWithZeros(body, header.length)
    const bodyWithAVG = calculateAVGFromBody(body, 2)
    let orderBody = getBodyWithTranslations(orderChartBody(bodyWithAVG), range)
    const chart = [header, ...orderBody];

    return { total: roundedTotalAverage, data: chart };
}

export function getPunctualityKPI(trips, filterBy, range, dateStart, dateEnd, isFilterByOne) {
    if (!trips?.length) return { data: [] }

    const header = [[i18next.t(`commandBox.${filterBy.toLowerCase()}`), i18next.t('commandBox.trips.punctualityTripsChart.punctual'), i18next.t('commandBox.trips.punctualityTripsChart.no-punctual')]]
    const body = []

    if (!isFilterByOne) {
        trips.forEach(trip => {
            if (trip.actualPickUpTime == null) return

            const filterByName = getFilterByName(trip, filterBy)[0]

            let row = body.find((entry) => entry[0] === filterByName);
            if (!row) {
                row = [filterByName, ...Array(2).fill(0)];
                body.push(row);
            }


            body.forEach((val) => {
                if (val[0] === filterByName) {
                    if (trips.isLateArrival) {
                        val[2] = (row[2] || 0) + 1
                    } else {
                        val[1] = (row[1] || 0) + 1
                    }
                }
            });
        })

        const data = [...header, ...body]
        return { data }
    }

    const printYear = isDifferentYear(dateEnd, dateStart)
    trips.forEach(trip => {
        let keyString = getKeyStringByRange(trip.actualPickUpTime, range, printYear);

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(2).fill(0)];
            body.push(row);
        }

        body.forEach((val) => {
            if (val[0] === keyString) {
                if (trips.isLateArrival) {
                    val[2] = (row[2] || 0) + 1
                } else {
                    val[1] = (row[1] || 0) + 1
                }
            }
        });
    })

    let orderBody = getBodyWithTranslations(orderChartBody(body), range)
    const data = [...header, ...orderBody]
    return { data }
}

export function getTotalJourneysKpi(journeys, filterBy, range, dateStart, dateEnd) {
    if (!journeys?.length) return { total: 0, data: [] };

    const header = [i18next.t("commandBox.rangeTableTitle")]
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    journeys.forEach((journey) => {
        if (!journey.date) return { total: 0, data: [] };

        total += 1;

        let keyString = getKeyStringByRange(journey.date, range, printYear);
        const filterByName = getFilterByName(journey, filterBy);

        if (!filterByName) return { total: 0, data: [] };

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });


        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill(0)];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = (row[index] || 0) + 1;
            }
        });
    });

    body = fillBodyWithZeros(body, header.length)
    let orderBody = getBodyWithTranslations(orderChartBody(body), range)

    const chart = [header, ...orderBody];
    return { total, data: chart };
}

export function getTripsKPI(trips, filterBy, range, dateStart, dateEnd) {
    if (!trips?.length) return { total: 0, data: [] };

    const header = [i18next.t("commandBox.rangeTableTitle")]
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    trips.forEach((trip) => {
        if (!trip.actualPickUpTime) return { total: 0, data: [] };

        total += 1;

        let keyString = getKeyStringByRange(trip.actualPickUpTime, range, printYear);
        const filterByName = getFilterByName(trip, filterBy);

        if (!filterByName) return { total: 0, data: [] };

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });


        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill(0)];
            body.push(row);
        }

        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = (row[index] || 0) + 1;
            }
        });
    });

    body = fillBodyWithZeros(body, header.length)
    let orderBody = getBodyWithTranslations(orderChartBody(body), range)

    const chart = [header, ...orderBody];
    return { total, data: chart };
}

function getTripsByUserKPI(trips, filterOptions, order = false) {
    if (!trips?.length) return []
    const { groupedBy } = filterOptions

    let data = [[i18next.t("commandBox.users.title")]]
    let users = {}
    let services = new Set()

    trips.forEach((elem) => {
        const filterByName = getFilterByName(elem, groupedBy)
        if (filterByName == null) return

        const customerName = elem.customerName === '' ? i18next.t("commandBox.users.deleted") : elem.customerName.trim()

        if (!users.hasOwnProperty(customerName)) {
            users[customerName] = { tripsByOption: {} }
        }

        filterByName.forEach(option => {
            const userTrips = users[customerName]?.tripsByOption
            const newValue = (userTrips[option] || 0) + 1
            userTrips[option] = newValue
            services.add(option)
        })
    })

    services = Array.from(services)

    if (order) {
        services = services.sort((a, b) => a.localeCompare(b))
    }

    data[0].push(...Array.from(services))

    Object.keys(users).forEach(user => {
        let serviceMatrix = data[0].slice(1).map(service => users[user].tripsByOption[service] || 0);
        data.push([user, ...serviceMatrix]);
    });

    return data
}

export function getTripsPerUsers(trips, customers, filterBy, range, dateStart, dateEnd) {
    if (!Object.keys(customers).length || !trips.length) return { data: [], total: 0 };

    const { data, total } = getUsersGroupBy(customers, filterBy, range, dateStart, dateEnd)
    const { data: dataTrips, total: totalTrips } = getTripsKPI(trips, filterBy, range, dateStart, dateEnd)
    const sortedData = sortMatrix(data)
    const sortedDataTrips = sortMatrix(dataTrips)

    sortedData.map((row, i) => {
        row.map((cell, j) => {
            sortedData[i][j] = typeof cell !== "string" ? protectedDivision(sortedDataTrips[i][j], cell) : cell
        })
    })

    return { data: sortedData, total: totalTrips / total }
}

const protectedDivision = (dividend, divisor) => {
    if (!dividend || !divisor) return 0
    if (divisor === 0) return 0
    return parseFloat((dividend / divisor).toFixed(2))
}

function sortMatrix(matrix) {
    const services = matrix[0].slice(1);

    const sortedIndices = services
        .map((val, index) => ({ val, index }))
        .sort((a, b) => a.val.localeCompare(b.val))
        .map(item => item.index);

    const sortedMatrix = matrix.map(row =>
        [row[0], ...sortedIndices.map(index => row[index + 1])]
    );

    return sortedMatrix;
}

export function getUsersGroupBy(users, filterBy, range, dateStart, dateEnd) {
    if (!Object.keys(users).length) return { data: [], total: 0 };

    const header = [i18next.t(`commandBox.${filterBy.toLowerCase()}`)]
    let body = []
    let total = 0

    const printYear = isDifferentYear(dateEnd, dateStart)

    Object.entries(users).map((entry) => {
        const service = entry[0]
        const dates = entry[1]

        if (!header.includes(service)) {
            header.push(service)
        }

        Object.entries(dates).forEach(date => {
            let keyString
            total += date[1]

            switch (range) {
                case KPI_RANGE_TYPES.month:
                    keyString = date[0].split('-')[0]
                    if (printYear) {
                        keyString = date[0]
                    }
                    break
                case KPI_RANGE_TYPES.year:
                    keyString = date[0].split('-')[1]
                    break
                default:
                    keyString = keyString = date[0].split('-')[0]
            }


            let row = body.find((entry) => {
                return entry[0] === keyString
            })

            if (!row) {
                row = [keyString, ...Array(header.length - 1).fill(0)]
                body.push(row)
            }

            const index = header.indexOf(service)
            row[index] = date[1]
        })
    })

    body = fillBodyWithZeros(body, header.length)
    let orderBody = getBodyWithTranslations(orderChartBody(body), range)

    const chart = [header, ...orderBody];
    return { data: chart, total };
}

export const getDriversGroupBy = (data, filterOptions) => {
    const { groupedBy, value } = filterOptions
    const totalDrivers = groupBy(data, groupedBy, value)

    let result = [[getChartName(groupedBy), i18next.t("commandBox.drivers.totalDriversChart.label")]]

    totalDrivers.forEach(el => {
        result.push([el[0], el[1]])
    })

    return result
}

export const getEconomicFeeKPI = (trips, filterBy, range, type, dateStart, dateEnd) => {
    if (!trips?.length) return { total: 0, data: [] };

    const header = [i18next.t("commandBox.rangeTableTitle")]
    let body = []
    let total = 0;

    const printYear = isDifferentYear(dateEnd, dateStart)

    trips.forEach(trip => {
        if (!trip.actualPickUpTime) return { total: 0, data: [] };

        total += trip?.price?.[type];

        let keyString = getKeyStringByRange(trip.actualPickUpTime, range, printYear);

        const filterByName = getFilterByName(trip, filterBy);

        filterByName.forEach((name) => {
            if (!header.includes(name)) {
                header.push(name);
            }
        });

        let row = body.find((entry) => entry[0] === keyString);
        if (!row) {
            row = [keyString, ...Array(header.length - 1).fill(0)];
            body.push(row);
        }
        filterByName.forEach((name) => {
            const index = header.indexOf(name);
            if (index !== -1) {
                row[index] = (row[index] || 0) + trip?.price?.[type];
            }
        });
    })

    body = fillBodyWithZeros(body, header.length)
    let orderBody = getBodyWithTranslations(orderChartBody(body), range)

    const chart = [header, ...orderBody];
    return { total, data: chart };
}

export const getVehicleCapacityKPI = (vehicleData, order = true) => {
    const data = []
    const chartData = [[i18next.t(`commandBox.vehicles.title`), i18next.t("commandBox.vehicles.vehicleCapacityChart.label")]]

    vehicleData.forEach((elm) => {
        data.push([elm.displayName, elm.capacity])
    })
    if (order) {
        data.sort((a, b) => b[1] - a[1])
    }

    chartData.push(...data)
    return chartData
}

export function getOriginDestinationMatrix(trips) {
    if (!trips?.length) return []

    let optionsDestinations = Array.from(new Set(Array.from(new Set(trips.map(trips => trips.groupedTrips.map(item => item.destination)))).flat(1)))
    let results = []

    trips.map((originItem, index) => {
        let parcialDestinations = []
        optionsDestinations.forEach(destination => {
            parcialDestinations[optionsDestinations.indexOf(destination)] = getGroupedTrip(trips, originItem.origin, destination)
        })
        if (index === 0) results.push(["origen/destino"].concat(optionsDestinations))

        results.push([originItem.origin].concat(parcialDestinations))

    })
    return results
}

export function getWheelJourneyDriversTable({ driverRequest }) {
    let driverData = {}

    driverRequest.forEach(driver => {
        if (driverData.hasOwnProperty(driver.fullName)) {
            driverData[driver.fullName].acceptedServices += driver.currentStatus === DRIVER_REQUEST_STATUS.Accepted ? 1 : 0
            driverData[driver.fullName].rejectedServices += driver.currentStatus === DRIVER_REQUEST_STATUS.Rejected ? 1 : 0
        } else {
            driverData[driver.fullName] = {
                acceptedServices: driver.currentStatus === DRIVER_REQUEST_STATUS.Accepted ? 1 : 0,
                rejectedServices: driver.currentStatus === DRIVER_REQUEST_STATUS.Rejected ? 1 : 0,
            }
        }
    })

    return driverData
}

export function getDriverTable({ journeys, confirmTrips }) {
    let driverData = {}
    journeys.forEach(trip => {
        const { driverName } = trip
        const trimmedName = driverName === '' ? i18next.t('commandBox.drivers.deleted') : driverName.trim()

        if (driverData.hasOwnProperty(trimmedName)) {
            driverData[trimmedName].journeys += 1
        } else {
            driverData[trimmedName] = {
                journeys: 1,
                totalTrips: 0,
                price: 0,
                unpunctuality: 0
            }
        }
    })

    confirmTrips.forEach(trip => {
        const { driverName } = trip
        const trimmedName = driverName?.trim()

        if (driverData.hasOwnProperty(trimmedName)) {
            driverData[trimmedName].totalTrips += 1
            driverData[trimmedName].price += trip.price ? trip.price.totalAmount : 0
            driverData[trimmedName].unpunctuality += trip.isLateArrival ? 1 : 0
        } else {
            driverData[trimmedName] = {
                totalTrips: 1,
                price: trip.price ? trip.price.totalAmount : 0,
                unpunctuality: trip.isLateArrival ? 1 : 0,
                journeys: 0,
            }
        }
    })

    return driverData
}

export function getTripStateTable({ reservations }) {
    if (!reservations) return { rows: [], total: 0 }
    const total = reservations.finished + reservations.nonAttendance + reservations.notPerformed;

    function createData(name, unit) {
        return { name, unit };
    }

    const rows = [
        createData(i18next.t('commandBox.trips.tripStateTable.row1'), reservations.finished,),
        createData(i18next.t('commandBox.trips.tripStateTable.row2'), reservations.nonAttendance),
        createData(i18next.t('commandBox.trips.tripStateTable.row3'), reservations.notPerformed),
    ];

    return { rows, total }
}

export function getAlertTable({ alerts }) {
    if (!alerts) return { rows: [], total: 0 }
    const total = alerts.tripPickupDelayed + alerts.tripRejectedByAllDrivers + alerts.isLateCancellation + alerts.nonAttendance + alerts.driverDelayed

    function createData(name, unit) {
        return { name, unit };
    }

    const rows = [
        createData(i18next.t("commandBox.summary.alertTable.row1"), alerts.tripRejectedByAllDrivers,),
        createData(i18next.t("commandBox.summary.alertTable.row2"), alerts.tripPickupDelayed),
        createData(i18next.t("commandBox.summary.alertTable.row3"), alerts.isLateCancellation),
        createData(i18next.t("commandBox.summary.alertTable.row4"), alerts.nonAttendance),
        createData(i18next.t("commandBox.summary.alertTable.row5"), alerts.driverDelayed),
    ];

    return { rows, total }
}

export function getReservationTable({ reservations }) {
    if (!reservations) return { rows: [], total: 0 }

    function createData(name, byBackOffice, byUserApp, byUserWeb, other, total) {
        return { name, byBackOffice, byUserApp, byUserWeb, other, total };
    }

    const totalBO = reservations.acceptedByBO + reservations.rejectedByBO + reservations.cancelledByBO
    const totalUserApp = reservations.acceptedByCustomerApp + reservations.rejectedByCustomerApp + reservations.cancelledByCustomerApp
    const totalUserWeb = reservations.acceptedByCustomerWebApp + reservations.rejectedByCustomerWebApp + reservations.cancelledByCustomerWebApp

    const otherAccepted = reservations.accepted - (reservations.acceptedByBO + reservations.acceptedByCustomerApp + reservations.acceptedByCustomerWebApp)
    const otherRejected = reservations.rejected - (reservations.rejectedByBO + reservations.rejectedByCustomerApp + reservations.rejectedByCustomerWebApp)
    const otherCancel = reservations.cancelled - (reservations.cancelledByBO + reservations.cancelledByCustomerApp + reservations.cancelledByCustomerWebApp)

    const totalOther = otherAccepted + otherRejected + otherCancel

    const rows = [
        createData(i18next.t("commandBox.summary.reservationTable.row1"), reservations.acceptedByBO, reservations.acceptedByCustomerApp, reservations.acceptedByCustomerWebApp, otherAccepted, reservations.accepted),
        createData(i18next.t("commandBox.summary.reservationTable.row2"), reservations.rejectedByBO, reservations.rejectedByCustomerApp, reservations.rejectedByCustomerWebApp, otherRejected, reservations.rejected),
        createData(i18next.t("commandBox.summary.reservationTable.row3"), reservations.cancelledByBO, reservations.cancelledByCustomerApp, reservations.cancelledByCustomerWebApp, otherCancel, reservations.cancelled),
    ];

    return { rows, totalBO, totalUserApp, totalOther, totalUserWeb }
}

export function getReservationTimeSlotsChart({ tripBooking, type }) {
    if (!tripBooking || !tripBooking.length) return { data: [] }

    const res = getTimeDistribution(tripBooking, type, "created")
    const newSlots = [
        ["00:00"],
        ["01:00"],
        ["02:00"],
        ["03:00"],
        ["04:00"],
        ["05:00"],
        ["06:00"],
        ["07:00"],
        ["08:00"],
        ["09:00"],
        ["10:00"],
        ["11:00"],
        ["12:00"],
        ["13:00"],
        ["14:00"],
        ["15:00"],
        ["16:00"],
        ["17:00"],
        ["18:00"],
        ["19:00"],
        ["20:00"],
        ["21:00"],
        ["22:00"],
        ["23:00"],
    ]


    const data = res && [
        [
            i18next.t("commandBox.summary.hour"),
            ...Object.keys(res)
        ],
        ...newSlots
    ]

    res && Object.entries(res).map((el) => {
        const slots = el[1]
        Object.entries(slots).forEach((slot, index) => {
            newSlots[index].push(slot[1])
        })
    })

    return { data }
}

export function getTotalTripsByUserChart({ trips, type, order = false }) {
    if (!trips?.length) return { header: [], body: [] }
    const data = getTripsByUserKPI(trips, { groupedBy: type }, order);
    const total = data[0].length > 1
        ? data.slice(1).reduce((acc, curr) => acc + curr.reduce((acc, curr) => typeof curr === "number" ? acc + curr : acc, 0), 0)
        : 0

    const header = [{ name: i18next.t("commandBox.users.title"), field: "user" }, ...data[0].slice(1).map(cell => ({ title: cell, field: cell }))]
    let body = data.slice(1).map(row => ({ user: row[0], ...row.slice(1).reduce((acc, cell, index) => ({ ...acc, [data[0][index + 1]]: cell }), {}) }))

    return { header, body, total }
}

export function getOriginDestinationChart({ groupedTrips }) {
    if (!groupedTrips?.length) return { header: [], body: [] }
    const data = getOriginDestinationMatrix(groupedTrips)

    const header = [{ name: i18next.t("commandBox.originDestination.label"), field: "origin" }, ...data[0].slice(1).map(cell => ({ title: cell, field: cell }))]
    const body = data.slice(1).map(row => ({ origin: row[0], ...row.slice(1).reduce((acc, cell, index) => ({ ...acc, [data[0][index + 1]]: cell }), {}) }))

    return { header, body }
}

export function getTimeServiceByDriverChart({ driverCalendar, weekDays }) {
    if (!driverCalendar?.length) return []

    const driversOptions = Array.from(new Set(driverCalendar.map(item => item.map(shiftDay => shiftDay.driverName)))).map(item => {
        return item[0]
    })
    const results = [
        [
            { type: 'string', id: 'Días' },
            { type: 'string', id: 'Servicio' },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
        ],
    ]

    const getWeekDays = days => {
        let result = weekDays.filter(item => days.includes(parseInt(item.id)))
        return result
    }

    driversOptions.forEach(function (itemDriver) {
        let dataDriver = driverCalendar
            .map(objectDriver =>
                objectDriver.filter(function (x) {
                    return x.driverName == itemDriver
                }),
            )
            .map(function (a, i) {
                return a.length > 0 ? a : null
            })
            .filter(item => item != null)[0]
        dataDriver.forEach(function (itemShift) {
            let from = moment(itemShift.earliestStart, 'HH:mm')
            let to = moment(itemShift.latestArrival, 'HH:mm')
            if (itemShift.endsNextDay) {
                to = to.add(1, 'days')
            }
            let days = getWeekDays(itemShift.weekDays)
            results.push([days.map(item => item.name).join(', '), itemDriver + ' - ' + itemShift.serviceName, from.toDate(), to.toDate()])
        })
    })

    return results
}