export const initialStateRole: RolesState = {
    pending: false,
    roles: [],
    users: [],
    role: null,
    error: null,
}

export interface Role {
    id: string
    name: string
    permissions: string[]
}

export interface User {
    id: string
    name: string
    email: string
    roleId: string
}

export interface RolesState {
    pending: boolean
    roles: Role[]
    users: User[]
    role?: Role | null
    error?: string | null
}

export interface RolesAction {
    type: string
    results?: Role[] | User[]
    role?: Role
    users?: User[]
    id?: string
    error?: string
}
