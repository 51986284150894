export const initialStateZone: ZoneState = {
    pending: false,
    zones: [],
    circles: [],
    results: [],
    zonesSelected: [],
    zonesCombination: [],
    zoneStops: [],
    allStops: [],
    refreshMarkers: false,
    changeZone: false,
    openModalConflictZones: false,
    stops: [],
}

interface Zone {
    id: number
    stops?: any[]
}

export interface ZoneAction {
    type: string
    zone?: Zone
    zones?: any[]
    zonesCombination?: any[]
    id?: number
    results?: any[]
    stops?: any[]
    conflictServices?: any
    boolean?: boolean
    idZone?: number
    values?: any[]
    error?: any
    contentToModal?: any
}

export interface ZoneState {
    pending: boolean
    zones: any[]
    circles: any[]
    results: any[]
    zonesSelected: any[]
    zonesCombination: any[]
    zoneStops: any[]
    allStops: any[]
    refreshMarkers: boolean
    changeZone: boolean
    openModalConflictZones: boolean
    zone?: Zone
    contentToModal?: any
    error?: any
    stops: any[]
}
