import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const tenantService = {
    _delete,
    createTenant,
    updateTenant,
    getTenants,
    getById,
    getTenantNameAndColorsById,
    getTenantIconById,
    getTenantNameById,
    getAccessDomain,
}

function getTenants() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + `/opusers/v1/Tenant/GetTenants`,
        requestOptions,
    ).then(handleResponse)
}

function getById(tenantId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/opusers/v1/Tenant/GetTenantById?id=' + tenantId,
        requestOptions,
    ).then(handleResponse)
}

function getTenantNameAndColorsById(tenantId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return fetch(
        stringConstants.URL_ApiGateway + `/opusers/v1/Tenant/GetTenantNameAndColorsById`,
        requestOptions,
    ).then(handleResponse)
}

function getTenantIconById(tenantId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + `/opusers/v1/Tenant/GetTenantIconById`,
        requestOptions,
    ).then(handleResponse)
}

function getTenantNameById(tenantId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + `/opusers/v1/Tenant/GetTenantNameById`,
        requestOptions,
    ).then(handleResponse)
}

function getAccessDomain() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + `/opusers/v1/Tenant/GetAccessDomain`,
        requestOptions,
    ).then(handleResponse)
}

function createTenant(tenant) {
    delete tenant.id
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tenant),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/opusers/v1/tenant/createTenant',
        requestOptions,
    ).then(handleResponse)
}

function updateTenant(tenant) {
    const id = tenant.id
    delete tenant.id
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tenant),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/opusers/v1/tenant/updateTenant?tenantId=' + id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/opusers/v1/tenant/removeTenant?id=' + id,
        requestOptions,
    ).then(handleResponse)
}
