import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { history } from '../../../helpers/history'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import UserTariffTypology from '../../services/typology/userTariff'
import { SERVICES_TYPE, TripStatus } from '../../../constants/types'
import { customerService } from '../../../services/customerService'
import { BackButton } from '../../shared/BackAndSave'
import { TripProperty } from './details'
import AlertDialog from '../../alert-dialog'
import { Prompt } from 'react-router-dom'
import { allowAccessRole } from '../../../helpers/roles'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants'
import { ResumeTripActions } from './components/buttons'

class ResumeTripView extends React.Component {
    constructor(props) {
        super()
        this.state = {
            clickButton: false,
            clickInfoPrice: false,
            preventExitWithTripPending: false,
        }
        this.handleClickInfo = this.handleClickInfo.bind(this)
        this.onClickBack = this.onClickBack.bind(this)
        this.onRejectClick = this.onRejectClick.bind(this)
        this.onReturnTrip = this.onReturnTrip.bind(this)
        this.onAcceptClick = this.onAcceptClick.bind(this)
        this.handleOnClickPlan = this.handleOnClickPlan.bind(this)
    }

    onAcceptClick(id) {
        const { tripReducer, multipleTripReducer } = this.props
        const trip = tripReducer.trip
        if (!multipleTripReducer.areInReturnProcess) {
            if (tripReducer.tripToModify == null) this.props.acceptTrip(id)
            else this.props.acceptModifyTrip(id, tripReducer.tripToModify.tripId)
        } else
            this.props.acceptTripReturn(
                trip,
                multipleTripReducer.selectedTripsToReturn.filter(item => item.checked),
            )

        this.setState({
            clickButton: true,
        })
    }

    handleClickInfo() {
        this.setState({
            clickInfoPrice: !this.state.clickInfoPrice,
        })
    }

    onRejectClick(id) {
        this.props.rejectTrip(id)
        this.setState({
            clickButton: true,
        })
    }

    onReturnTrip(trip) {
        const pickUp = { ...trip.pickUpLocation }
        const dropOff = { ...trip.dropOffLocation }
        trip.pickUpLocation = dropOff
        trip.dropOffLocation = pickUp
        const tripToRepeat = { ...trip }

        customerService.getById(tripToRepeat.customerId).then(result => {
            const customer = {
                customerId: trip.customerId,
                name: result.fullName,
                birthDate: result.birthDate,
                isOnRelatedCustomerBehalf: trip.isOnRelatedCustomerBehalf,
            }
            this.props.cleanRepeatTrip()
            this.props.repeatTrip(tripToRepeat, customer, true)
        })
    }

    handleOnClickPlan(trip) {
        const { tripReducer } = this.props

        if (trip.isReturn || trip.hasReturn) {
            let outboundTripId = ''
            if (tripReducer.tripToReturn) {
                outboundTripId = tripReducer.tripToReturn.tripId
            } else {
                outboundTripId = trip.isReturn
                    ? tripReducer.trip.outboundTripId
                    : tripReducer.trip.tripId
            }

            this.props.setOriginalOutbound(outboundTripId)
            this.props.setOriginalReturn(trip.tripId)
        } else {
            this.props.setOriginalOutbound(trip.tripId)
            this.props.setOriginalReturn(null)
        }
        this.props.setOpenModalSelectFrecuency(
            true,
            trip.tripId,
            trip.requestPickUpStartTime,
            trip.serviceType,
            trip.serviceId,
        )
    }

    onClickBack() {
        const { multipleTripReducer } = this.props
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)
        if (hasTripWithError) {
            this.setState({ preventExitWithTripPending: true })
        } else history.goBack()
    }

    componentWillUnmount() {
        const { trip } = this.props.tripReducer
        if (trip != null) {
            if (trip.status == TripStatus.Available && !this.state.clickButton) {
                this.props.rejectTrip(trip.tripId)
            }
        }
    }

    getIsPMR() {
        if (this.props.isPMR === null || this.props.isPMR === undefined) {
            return ''
        }
        return this.props.isPMR ? this.props.t('trips.resume.yes') : this.props.t('trips.resume.no')
    }

    render() {
        const { tripReducer, t, multipleTripReducer } = this.props
        const trip = tripReducer.trip
        const executeMultipleTrips = multipleTripReducer.executeMultipleTrips
        const inMultipleTripScheduleReturn = multipleTripReducer.areInReturnProcess
        const columns = {
            passenger: [2, 3],
            origin: [2, 3],
            destination: [2, 3],
            date: [2, 3],
            actions: 3,
        }
        const index = executeMultipleTrips | 0
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)

        return (
            <div>
                <Prompt
                    when={hasTripWithError && !this.state.preventExitWithTripPending}
                    message={(location, action) => {
                        return t('trips.multipleBook.pendingTripsConfirm')
                    }}
                />
                <Grid
                    container
                    spacing={1}
                    style={{
                        paddingBottom: 20,
                        paddingLeft: 20,
                    }}
                >
                    <Grid item md={12} sm={12}>
                        <h2>
                            <BackButton onClick={this.onClickBack} />
                            {!multipleTripReducer.areInReturnProcess
                                ? t('trips.resume.title')
                                : t('trips.resume.requestReturnTitle')}
                        </h2>
                    </Grid>
                    {trip != null && (
                        <>
                            <Grid item md={columns.passenger[index]} sm={6} xs={12}>
                                <TripProperty isBold>{t('trips.resume.customer')}</TripProperty>
                                <TripProperty>{this.props.customerName}</TripProperty>
                                <TripProperty isBold>{t('trips.resume.price')}</TripProperty>
                                <TripProperty>
                                    <Box display={'flex'} alignContent={'center'}>
                                        {this.props.price}€
                                        {trip.serviceType != SERVICES_TYPE.regularWithSchedule ? (
                                            <Tooltip title={t('trips.resume.priceTable')}>
                                                <IconButton
                                                    style={{ padding: 0, fontSize: 'inherit' }}
                                                >
                                                    <InfoIcon
                                                        onClick={this.handleClickInfo}
                                                        fontSize="inherit"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </Box>
                                </TripProperty>
                                <TripProperty isBold>
                                    {t('trips.resume.numPassengers')}
                                </TripProperty>
                                <TripProperty data-testid="numPassengers">
                                    {this.props.numPassengers}
                                </TripProperty>
                                {this.props.observation ? (
                                    <>
                                        <TripProperty isBold>
                                            {t('trips.resume.observation')}
                                        </TripProperty>
                                        <TripProperty data-testid="observation">
                                            {this.props.observation}
                                        </TripProperty>
                                    </>
                                ) : null}
                            </Grid>
                            <Grid item md={columns.origin[index]} sm={6} xs={12}>
                                <TripProperty isBold>{t('trips.resume.origin')}:</TripProperty>
                                <TripProperty data-testid="originAddress">
                                    {this.props.originAddress}
                                </TripProperty>
                                {this.props.pickUpStopName ? (
                                    <>
                                        <TripProperty isBold>
                                            {t('trips.resume.pickUpStopName')}
                                        </TripProperty>
                                        <TripProperty data-testid="pickUpStopName">
                                            {this.props.pickUpStopName}
                                        </TripProperty>
                                    </>
                                ) : null}
                                <TripProperty isBold>{t('trips.resume.luggage')}</TripProperty>
                                <TripProperty data-testid="luggage">
                                    {this.props.luggage}
                                </TripProperty>
                            </Grid>
                            <Grid item md={columns.destination[index]} sm={6} xs={12}>
                                <TripProperty isBold>{t('trips.resume.destination')}:</TripProperty>
                                <TripProperty data-testid="destinationAddress">
                                    {this.props.destinationAddress}
                                </TripProperty>
                                {this.props.dropOffStopName ? (
                                    <>
                                        <TripProperty isBold>
                                            {t('trips.resume.dropOffStopName')}
                                        </TripProperty>
                                        <TripProperty data-testid="dropOffStopName">
                                            {this.props.dropOffStopName}
                                        </TripProperty>
                                    </>
                                ) : null}
                                <TripProperty isBold>
                                    {t('trips.resume.hasWheelChair')}
                                </TripProperty>
                                <TripProperty data-testid="hasWheelChair">
                                    {this.props.hasWheelChair
                                        ? t('trips.resume.yes')
                                        : t('trips.resume.no')}
                                </TripProperty>
                            </Grid>
                            <Grid item md={columns.date[index]} sm={6} xs={12}>
                                <TripProperty isBold>{t('trips.resume.date')}</TripProperty>
                                <TripProperty>
                                    <div style={{ display: 'flex', gap: 5 }}>
                                        <Typography>{t('trips.resume.pickUp')}</Typography>
                                        <Typography data-testId="pickupTime">
                                            {this.props.pickupTime}
                                        </Typography>
                                    </div>
                                    {trip.status === TripStatus.Available &&
                                    trip.isAlternateSolution ? (
                                        <span>
                                            <Tooltip
                                                title={t('services.trips.warningAlternateSolution')}
                                            >
                                                <WarningIcon />
                                            </Tooltip>
                                        </span>
                                    ) : null}
                                </TripProperty>
                                <TripProperty>
                                    <div style={{ display: 'flex', gap: 5 }}>
                                        <Typography>{t('trips.resume.dropOff')}</Typography>
                                        <Typography data-testId="dropOffTime">
                                            {this.props.dropOffTime}
                                        </Typography>
                                    </div>
                                </TripProperty>
                                <TripProperty isBold>
                                    {t('trips.resume.isOnRelatedCustomerBehalf')}
                                </TripProperty>
                                <TripProperty data-testid="isOnRelatedCustomerBehalf">
                                    {this.props.isOnRelatedCustomerBehalf
                                        ? t('trips.resume.yes')
                                        : t('trips.resume.no')}
                                </TripProperty>
                                <TripProperty isBold>{t('trips.resume.isPMR')}</TripProperty>
                                <TripProperty data-testid="isPMR">{this.getIsPMR()}</TripProperty>
                            </Grid>
                            {(!executeMultipleTrips || inMultipleTripScheduleReturn) &&
                                allowAccessRole(MODULES.Trips, OPERATIONS_ALLOWED.manage) && (
                                    <ResumeTripActions
                                        handleOnClickPlan={this.handleOnClickPlan}
                                        onAcceptClick={this.onAcceptClick}
                                        onRejectClick={this.onRejectClick}
                                        onReturnTrip={this.onReturnTrip}
                                        trip={trip}
                                    />
                                )}
                        </>
                    )}
                </Grid>
                <AlertDialog
                    open={this.state.clickInfoPrice}
                    title={''}
                    maxWidth={'md'}
                    fullWidth
                    onClose={this.handleClickInfo}
                    onClickAccept={this.handleClickInfo}
                    content={() => (
                        <UserTariffTypology tariff={this.props.tariff} t={t} canEdit={false} />
                    )}
                />
                <AlertDialog
                    title={t('trips.multipleBook.pendingTripsConfirm')}
                    open={this.state.preventExitWithTripPending}
                    onClose={() => this.setState({ preventExitWithTripPending: false })}
                    onClickCancel={() => this.setState({ preventExitWithTripPending: false })}
                    onClickAccept={() => history.goBack()}
                />
            </div>
        )
    }
}

export default ResumeTripView
