import { zonesConstants } from '../../constants/zones_constants'
import { zoneService } from '../../services/zoneService'
import { alertActions } from '../actions/alert_actions'
import i18next from 'i18next'
import React from 'react'
import { ERROR_CODES } from '../../constants/types'

const DETAIL_ERROR_CODES = {
    StopOutsideZone: 'StopOutsideZone',
    ZoneNameAlreadyExists: 'ZoneNameAlreadyExists',
    InsufficientPoints: 'InsufficientPoints',
}

export const zoneActions = {
    getAll,
    getAllByType,
    getById,
    insert,
    update,
    clearResults,
    remove,
    getAllWithOutArea,
    clearZonesSelected,
    setZonesSelected,
    setCombinationZone,
    clearZonesCombination,
    checkEmptyZoneCombination,
    addStops,
    setStops,
    changeZoneSelectStops,
    getServicesFromStops,
    closeModalConflictStops,
    getAllActiveStops,
}

function getAll(serviceId = null) {
    return dispatch => {
        dispatch(request())
        let stops = []
        zoneService.getAll(serviceId).then(
            results => {
                results = results.sort((a, b) => a.name.localeCompare(b.name))
                results.forEach(element => {
                    stops = stops.concat(element.stops)
                })
                dispatch(success(results, stops))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.zones.getAllError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.GETALL_REQUEST }
    }

    function success(results, stops) {
        return { type: zonesConstants.GETALL_SUCCESS, results, stops }
    }

    function failure(error) {
        return { type: zonesConstants.GETALL_FAILURE, error }
    }
}

function getAllActiveStops() {
    return dispatch => {
        dispatch(request())
        zoneService.getAllActiveStops().then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.zones.getAllError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.GETALL_ACTIVED_STOPS_REQUEST }
    }

    function success(results) {
        return { type: zonesConstants.GETALL_ACTIVED_STOPS_SUCCESS, results }
    }

    function failure(error) {
        return { type: zonesConstants.GETALL_ACTIVED_STOPS_FAILURE, error }
    }
}

function getAllWithOutArea() {
    return dispatch => {
        dispatch(request())
        zoneService.getAll().then(
            results => {
                results = results.filter(a => a.areaId == null)
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.zones.getAllError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: zonesConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: zonesConstants.GETALL_FAILURE, error }
    }
}

function getAllByType() {
    return dispatch => {
        dispatch(request())
        zoneService.getAllByType().then(
            results => {
                dispatch(success(results.freeZones))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.zones.getAllError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: zonesConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: zonesConstants.GETALL_FAILURE, error }
    }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id))
        zoneService.getById(id).then(
            zone => {
                dispatch(success(zone))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.zones.getByIdError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: zonesConstants.DETAIL_REQUEST, id }
    }

    function success(zone) {
        return { type: zonesConstants.DETAIL_SUCCESS, zone }
    }

    function failure(error) {
        return { type: zonesConstants.DETAIL_FAILURE, error }
    }
}

function update(zone) {
    return dispatch => {
        dispatch(request())
        zoneService.update(zone).then(
            zone => {
                dispatch(success(zone))
                dispatch(alertActions.success(i18next.t('services.zones.updateSuccess')))
                dispatch(getAllByType())
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenUpdate')))
                        break
                    default:
                        let detailError = 'Se ha producido un error'
                        try {
                            let parsedError = JSON.parse(error)
                            detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.StopOutsideZone:
                                    detailError = i18next.t('services.zones.update.StopOutsideZone')
                                    break
                                case DETAIL_ERROR_CODES.ZoneNameAlreadyExists:
                                    detailError = i18next.t('services.zones.SameNameAlreadyExists')
                                    break
                                case DETAIL_ERROR_CODES.InsufficientPoints:
                                    detailError = i18next.t('services.zones.InsufficientPoints')
                                    break
                                default:
                                    detailError = i18next.t('services.zones.updateError')
                                    break
                            }
                            dispatch(alertActions.error(detailError))
                            dispatch(failure(detailError))
                        } catch (e) {
                            detailError = i18next.t('services.zones.updateError')
                            dispatch(alertActions.error(detailError))
                        }
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.EDIT_REQUEST }
    }

    function success(zone) {
        return { type: zonesConstants.EDIT_SUCCESS, zone }
    }

    function failure(error) {
        return { type: zonesConstants.EDIT_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: zonesConstants.CLEAR_RESULTS }
    }
}

function clearZonesSelected() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: zonesConstants.CLEAR_ZONES_SELECTED }
    }
}

function clearZonesCombination() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: zonesConstants.CLEAR_COMBINATION_ZONE }
    }
}

function setCombinationZone(idZone, values) {
    return dispatch => {
        dispatch(request(idZone, values))
        //dispatch(checkEmptyZoneCombination())
    }

    function request(idZone, values) {
        return { type: zonesConstants.SET_COMBINATION_ZONE, idZone, values }
    }
}

function setZonesSelected(zones, zonesCombination) {
    return dispatch => {
        if (!zonesCombination) zonesCombination = []
        zones.forEach(element => {
            if (zonesCombination.find(x => x.idZone == element.id) == null)
                zonesCombination[element.id] = []
        })
        zonesCombination = zonesCombination.filter(x => zones.map(y => y.id).includes(x.idZone))
        dispatch(request(zones, zonesCombination))
    }

    function request(zones, zonesCombination) {
        return { type: zonesConstants.SET_ZONES_SELECTED, zones, zonesCombination }
    }
}

function checkEmptyZoneCombination() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: zonesConstants.CHECK_EMPTY }
    }
}

function insert(zone, rollback) {
    return dispatch => {
        dispatch(request())
        zoneService.insert(zone).then(
            zone => {
                dispatch(success(zone))
                dispatch(alertActions.success(i18next.t('services.zones.insertSuccess')))
                dispatch(getAllByType())
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenInsert')))
                        break
                    default:
                        let detailError = 'Se ha producido un error'
                        try {
                            let parsedError = JSON.parse(error)
                            detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.ZoneNameAlreadyExists:
                                    detailError = i18next.t('services.zones.SameNameAlreadyExists')
                                    break
                                case DETAIL_ERROR_CODES.InsufficientPoints:
                                    detailError = i18next.t('services.zones.InsufficientPoints')
                                    break
                                default:
                                    detailError = i18next.t('services.zones.insertError')
                                    break
                            }
                            dispatch(failure(detailError))
                            dispatch(alertActions.error(detailError))
                        } catch (e) {
                            detailError = i18next.t('services.zones.insertError')
                            dispatch(alertActions.error(detailError))
                        }
                }
                rollback()
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.ADD_REQUEST }
    }

    function success(zone) {
        return { type: zonesConstants.ADD_SUCCESS, zone }
    }

    function failure(error) {
        return { type: zonesConstants.ADD_FAILURE, error }
    }
}

function addStops(zoneId, stops, cleanStops) {
    return dispatch => {
        dispatch(request())
        zoneService.addStop(zoneId, stops).then(
            zone => {
                dispatch(success(zone))
                cleanStops()
                dispatch(alertActions.success(i18next.t('services.zones.stopsSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenInsert')))
                        break
                    default:
                        dispatch(alertActions.error(error.toString()))
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.ADD_STOP_REQUEST }
    }

    function success(zone) {
        return { type: zonesConstants.ADD_STOP_SUCCESS, zone }
    }

    function failure(error) {
        return { type: zonesConstants.ADD_STOP_FAILURE, error }
    }
}

function getServicesFromStops(zoneId, stops, cleanStops) {
    let stopsId = stops.map(s => s.id)
    let stringStops = stopsId.join('%3B')

    return dispatch => {
        dispatch(request())
        zoneService.getServicesFromStops(zoneId, stringStops).then(
            servicesWithStops => {
                if (servicesWithStops.length > 0) {
                    let conflictServices = servicesWithStops.replace(/:/g, ': ')
                    const Wrapper = () => (
                        <React.Fragment>
                            <label style={{ marginTop: -15, display: 'flex' }}>
                                {conflictServices.split(';').map(string => (
                                    <React.Fragment>
                                        {string}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </label>
                        </React.Fragment>
                    )
                    dispatch(success(Wrapper))
                } else {
                    dispatch(addStops(zoneId, stops, cleanStops))
                }
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(error.toString()))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.CHECK_STOP_REQUEST }
    }

    function success(conflictServices) {
        return { type: zonesConstants.CHECK_STOP_SUCCESS, conflictServices }
    }

    function failure(error) {
        return { type: zonesConstants.CHECK_STOP_FAILURE, error }
    }
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

function changeZoneSelectStops() {
    return dispatch => {
        dispatch(request(true))
        delay(500).then(() => dispatch(request(false)))
    }

    function request(boolean) {
        return { type: zonesConstants.SET_ZONE_STOPS_REFRESH, boolean }
    }
}

function setStops(stops) {
    return dispatch => {
        dispatch(success(stops))
    }

    function success(stops) {
        return { type: zonesConstants.SET_ZONE_STOPS, stops }
    }
}

function closeModalConflictStops() {
    return dispatch => {
        dispatch(request())
    }
    function request() {
        return { type: zonesConstants.CLOSE_MODAL }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        zoneService._delete(id).then(
            zone => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.zones.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.zones.forbiddenDelete')))
                        break
                    default:
                        dispatch(alertActions.error(error.toString()))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: zonesConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: zonesConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: zonesConstants.DELETE_FAILURE, error }
    }
}
