import { handleResponse } from '../helpers/services'
import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { customFetch } from '.'

export const kpiService = {
    getConfirmedTripsKPI,
    getReservationsKPI,
    getAlertsKPI,
    getCustomersKPI,
    getDriversKPI,
    getDriverRequestsKPI,
    getJourneysKPI,
    getConfirmedGroupedTripsKPI,
    getDriverCalendarKPI,
    getTripsBookingKPI,
    getVehicleGeneralDataKPI,
}

async function getConfirmedTripsKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetFinalizedTripsKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getReservationsKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetTripsKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getAlertsKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetAlertsKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getCustomersKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetCustomersKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getDriversKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetDriversKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getDriverRequestsKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    status = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
            requestStatuses: status,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/drivers/v1/KPI/GetDriverRequestsKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getJourneysKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetJourneysKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getConfirmedGroupedTripsKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetConfirmedGroupedTripsKPIDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getDriverCalendarKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/OTPS/v1/KPI/GetCalendarDriversKPI',
        requestOptions,
    ).then(handleResponse)
}

async function getTripsBookingKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/KPI/GetTripsKPIBookingsDashboard',
        requestOptions,
    ).then(handleResponse)
}

async function getVehicleGeneralDataKPI({
    dateStart,
    dateEnd,
    services = [],
    otps = [],
    tenants = [],
    regions = [],
    municipalities = [],
    lang = 'es',
}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            lang,
            dateStart,
            dateEnd,
            services,
            otPs: otps,
            tenants,
            regions,
            municipalities,
        }),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + '/OTPS/v1/KPI/GetVehiclesGeneralDataKPI',
        requestOptions,
    ).then(handleResponse)
}
