import { KPIFiltersConstants } from '../../../../constants/kpiFilters_constant'
import { KPIFiltersState, KPIFiltersAction, initialStateKPIFilter } from './types'

export function kpiFiltersReducer(
    state = initialStateKPIFilter,
    action: KPIFiltersAction,
): KPIFiltersState {
    switch (action.type) {
        case KPIFiltersConstants.SET_DATE_START:
            return {
                ...state,
                dateStart: action.dateStart ?? state.dateStart,
            }

        case KPIFiltersConstants.SET_DATE_END:
            return {
                ...state,
                dateEnd: action.dateEnd ?? state.dateEnd,
            }

        case KPIFiltersConstants.SET_RANGE:
            return {
                ...state,
                range: action.range ?? state.range,
            }

        case KPIFiltersConstants.SET_TYPE:
            return {
                ...state,
                type: action.typeSelected ?? state.type,
            }

        case KPIFiltersConstants.SET_FILTER_BY:
            return {
                ...state,
                filterBy: action.filterBy ?? state.filterBy,
            }

        default:
            return state
    }
}
