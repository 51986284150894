import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const otpService = {
    getAll,
    getById,
    insert,
    update,
    _delete,
    positionInitialIsInOtpServiceZones,
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/otps/getOtps',
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/otps/v1/otps/' + id, requestOptions).then(
        handleResponse,
    )
}

function positionInitialIsInOtpServiceZones(otpId, lat, lng) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
            '/services/v1/serviceZones/positionInitialIsInOtpServiceZones?otpId=' +
            otpId +
            '&lat=' +
            lat +
            '&lon=' +
            lng,
        requestOptions,
    ).then(handleResponse)
}

function insert(otp) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(otp),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/otps/v1/otps/add', requestOptions).then(
        handleResponse,
    )
}

function update(otp) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(otp),
    }
    const id = otp.id
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/otps/update?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/otps/v1/otps/remove?id=' + id,
        requestOptions,
    ).then(handleResponse)
}
