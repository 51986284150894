import React from 'react';
import { FormControl, Grid, Hidden, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class FilterMarkers extends React.Component {

    constructor(props) {
        super()
        this.state = {
            otp: [],
            service: [],
            plate: [],
            zone: [],
            filteredOtp: [],
            filteredService: [],
            filteredPlate: [],
            filteredZone: []
        }
    }

    componentDidMount() {
        this.setInitialFilters();
    }

    setInitialFilters = () => {
        const { vehicleReducer, zoneReducer, otpReducer, serviceReducer } = this.props;
        this.setState({
            filteredOtp: otpReducer.results,
            filteredService: serviceReducer.results,
            filteredPlate: vehicleReducer.results,
            filteredZone: zoneReducer.results
        });
    }

    filterOptions = () => {
        const { otp, service, plate, zone } = this.state;
        const { vehicleReducer, zoneReducer, otpReducer, serviceReducer } = this.props;

        let newOtp = otpReducer.results;
        let newService = serviceReducer.results;
        let newPlate = vehicleReducer.results;
        let newZone = zoneReducer.results;

        if (service.length > 0) {
            newOtp = newOtp.filter(o => service.some(s => s.otpId === o.id)); 
            const serviceIds = service.map(s => s.id)
            newPlate = newPlate.filter(p => p.serviceIds.some(serviceId => serviceIds.includes(serviceId)));
        }

        if (otp.length > 0) { 
            const otpsId = otp.map(o => o.id)
            newPlate = newPlate.filter(p => otpsId.includes(p.otpId));
            newService = newService.filter(s => otpsId.includes(s.otpId)); 
        }

        if (plate.length > 0) {
            newOtp = newOtp.filter(o => plate.some(p => p.otpId === o.id));
            newService = newService.filter(s => plate.some(p => p.serviceIds.includes(s.id)));
        }

        this.setState({
            filteredOtp: newOtp,
            filteredService: newService,
            filteredPlate: newPlate,
            filteredZone: newZone
        });
    }

    onChangeOtp = (event, newValue) => {
        this.setState({ otp: newValue }, this.filterOptions);
        this.props.setFilter('otp', newValue.map(item => item.id));
    }

    onChangeService = (event, newValue) => {
        this.setState({ service: newValue }, this.filterOptions);
        this.props.setFilter('service', newValue.map(item => item.id));
    }

    onChangePlate = (event, newValue) => {
        this.setState({ plate: newValue }, this.filterOptions);
        this.props.setFilter('plate', newValue.map(item => item.plateNumber));
    }

    onChangeZone = (event, newValue) => {
        this.setState({ zone: newValue }, this.filterOptions);
        this.props.setFilter('zone', newValue.map(item => item.points));
    }

    render() {
        const { t } = this.props;
        const { filteredOtp, filteredService, filteredPlate, filteredZone } = this.state;

        return (
            <Grid container className={styles.root} spacing={3} style={{ marginBottom: '16px', marginTop: '16px' }}>
                <Grid item md={1} implementation="css" smDown component={Hidden} />
                <Grid item md={2} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="otpId"
                            value={this.state.otp}
                            onChange={this.onChangeOtp}
                            options={filteredOtp}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={'outlined'}
                                    label={t('maps.filter.otp')}
                                    name="otp"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="serviceId"
                            value={this.state.service}
                            onChange={this.onChangeService}
                            options={filteredService}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={'outlined'}
                                    label={t('maps.filter.service')}
                                    name="serviceId"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="plate"
                            value={this.state.plate}
                            onChange={this.onChangePlate}
                            options={filteredPlate}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.plateNumber ? option.plateNumber : ''}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.plateNumber}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={'outlined'}
                                    label={t('maps.filter.plate')}
                                    name="plate"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={6} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            multiple
                            id="zone"
                            value={this.state.zone}
                            onChange={this.onChangeZone}
                            options={filteredZone}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option ? option.name : ''}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={'outlined'}
                                    label={t('maps.filter.zone')}
                                    name="zone"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
}

export default FilterMarkers;