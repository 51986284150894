export const initialStateAuthentication: AuthenticationState = {
    auth: false,
    pending: false,
    mongoId: null,
    profile: null,
    name: null,
    messagingToken: 'null',
    Permissions: [],
}

export interface AuthenticationState {
    auth: boolean
    pending: boolean
    mongoId: string | null
    profile: string | null
    name: string | null
    messagingToken: string
    Permissions: string[]
    refreshToken?: string | null
    user?: User | null
    token?: string
    customerId?: string
    roles?: string | string[]
    error?: string | null
}

export interface User {
    id: string
    email: string
    name: string
}

export interface Action {
    type: string
    data?: any
    token?: string
    error?: string
}
