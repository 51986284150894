import React from 'react';
import { FormControl, Grid, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select'
import { Add, Remove } from '@material-ui/icons';
import GoogleMaps from '../../../vehicles/form/mapsInput';

class ItemAddress extends React.Component {

    render() {
        const { t, customerReducer } = this.props
        return (
            <Grid item container spacing={3} style={{ justifyContent: 'center' }}>
                <Grid item md={4} xs={9} sm={3} >
                    <FormControl fullWidth>
                        <GoogleMaps
                            label={t('customers.form.page.addresses.address.location')}
                            onChange={this.props.onChangeAddress}
                            value={this.props.value.addressPosition.address}
                            name={"address_" + this.props.index}
                            disabled={this.props.readOnly ? true : false}
                            error={this.props.state.errors.favouriteAddresses[this.props.index].address}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xl={2} xs={9} >
                    <FormControl fullWidth error={this.props.state.errors.favouriteAddresses[this.props.index].type.message.length == 0 ? false : true} >
                        <InputLabel>
                            {t('customers.form.page.addresses.address.type')}
                        </InputLabel>
                        <Select
                            value={this.props.value.type || ''}
                            onChange={this.props.onChange}
                            name={'type_' + this.props.index}
                            disabled={this.props.readOnly ? true : false}
                        >
                            {customerReducer.customerAddresses.map((item, index) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{this.props.state.errors.favouriteAddresses[this.props.index].type.message}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={1} sm={3} style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {
                        (this.props.index + 1 == this.props.length ?
                            <React.Fragment>
                                {
                                    this.props.length > 1 &&
                                    <Remove
                                        onClick={() => !this.props.readOnly && this.props.remove(this.props.index, this.props.id)}
                                    />
                                }
                                <Add
                                    onClick={() => !this.props.readOnly && this.props.add()}
                                />
                            </React.Fragment>

                            :
                            <Remove
                                onClick={() => !this.props.readOnly && this.props.remove(this.props.index, this.props.id)}
                            />)
                    }
                </Grid>
            </Grid>
        );
    }
}

export default ItemAddress;