import { Driver } from 'model/Driver'
import { driverConstants } from '../../../constants/driver_constants'
import { DriverAction, DriverResult, DriverState, initialStateDriver } from './types'

export function driverReducer(state = initialStateDriver, action: DriverAction): DriverState {
    switch (action.type) {
        case driverConstants.GET_FOR_CHAT_REQUEST:
        case driverConstants.GET_FULLNAME_REQUEST:
        case driverConstants.DETAIL_REQUEST:
        case driverConstants.GETALL_REQUEST:
        case driverConstants.ADD_REQUEST:
        case driverConstants.EDIT_REQUEST:
        case driverConstants.DELETE_REQUEST:
        case driverConstants.UPDATE_PSW_REQUEST:
        case driverConstants.CHECK_DRIVERS_SERVICES_REQUEST:
        case driverConstants.GET_REQUEST_DRIVERS_SERVICES_REQUEST:
        case driverConstants.GET_DRIVERS_KPI_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case driverConstants.GET_FOR_CHAT_SUCCESS:
            return {
                ...state,
                pending: false,
                driversForChat: (action.results as Driver[]) ?? [],
            }

        case driverConstants.GET_FULLNAME_SUCCESS:
            return {
                ...state,
                pending: false,
                driverFullName: (action.results as string[]) ?? [],
            }

        case driverConstants.CLEAN_DRIVERS_FULLNAME:
            return {
                ...state,
                driverFullName: [],
            }

        case driverConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: (action.results as DriverResult[]) ?? [],
                allDrivers: action.all || state.allDrivers,
            }

        case driverConstants.DETAIL_SUCCESS:
        case driverConstants.ADD_SUCCESS:
        case driverConstants.EDIT_SUCCESS:
            return {
                ...state,
                pending: false,
                driver: action.driver,
            }

        case driverConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.driver.id !== action.id),
            }

        case driverConstants.UPDATE_PSW_SUCCESS:
            return {
                ...state,
                pending: false,
            }

        case driverConstants.VALIDATE_SUCCESS:
            return {
                ...state,
                results: state.results.map(item =>
                    item.driver.id === action.id
                        ? { ...item, driver: { ...item.driver, isValidated: true } }
                        : item,
                ),
            }

        case driverConstants.ENABLE_SUCCESS:
            return {
                ...state,
                results: state.results.map(item =>
                    item.driver.id === action.id
                        ? { ...item, driver: { ...item.driver, isActived: true } }
                        : item,
                ),
            }

        case driverConstants.DISABLE_SUCCESS:
            return {
                ...state,
                results: state.results.map(item =>
                    item.driver.id === action.id
                        ? { ...item, driver: { ...item.driver, isActived: false } }
                        : item,
                ),
            }

        case driverConstants.DRIVER_SET_PAGE_TABLE:
            return { ...state, currentPage: action.page || 0 }

        case driverConstants.CHECK_DRIVERS_CLOSE:
            return { ...state, modalToAssignDriver: false }

        case driverConstants.CHECK_DRIVERS_SERVICES_SUCCESS:
            return {
                ...state,
                pending: false,
                driversService: (action.results as Driver[]) ?? [],
            }

        case driverConstants.GET_DRIVERS_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                driversKPI: action.results || [],
            }

        case driverConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                driversFilters: action.tableState || [],
            }

        case driverConstants.UPDATE_DRIVER_STATUS_SUCCESS:
            return {
                ...state,
                results: state.results.map(item =>
                    item.driver.id === action.id
                        ? {
                              ...item,
                              driver: {
                                  ...item.driver,
                                  isValidated: action.isValidated || false,
                                  isActived: action.isActived || false,
                              },
                          }
                        : item,
                ),
            }

        case driverConstants.GET_FOR_CHAT_FAILURE:
        case driverConstants.GET_FULLNAME_FAILURE:
        case driverConstants.DETAIL_FAILURE:
        case driverConstants.GETALL_FAILURE:
        case driverConstants.ADD_FAILURE:
        case driverConstants.EDIT_FAILURE:
        case driverConstants.DELETE_FAILURE:
        case driverConstants.UPDATE_PSW_FAILURE:
        case driverConstants.GET_DRIVERS_KPI_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }

        default:
            return state
    }
}
