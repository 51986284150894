import { tenantConstants } from '../../constants/tenant_constants'
import { history } from '../../helpers/history'
import { tenantService } from '../../services/tenantService'
import { alertActions } from '../actions/alert_actions'
import i18next from 'i18next'
import { ERROR_CODES } from '../../constants/types'

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value.toString())

const DETAIL_ERROR_CODES = {
    OpUserNotFound: 'OpUserNotFound',
    InvalidProfile: 'InvalidProfile',
    CannotRemoveAmtuTenant: 'CannotRemoveAmtuTenant',
}

export const tenantsActions = {
    setCurrentPage,
    closeModalToAssing,
    getAll,
    getTenant,
    update,
    insert,
    remove,
    filterPlainArray,
    clearResults,
    getById,
}

function setCurrentPage(page) {
    return dispatch => {
        dispatch(request(page))
    }

    function request(page) {
        return { type: tenantConstants.TENANT_SET_PAGE_TABLE, page }
    }
}

function closeModalToAssing() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tenantConstants.CHECK_TENANTS_CLOSE }
    }
}

function getAll() {
    return dispatch => {
        dispatch(request())
        tenantService.getTenants().then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenGetAll')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tenantConstants.GET_TENANTS_REQUEST }
    }

    function success(results) {
        return { type: tenantConstants.GET_TENANTS_SUCCESS, results }
    }

    function failure(error) {
        return { type: tenantConstants.GET_TENANTS_FAILURE, error }
    }
}

function getTenant() {
    return dispatch => {
        dispatch(request())
        tenantService.get().then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenGetAll')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tenantConstants.GET_TENANT_REQUEST }
    }

    function success(results) {
        return { type: tenantConstants.GET_TENANT_SUCCESS, results }
    }

    function failure(error) {
        return { type: tenantConstants.GET_TENANT_FAILURE, error }
    }
}

function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true
            return filters[key].find(filter => getValue(filter) === getValue(item[key]))
        })
    })
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        tenantService.getById(id).then(
            tenant => {
                dispatch(success(tenant))
                if (redirect) history.push('/tenants/' + id)
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.tenants.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tenantConstants.GET_TENANT_REQUEST, id }
    }

    function success(tenant) {
        return { type: tenantConstants.GET_TENANT_SUCCESS, tenant }
    }

    function failure(error) {
        return { type: tenantConstants.GET_TENANT_FAILURE, error }
    }
}

function update(tenant) {
    return dispatch => {
        dispatch(request())
        tenantService.updateTenant(tenant).then(
            tenant => {
                dispatch(success(tenant))
                history.push('/tenants')
                dispatch(alertActions.success(i18next.t('services.tenants.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OpUserNotFound:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.opUserNotFound'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.InvalidProfile:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.invalidProfile'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.updateError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tenantConstants.UPDATE_REQUEST }
    }

    function success(tenant) {
        return { type: tenantConstants.UPDATE_SUCCESS, tenant }
    }

    function failure(error) {
        return { type: tenantConstants.UPDATE_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: tenantConstants.CLEAR_RESULTS }
    }
}

function insert(tenant) {
    return dispatch => {
        dispatch(request())
        tenantService.createTenant(tenant).then(
            tenant => {
                dispatch(success(tenant))
                dispatch(alertActions.success(i18next.t('services.tenants.insertSuccess')))
                history.push('/tenants')
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OpUserNotFound:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.opUserNotFound'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.InvalidProfile:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.invalidProfile'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.insertError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tenantConstants.CREATE_REQUEST }
    }

    function success(tenant) {
        return { type: tenantConstants.CREATE_SUCCESS, tenant }
    }

    function failure(error) {
        return { type: tenantConstants.CREATE_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        tenantService._delete(id).then(
            tenant => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.tenants.deleteSuccess')))
            },

            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.tenants.forbiddenDelete')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.CannotRemoveAmtuTenant:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.cannotRemoveAmtuTenant'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.InvalidProfile:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.invalidProfile'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OpUserNotFound:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.opUserNotFound'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.tenants.deleteError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tenantConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: tenantConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: tenantConstants.DELETE_FAILURE, error }
    }
}
