import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const notificationService = {
    getAll,
    getDeviationNotifications,
    getTypes,
    checkNotification,
    getNotificationsCount,
    getPushNotificationsByRecipientId,
    deleteNotifications,
    getDeviationNotificationsTypes,
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/notifications/getNotifications?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}


function getDeviationNotificationsTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/notifications/DeviationNotificationTypes?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getDeviationNotifications() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/notifications/GetDeviationNotifications?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function deleteNotifications(idList, type = null) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(idList),
    }

    const parameters = type ? `&notificationType=${type}` : ""

    return customFetch(`${stringConstants.URL_ApiGateway}/services/v1/Notifications/DeleteNotifications${parameters}`, requestOptions)
}

function getTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/notificationTypes?lang=' +
        lang,
        requestOptions,
    ).then(handleResponse)
}

function checkNotification(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/notifications/checkNotification?notificationId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

function getNotificationsCount() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/notifications/getNotificationsCount',
        requestOptions,
    ).then(handleResponse)
}

function getPushNotificationsByRecipientId(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/notifications/getPushNotificationsByRecipientId?recipientId=${customerId}&lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}
