export const initialStateKPI: KPIState = {
    confirmTrips: [],
    confirmTripsLoading: false,
    reservations: [],
    reservationsLoading: false,
    alerts: [],
    alertsLoading: false,
    customers: [],
    customersLoading: false,
    customersPerService: [],
    drivers: [],
    driversLoading: false,
    driverRequest: [],
    driverRequestLoading: false,
    journeys: [],
    journeysLoading: false,
    confirmGroupedTrips: [],
    confirmGroupedTripsLoading: false,
    driverCalendar: [],
    driverCalendarLoading: false,
    tripBooking: [],
    tripBookingLoading: false,
    vehicleGeneralData: [],
    vehicleGeneralDataLoading: false,
    error: null,
}

export interface KPIState {
    confirmTrips: any[]
    confirmTripsLoading: boolean
    reservations: any[]
    reservationsLoading: boolean
    alerts: any[]
    alertsLoading: boolean
    customers: any[]
    customersLoading: boolean
    customersPerService: any[]
    drivers: any[]
    driversLoading: boolean
    driverRequest: any[]
    driverRequestLoading: boolean
    journeys: any[]
    journeysLoading: boolean
    confirmGroupedTrips: any[]
    confirmGroupedTripsLoading: boolean
    driverCalendar: any[]
    driverCalendarLoading: boolean
    tripBooking: any[]
    tripBookingLoading: boolean
    vehicleGeneralData: any[]
    vehicleGeneralDataLoading: boolean
    error: string | null
}

export interface KPIAction {
    type: string
    confirmTrips?: any[]
    reservations?: any[]
    alerts?: any[]
    customers?: any[]
    customersPerService?: any[]
    drivers?: any[]
    driverRequest?: any[]
    journeys?: any[]
    confirmGroupedTrips?: any[]
    driverCalendar?: any[]
    tripBooking?: any[]
    vehicleGeneralData?: any[]
    error?: string
}
