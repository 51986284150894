import React from 'react'
import clsx from 'clsx'
import {
    CssBaseline,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    Container,
    Grid,
    Paper,
    ListItem,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MuiAlert from '@material-ui/lab/Alert'
import ListItems from './listItems'
import { Link } from 'react-router-dom'
import useStyles from './styles'
import MenuUser from './menu'
import { useSnackbar } from 'notistack'
import { withTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import store from '../../redux/store'
import { customerActions } from '../../redux/actions/customer_actions'

class DashboardView extends React.Component {
    Alert = props => {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    render() {
        const classes = this.props.styles
        const user = JSON.parse(localStorage.getItem('user_webAdmin')) || {
            value: { displayName: '' },
        }
        const {
            alertReducer,
            t,
            chatReducer,
            incidentReducer,
            notificationReducer,
            tripReducer,
            serviceReducer,
            isBackgroundTransparent,
        } = this.props
        const lastAlert =
            alertReducer.messages.length > 0
                ? alertReducer.messages[alertReducer.messages.length - 1]
                : null
        // to prevent multiple alert repeat in the render
        if (lastAlert) this.props.clear()
        return (
            <div className={classes.root} style={{ height: '100vh' }}>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    elevation={1}
                    className={clsx(classes.appBar, this.props.open && classes.appBarShift)}
                >
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="primary"
                            aria-label="open drawer"
                            onClick={this.props.toggleMenu}
                            className={clsx(
                                classes.menuButton,
                                this.props.open && classes.menuButtonHidden,
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="primary"
                            noWrap
                            className={classes.title}
                        >
                            <ListItem button component={Link} to="/home">
                                {this.props.theme.nameTenant + ' ' + t('dashboard.title')}
                            </ListItem>
                        </Typography>

                        <MenuUser
                            t={t}
                            logout={this.props.logout}
                            name={user.value.displayName || user.value.email}
                            cssClass={classes.username}
                            changeLanguage={this.props.changeLanguage}
                            notificationReducer={notificationReducer}
                        />
                    </Toolbar>
                </AppBar>

                <Drawer
                    className="scroll-hidden"
                    style={{ height: '100vh' }}
                    variant="permanent"
                    classes={{
                        paper: clsx(
                            classes.drawerPaper,
                            !this.props.open && classes.drawerPaperClose,
                        ),
                    }}
                    open={this.props.open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.props.toggleMenu}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List className="scroll">
                        <ListItems
                            t={t}
                            getTrips={this.props.getTrips}
                            getTripsPending={this.props.getTripsPending}
                            getJourneys={this.props.getJourneys}
                            getWheelJourneys={this.props.getWheelJourneys}
                            match={this.props.component.props.match}
                            clearAllResults={this.props.clearAllResults}
                            chatReducer={chatReducer}
                            incidentReducer={incidentReducer}
                            tripReducer={tripReducer}
                            serviceReducer={serviceReducer}
                        />
                    </List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container spacing={3} style={{ height: '100%', maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    elevation={isBackgroundTransparent ? 0 : 1}
                                    className={
                                        isBackgroundTransparent ? classes.paperContainer : ''
                                    }
                                >
                                    {lastAlert &&
                                        this.props.enqueueSnackbar(lastAlert.message, {
                                            variant: lastAlert.type,
                                            autoHideDuration: 9000,
                                            onClose: this.props.handleCloseAlert,
                                        })}
                                    {this.props.component}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </div>
        )
    }
}

function Hook(props) {
    const classes = useStyles()
    const openMenu = useSelector(state => state.customerReducer.isMenuOpen)

    const [openAlert] = React.useState(false)

    const toggleMenu = () => {
        store.dispatch(customerActions.toggleMenu())
    }

    const { enqueueSnackbar } = useSnackbar()
    const handleCloseAlert = (_, reason) => {
        if (reason === 'clickaway') {
            return
        }
        props.clear()
    }

    return (
        <DashboardView
            styles={classes}
            component={props.component}
            open={openMenu}
            logout={props.logout}
            alertReducer={props.alertReducer}
            authenticationReducer={props.authenticationReducer}
            tripReducer={props.authenticationReducer}
            serviceReducer={props.serviceReducer}
            openAlert={openAlert}
            toggleMenu={toggleMenu}
            handleCloseAlert={handleCloseAlert}
            enqueueSnackbar={enqueueSnackbar}
            {...props}
        />
    )
}

export default withTheme(Hook)
