import store from "./redux/store";
import { incidentActions } from "./redux/actions/incident_actions";
import { userService } from "./services/userService";
import { notificationActions } from "./redux/actions/notification_actions";
import { chatActions } from "./redux/actions/chat_actions";

export const onIncidentsListener = () => {
    const user = userService.getWithExpiry("user_webAdmin");
    const claims = userService.getWithExpiry("claims");
    if (!user) return;

    initialNotificationCalls(claims);
}

const initialNotificationCalls = (claims) => {
    if (claims == null) return;

    store.dispatch(chatActions.getNumberOfUnreadMessagesBySenderId())
    store.dispatch(incidentActions.getNumberOfTicketsToReviewFromOpUser(claims.mongo_id));
    store.dispatch(notificationActions.getNotificationsCount())
}