import { servicesConstants } from 'constants/services_constants'
import { initialStateService, ServiceAction, ServiceProp, ServiceState } from './types'
import { SERVICES_TYPE } from 'constants/types'
import { Service } from 'model/Service'
import { driverConstants } from 'constants/driver_constants'
import { vehiclesConstants } from 'constants/vehicles_constants'

export function serviceReducer(
    state: ServiceState = initialStateService,
    action: ServiceAction,
): ServiceState {
    switch (action.type) {
        case servicesConstants.GET_WEEK_DAYS_REQUEST:
        case servicesConstants.GET_PRICES_REQUEST:
        case servicesConstants.DELETE_REQUEST:
        case servicesConstants.EDIT_REQUEST:
        case servicesConstants.GET_ROUTES_REQUEST:
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_REQUEST:
        case servicesConstants.GET_SPECIFIC_ROUTE_REQUEST:
        case servicesConstants.GET_ZONES_STOPS_REQUEST:
        case servicesConstants.GET_JOURNEYS_REQUEST:
        case servicesConstants.GET_OLD_JOURNEYS_REQUEST:
        case servicesConstants.GET_ROUTE_STOPS_REQUEST:
        case servicesConstants.GET_TRIPS_REQUEST:
        case servicesConstants.GET_TRIPS_TYPES_REQUEST:
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_REQUEST:
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_REQUEST:
        case servicesConstants.GET_DRIVER_OBSERVATIONS_REQUEST:
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_REQUEST:
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_REQUEST:
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_REQUEST:
        case servicesConstants.GET_TRIP_OBSERVATIONS_REQUEST:
        case servicesConstants.ADD_TRIP_OBSERVATIONS_REQUEST:
        case servicesConstants.GET_TYPE_PERIODICITY_REQUEST:
        case servicesConstants.GET_TYPE_TYPOLOGY_REQUEST:
        case servicesConstants.GET_ANTICIPATION_TYPES_REQUEST:
        case servicesConstants.SET_TYPOLOGY_REQUEST:
        case servicesConstants.GET_REPORT_REQUEST:
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST:
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST:
        case servicesConstants.SET_SERVICE_LINE_REQUEST:
        case servicesConstants.SET_EXPEDITIONS_REQUEST:
        case servicesConstants.GET_KPI_TYPES_REQUEST:
        case servicesConstants.GET_KPIS_BY_TYPE_REQUEST:
        case servicesConstants.GET_KPIS_FILTER_GROUP_REQUEST:
        case servicesConstants.GET_KPI_TIME_UNITS_REQUEST:
        case servicesConstants.GET_KPI_CHANNEL_REQUEST:
        case servicesConstants.GET_BILLING_REQUEST:
        case servicesConstants.GET_SERVICE_LIST_REQUEST:
        case servicesConstants.GETALL_REQUEST:
        case servicesConstants.GET_TYPES_REQUEST:
        case servicesConstants.ADD_REQUEST:
        case servicesConstants.GET_SERVICES_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case servicesConstants.GET_WEEK_DAYS_FAILURE:
        case servicesConstants.GET_PRICES_FAILURE:
        case servicesConstants.DELETE_FAILURE:
        case servicesConstants.EDIT_FAILURE:
        case servicesConstants.GET_ROUTES_FAILURE:
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_FAILURE:
        case servicesConstants.GET_SPECIFIC_ROUTE_FAILURE:
        case servicesConstants.GET_ZONES_STOPS_FAILURE:
        case servicesConstants.GET_JOURNEYS_FAILURE:
        case servicesConstants.GET_OLD_JOURNEYS_FAILURE:
        case servicesConstants.GET_ROUTE_STOPS_FAILURE:
        case servicesConstants.GET_TRIPS_FAILURE:
        case servicesConstants.GET_TRIPS_TYPES_FAILURE:
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_FAILURE:
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_FAILURE:
        case servicesConstants.GET_DRIVER_OBSERVATIONS_FAILURE:
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_FAILURE:
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_FAILURE:
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_FAILURE:
        case servicesConstants.GET_TRIP_OBSERVATIONS_FAILURE:
        case servicesConstants.ADD_TRIP_OBSERVATIONS_FAILURE:
        case servicesConstants.GET_TYPE_PERIODICITY_FAILURE:
        case servicesConstants.GET_TYPE_TYPOLOGY_FAILURE:
        case servicesConstants.GET_ANTICIPATION_TYPES_FAILURE:
        case servicesConstants.GET_REPORT_FAILURE:
        case servicesConstants.GET_SERVICE_LINE_FAILURE:
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE:
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_FAILURE:
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_FAILURE:
        case servicesConstants.SET_SERVICE_LINE_FAILURE:
        case servicesConstants.SET_EXPEDITIONS_FAILURE:
        case servicesConstants.GET_KPI_TYPES_FAILURE:
        case servicesConstants.GET_KPIS_BY_TYPE_FAILURE:
        case servicesConstants.GET_KPIS_FILTER_GROUP_FAILURE:
        case servicesConstants.GET_KPI_TIME_UNITS_FAILURE:
        case servicesConstants.GET_KPI_CHANNEL_FAILURE:
        case servicesConstants.GET_BILLING_FAILURE:
        case servicesConstants.GET_SERVICE_LIST_FAILURE:
        case servicesConstants.GETALL_FAILURE:
        case servicesConstants.GET_TYPES_FAILURE:
        case servicesConstants.DETAIL_FAILURE:
        case servicesConstants.ADD_FAILURE:
        case servicesConstants.GET_SERVICES_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case servicesConstants.GET_KPI_TIME_UNITS_SUCCESS:
            return {
                ...state,
                pending: false,
                KPITimeUnits: action.results,
            }
        case servicesConstants.GET_ANTICIPATION_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                anticipationTypes: action.anticipations,
            }
        case servicesConstants.SET_SERVICE_LINE_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceLine: action.line,
            }
        case servicesConstants.GET_KPI_CHANNEL_SUCCESS:
            return {
                ...state,
                pending: false,
                kPITripOrigins: action.results,
            }
        case servicesConstants.SET_EXPEDITIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceLine: action.expeditions,
            }
        case servicesConstants.GET_REPORT_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case driverConstants.CLEAN_SERVICES_NAME:
            return {
                ...state,
                results: [],
            }
        case servicesConstants.GET_SERVICES_TYPES_SUCCESS:
            return {
                ...state,
                typePending: false,
                serviceTypes: action.types,
            }
        case vehiclesConstants.DETAIL_REQUEST:
            return {
                ...state,
                results: [],
            }
        case servicesConstants.DETAIL_REQUEST:
            return {
                ...state,
                pending: true,
                ranges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    },
                ],
                workingRanges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        shiftHours: [],
                        key: 'selection',
                    },
                ],
                service: null,
            }
        case servicesConstants.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                area: action.area,
            }
        case servicesConstants.GET_WEEK_DAYS_SUCCESS:
            return {
                ...state,
                pending: false,
                weekDays: action.days,
            }
        case servicesConstants.GET_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesService: action.types,
            }
        case servicesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
                allServices: action.all || state.allServices,
            }

        case servicesConstants.GET_USER_LIST_REQUEST: {
            return {
                ...state,
                isUserListPending: true,
            }
        }

        case servicesConstants.GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                isUserListPending: false,
                userList: action.userList,
            }
        }

        case servicesConstants.GET_USER_LIST_FAILURE: {
            return {
                ...state,
                isUserListPending: false,
                userList: [],
                error: action.error,
            }
        }
        case servicesConstants.GET_PRICES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesPrices: action.prices,
            }
        case servicesConstants.GET_ROUTES_SUCCESS:
            return {
                ...state,
                pending: false,
                routes: action.routes,
                allRoutes: action.all,
            }
        case servicesConstants.GET_ROUTES_FROM_VEHICLE_SUCCESS:
            return {
                ...state,
                pending: false,
                routes: action.routes,
            }
        case servicesConstants.GET_SPECIFIC_ROUTE_SUCCESS:
            return {
                ...state,
                pending: false,
                routes: action.route,
            }
        case servicesConstants.GET_ZONES_STOPS_SUCCESS:
            return {
                ...state,
                pending: false,
                zonesStops: action.zones,
                typesService: action.types,
            }
        case servicesConstants.SET_ROUTE_ID:
            return {
                ...state,
                pending: false,
                routeId: action.routeId,
            }
        case servicesConstants.GET_ROUTE_ID:
            return {
                ...state,
                pending: false,
            }
        case servicesConstants.GET_JOURNEYS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeys: action.journeys,
                allJourneys: action.all,
            }
        case servicesConstants.GET_OLD_JOURNEYS_SUCCESS:
            return {
                ...state,
                pending: false,
                oldJourneys: action.oldJourneys,
                allJourneys: action.all,
            }
        case servicesConstants.GET_ROUTE_STOPS_SUCCESS:
            return {
                ...state,
                pending: false,
                routeStops: action.routeStops,
            }
        case servicesConstants.GET_TRIPS_SUCCESS:
            return {
                ...state,
                pending: false,
                journeyTrips: action.journeyTrips,
            }
        case servicesConstants.GET_TRIPS_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                typesTrips: action.results,
            }
        case servicesConstants.GET_BILLING_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceBilling: action.results,
            }
        case servicesConstants.GET_SERVICE_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceList: action.results,
            }
        case servicesConstants.GET_SERVICE_LINE_REQUEST:
            return {
                ...state,
                pending: true,
                serviceLine: null,
            }
        case servicesConstants.GET_SERVICE_LINE_SUCCESS:
            return {
                ...state,
                pending: false,
                calendar: undefined,
                serviceLine: action.line,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS:
            return {
                ...state,
                serviceLineSingleStops: action.stops,
                pending: false,
            }
        case servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_SUCCESS:
            return {
                ...state,
                serviceLineReturnStops: action.stops,
                pending: false,
            }
        case servicesConstants.GET_TYPE_TYPOLOGY_SUCCESS:
            return {
                ...state,
                pending: false,
                typeTypologies: action.typologies,
            }
        case servicesConstants.SET_FILTER_SERVICE: {
            let otpNameFilter = action.prop === 'OTP' ? action.values : state.service_filterOTP
            return {
                ...state,
                service_filterOTP: otpNameFilter,
                service_filters: action.filters,
            }
        }
        case servicesConstants.CLEAN_FILTER_SERVICE:
            return {
                ...state,
                service_filterOTP: [],
                service_filters: null,
            }
        case servicesConstants.SET_FILTER_ROUTE:
            let dateFilter = action.prop === 'date' ? action.values : state.route_filterDate
            let route_otpNameFilter = action.prop === 'OTP' ? action.values : state.route_filterOTP
            let numStops = action.prop === 'numStop' ? action.values : state.route_filterNumStop
            let vehicleDisplayName =
                action.prop === 'vehicle' ? action.values : state.route_filterVehicle
            let serviceName = action.prop === 'service' ? action.values : state.route_filterService
            return {
                ...state,
                route_filterDate: dateFilter,
                route_filterOTP: route_otpNameFilter,
                route_filterNumStop: numStops,
                route_filterVehicle: vehicleDisplayName,
                route_filterService: serviceName,
                route_filters: action.filters,
            }
        case servicesConstants.CLEAN_FILTER_ROUTE:
            return {
                ...state,
                route_filterDate: [],
                route_filterOTP: [],
                route_filterNumStop: [],
                route_filterVehicle: [],
                route_filterService: [],
                route_filters: null,
            }
        case servicesConstants.SET_FILTER_JOURNEY: {
            let JdateFilter = action.prop === 'date' ? action.values : state.journey_filterDate
            let Jroute_otpNameFilter =
                action.prop === 'OTP' ? action.values : state.journey_filterOTP
            let JnumPass =
                action.prop === 'numPass' ? action.values : state.journey_filterNumPassenger
            let JvehicleDisplayName =
                action.prop === 'vehicle' ? action.values : state.journey_filterVehicle
            let JserviceName =
                action.prop === 'service' ? action.values : state.journey_filterService
            return {
                ...state,
                journey_filterDate: JdateFilter,
                journey_filterOTP: Jroute_otpNameFilter,
                journey_filterNumPassenger: JnumPass,
                journey_filterVehicle: JvehicleDisplayName,
                journey_filterService: JserviceName,
                journey_filters: action.filters,
            }
        }
        case servicesConstants.SET_FILTER_JOURNEY_DATES:
            if (action.typeDate === 'startDate')
                return {
                    ...state,
                    filterJourneyStartDate: action.value,
                }
            else
                return {
                    ...state,
                    filterJourneyEndDate: action.value,
                }
        case servicesConstants.CLEAN_FILTER_JOURNEY:
            return {
                ...state,
                journey_filterDate: [],
                journey_filterOTP: [],
                journey_filterNumPassenger: [],
                journey_filterVehicle: [],
                journey_filterService: [],
                journeysTableFilters: [],
                journey_filters: null,
            }
        case servicesConstants.GET_JOURNEYS_OBSERVATIONS_SUCCESS:
        case servicesConstants.ADD_JOURNEYS_OBSERVATIONS_SUCCESS:
        case servicesConstants.GET_TRIP_OBSERVATIONS_SUCCESS:
        case servicesConstants.ADD_TRIP_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                tripActivities: action.activities,
            }
        case servicesConstants.GET_DRIVER_OBSERVATIONS_SUCCESS:
        case servicesConstants.ADD_DRIVER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                driverActivities: action.activities,
            }
        case servicesConstants.GET_CUSTOMER_OBSERVATIONS_SUCCESS:
        case servicesConstants.ADD_CUSTOMER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                customerActivities: action.activities,
            }
        case servicesConstants.SET_TYPOLOGY_SUCCESS: {
            const updatedService = { ...state.service } as ServiceProp
            updatedService.serviceResponse = action.serviceUpdated
            return {
                ...state,
                service: updatedService,
                pending: false,
            }
        }
        case servicesConstants.SET_TYPOLOGY_FAILURE: {
            const refreshedService = { ...state.service }
            refreshedService.serviceResponse.serviceCustomerTipology =
                action.refreshTypology.serviceCustomerTipology
            refreshedService.serviceResponse.customerTipologies.bonusCustomers =
                action.refreshTypology.bonusCustomers
            return {
                ...state,
                pending: false,
                service: refreshedService,
                error: action.error,
            }
        }
        case servicesConstants.GET_KPI_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                kpiTypes: action.results,
            }
        case servicesConstants.GET_KPIS_BY_TYPE_SUCCESS:
            return {
                ...state,
                pending: false,
                kpisByType: action.results,
            }
        case servicesConstants.GET_KPIS_FILTER_GROUP_SUCCESS:
            return {
                ...state,
                pending: false,
                kpiFilters: action.results,
            }
        case servicesConstants.SET_WORKS_DAY:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    workingWeekDays: action.days,
                },
            }
        case servicesConstants.REMOVE_EXCLUDE_DAYS:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    excludedPeriods: state.calendar?.excludedPeriods.filter(
                        (_: Date, i: number) => i !== action.index,
                    ),
                },
            }
        case servicesConstants.DETAIL_SUCCESS:
            if (action.service.serviceResponse.calendar != null) {
                if (
                    action.service.serviceResponse.calendar.shiftDays.length == 0 ||
                    action.service.serviceResponse.calendar.requestShiftDays.length == 0
                )
                    action.service.serviceResponse.calendar = {
                        excludedPeriods: action.service.serviceResponse.calendar.excludedPeriods,
                        workingBankHolidays:
                            action.service.serviceResponse.calendar.workingBankHolidays,
                        shiftDays:
                            action.service.serviceResponse.calendar.shiftDays.length == 0 &&
                            action.service.serviceResponse.serviceType !=
                                SERVICES_TYPE.regularWithSchedule
                                ? [
                                      {
                                          weekDays: [],
                                          earliestStart: '',
                                          latestArrival: '',
                                          endsNextDay: true,
                                      },
                                  ]
                                : action.service.serviceResponse.calendar.shiftDays,
                        requestShiftDays:
                            action.service.serviceResponse.calendar.requestShiftDays.length == 0
                                ? [
                                      {
                                          weekDays: [],
                                          earliestStart: '',
                                          latestArrival: '',
                                          endsNextDay: true,
                                      },
                                  ]
                                : action.service.serviceResponse.calendar.requestShiftDays,
                        shiftBreaks:
                            action.service.serviceResponse.calendar.shiftBreaks.length == 0 &&
                            action.service.serviceResponse.serviceType !=
                                SERVICES_TYPE.regularWithSchedule
                                ? [
                                      {
                                          shiftDays: {
                                              weekDays: [],
                                              earliestStart: '',
                                              latestArrival: '',
                                              endsNextDay: true,
                                          },
                                          stop: {},
                                      },
                                  ]
                                : action.service.serviceResponse.calendar.shiftBreaks,
                    }
            }
            return {
                ...state,
                service: action.service,
                pending: false,
            }
        case servicesConstants.REMOVE_EXCLUDED_WORKING_DAYS:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    workingBankHolidays: state.calendar?.workingBankHolidays?.filter(
                        (_, i) => i !== action.index,
                    ),
                },
            }
        case servicesConstants.GET_CALENDAR_SUCCESS:
            return {
                ...state,
                calendar: action.calendar,
            }
        case servicesConstants.SET_RANGE_CALENDAR:
            return {
                ...state,
                ranges: action.range,
            }
        case servicesConstants.SET_EXCLUDED_WORKING_DAYS:
            state.calendar?.workingBankHolidays?.push(action.element)
            return {
                ...state,
                workingRanges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        shiftHours: [],
                        key: 'selection',
                    },
                ],
            }
        case servicesConstants.SET_WORKING_RANGE_CALENDAR:
            return {
                ...state,
                workingRanges: action.workingRange,
            }
        case servicesConstants.SET_EXCLUDE_DAYS:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    excludedPeriods: [...(state.calendar?.excludedPeriods || []), action.element],
                },
                ranges: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                    },
                ],
            }
        case servicesConstants.ADD_TIME_REQUESTSHIFT_DAY:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    requestShiftDays: [
                        ...(state.calendar?.requestShiftDays || []),
                        {
                            weekDays: [],
                            earliestStart: '',
                            latestArrival: '',
                            endsNextDay: true,
                        },
                    ],
                },
            }
        case servicesConstants.ADD_TIME_SHIFT_DAY:
            state?.calendar?.shiftDays?.push({
                weekDays: [],
                earliestStart: '',
                latestArrival: '',
                endsNextDay: true,
            })
            return {
                ...state,
            }
        case servicesConstants.ADD_TIME_SHIFT_BREAK:
            state?.calendar?.shiftBreaks?.push({
                shiftDays: {
                    weekDays: [],
                    earliestStart: '',
                    latestArrival: '',
                    endsNextDay: true,
                },
                stop: {},
            })
            return {
                ...state,
            }
        case servicesConstants.ADD_TIME_SHIFT_HOUR:
            state.calendar = state.calendar ?? { workingBankHolidays: [] }
            state.calendar.workingBankHolidays = state.calendar.workingBankHolidays ?? []
            state.calendar.workingBankHolidays[action.index] = state.calendar.workingBankHolidays[
                action.index
            ] ?? { shiftHours: [] }
            state.calendar.workingBankHolidays[action.index].shiftHours =
                state.calendar.workingBankHolidays[action.index].shiftHours ?? []
            state.calendar.workingBankHolidays[action.index].shiftHours.push({
                earliestStart: '',
                latestArrival: '',
                endsNextDay: true,
            })
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_SHIFT_DAY:
            const filtered_shiftDays =
                state.calendar?.shiftDays?.filter((_, i) => i !== action.index) || []
            state.calendar = {
                ...state.calendar,
                shiftDays: filtered_shiftDays,
            }
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_SHIFT_BREAK:
            let filtered_shiftBreaks =
                state.calendar?.shiftBreaks?.filter((_, i) => i !== action.index) || []
            state.calendar = {
                ...state.calendar,
                shiftBreaks: filtered_shiftBreaks,
            }
            return {
                ...state,
            }
        case servicesConstants.REMOVE_TIME_SHIFT_HOUR:
            const filtered_shiftHours =
                state.calendar?.workingBankHolidays?.[action.index]?.shiftHours?.filter(
                    (_, i) => i !== action.indexShiftHour,
                ) || []
            if (state.calendar?.workingBankHolidays?.[action.index]) {
                state.calendar.workingBankHolidays[action.index].shiftHours = filtered_shiftHours
            }
            return {
                ...state,
            }
        case servicesConstants.SET_SHIFT_DAY:
            return {
                ...state,
                shiftDays: action.shiftDay,
            }
        case servicesConstants.SET_SHIFT_BREAK:
            return {
                ...state,
                shiftBreaks: action.shiftBreak,
            }
        case servicesConstants.SET_REQUEST_SHIFT_DAY:
            return {
                ...state,
                requestShiftDays: action.requestShiftDay,
            }
        case servicesConstants.SET_SHIFT_HOUR:
            return {
                ...state,
                shiftDays: action.shiftHour,
            }
        case servicesConstants.REMOVE_TIME_REQUESTSHIFT_DAY:
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    requestShiftDays: state.calendar?.requestShiftDays?.filter(
                        (_, i) => i !== action.index,
                    ),
                },
            }
        case servicesConstants.SAVE_TABLE_FILTERS:
            return {
                ...state,
                tableFilters: action.tableFilters,
            }
        case servicesConstants.SAVE_JOURNEYS_TABLE_FILTERS:
            return {
                ...state,
                journeysTableFilters: action.journeysTableFilters,
            }
        case servicesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id),
            }
        case servicesConstants.SET_SERVICE_DATA_EDIT:
            return {
                ...state,
                service: {
                    ...state.service,
                    serviceResponse: {
                        ...((state.service as ServiceProp).serviceResponse as Service),
                        otpId: action.body.id,
                        otpName: action.body.name,
                    },
                    vehiclesResponse: [],
                },
            }

        default:
            return state
    }
}
