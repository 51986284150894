import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import i18next from 'i18next'
import moment from 'moment'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const serviceService = {
    getAll,
    getALLWithPagination,
    getAllWithZonesAndVehicles,
    getById,
    insert,
    update,
    _delete,
    servicesTypes,
    servicesPrices,
    getRoutes,
    getRoutesFromVehicle,
    getSpecificRoute,
    getServiceZonesWithStops,
    updateZoneStops,
    getWeekDays,
    setCalendar,
    getAvailableVehicleCapacity,
    getJourneys,
    getRouteStops,
    getJourneyTrips,
    getServicesTypes,
    getTripStatuses,
    serviceHasSameName,
    addActivityJourney,
    getJourneysActivities,
    getOldJourneys,
    getWheelJourneys,
    getDriverActivities,
    addActivityDriver,
    getCustomerActivities,
    addActivityCustomer,
    setAbsences,
    removeCurrentPenalty,
    setCustomerBilling,
    setHolderBilling,
    getTypeTipologies,
    getTipologyPeriodicity,
    getTripActivities,
    addActivityTrip,
    anticipationTypes,
    getTripsByJourneys,
    getServicesByOtp,
    getServiceLine,
    getServiceLineAvailableStops,
    setServiceLine,
    setExpeditions,
    getServiceBilling,
    getKPITypes,
    getKPIsByType,
    getKPIGroups,
    getDestinationZones,
    getConfirmedGroupedTripsKPI,
    disable,
    enable,
    sendPushToServiceUsers,
    getServiceAnticipationMaxRequestTimeDays,
    getUsersFromService,
}

function anticipationTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/services/anticipationTypes?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function getUsersFromService(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Trips/GetTripsByServiceId?serviceId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getServiceAnticipationMaxRequestTimeDays(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/services/getServiceAnticipationMaxRequestTimeDays?serviceId=${serviceId}`,
        requestOptions,
    ).then(handleResponse)
}

function getServiceBilling(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/services/getServiceBilling?serviceId=${serviceId}`,
        requestOptions,
    ).then(handleResponse)
}

function getAvailableVehicleCapacity(vehicleId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetAvailableVehicleCapacity?vehicleId=${vehicleId}`,
        requestOptions,
    ).then(handleResponse)
}

function setAbsences(absences, customerId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(absences),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/absences/setAbsences?customerId=' +
        customerId,
        requestOptions,
    ).then(handleResponse)
}

function setServiceLine(serviceLine) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(serviceLine),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/serviceLine/setServiceLine',
        requestOptions,
    ).then(handleResponse)
}

function setExpeditions(expeditions, serviceId, direction, expeditionMinutes) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(expeditions),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/serviceLine/setServiceLineExpeditions?serviceId=' +
        serviceId +
        '&direction=' +
        direction +
        '&expeditionMinutes=' +
        expeditionMinutes,
        requestOptions,
    ).then(handleResponse)
}

function sendPushToServiceUsers(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/sendPushToServiceUsers',
        requestOptions,
    ).then(handleResponse)
}

function removeCurrentPenalty(absencesId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/absences/removeCurrentPenalty?absencesId=' +
        absencesId,
        requestOptions,
    ).then(handleResponse)
}

function setCustomerBilling(typology) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(typology),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/setCustomerBilling',
        requestOptions,
    ).then(handleResponse)
}

function setHolderBilling(typology) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(typology),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/setHolderBilling',
        requestOptions,
    ).then(handleResponse)
}

function getAll(includeRemovedEntries) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/services/GetServices?includeRemovedEntries=${includeRemovedEntries}`,
        requestOptions,
    ).then(handleResponse)
}
function getALLWithPagination(page = -1, pageSize = 10, includeRemovedEntries = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/services?page=${page}&entriesPerPage=${pageSize}&includeRemovedEntries=${includeRemovedEntries}`,
        requestOptions,
    ).then(handleResponse)
}

function getAllWithZonesAndVehicles() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/GetServicesWithZonesAndVehicles',
        requestOptions,
    ).then(handleResponse)
}

function servicesTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/serviceZones/zoneStopTypes?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getServicesTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/serviceTypes?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getWeekDays() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/weekDays?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function servicesPrices() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/ServicePriceTypes?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/services/getServiceWithZonesAndVehiclesById?serviceId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

function getServiceZonesWithStops(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/serviceZones/GetServiceZonesWithStops?serviceId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

function serviceHasSameName(id, name) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/services/serviceHasSameName?id=' +
        id +
        '&name=' +
        name,
        requestOptions,
    ).then(handleResponse)
}

function insert(zone) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/addService',
        requestOptions,
    ).then(handleResponse)
}

function setCalendar(calendar, idService, hasRestrictionsOnRequests) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(calendar),
    }
    const hasRestriction = hasRestrictionsOnRequests ? true : false
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/services/setCalendar?id=' +
        idService +
        '&hasRestrictionsOnRequests=' +
        hasRestriction,
        requestOptions,
    ).then(handleResponse)
}

function update(zone) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(zone),
    }
    const id = zone.id
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/update?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function updateZoneStops(id, stops) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(stops),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/serviceZones/updateServiceZoneStops?serviceId=' +
        id,
        requestOptions,
    ).then(handleResponse)
}

function _delete(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/remove?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function disable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/disableService?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function enable(id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/enableService?id=' + id,
        requestOptions,
    ).then(handleResponse)
}

function getRoutes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetRoutes`,
        requestOptions,
    ).then(handleResponse)
}

function getRoutesFromVehicle(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetRoutesFromVehicle?vehicleId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getSpecificRoute(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetRouteById?routeId=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getJourneys() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneys`,
        requestOptions,
    ).then(handleResponse)
}

function getTripsByJourneys(tripId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneysTrips?tripId=` + tripId,
        requestOptions,
    ).then(handleResponse)
}

function getOldJourneys(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    if ((startDate != null) & (finishDate != null))
        return customFetch(
            `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetJourneys` +
            '?startDate=' +
            encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    else
        return customFetch(
            `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetJourneys` +
            '?startDate=' +
            encodeURIComponent(moment().format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment().format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    /*return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Routes/GetJourneys`,
        requestOptions,
    ).then(handleResponse)*/
}

function getWheelJourneys(startDate, finishDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    if ((startDate != null) & (finishDate != null))
        return customFetch(
            `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneys` +
            '?startDate=' +
            encodeURIComponent(moment(startDate).format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment(finishDate).format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    else
        return customFetch(
            `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneys` +
            '?startDate=' +
            encodeURIComponent(moment().format('MM/DD/YYYY')) +
            '&finishDate=' +
            encodeURIComponent(moment().format('MM/DD/YYYY')),
            requestOptions,
        ).then(handleResponse)
    /*return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneys`,
        requestOptions,
    ).then(handleResponse)*/
}

function getRouteStops(journeyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneyStops?journeyId=${journeyId}`,
        requestOptions,
    ).then(handleResponse)
}

function getJourneyTrips(journeyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Journeys/GetJourneyTrips?journeyId=${journeyId}`,
        requestOptions,
    ).then(handleResponse)
}

function getJourneysActivities(idJourney) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Activity/getJourneyActivities?journeyId=${idJourney}`,
        requestOptions,
    ).then(handleResponse)
}

function addActivityJourney(activities, idJourney) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(activities),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/Activity/addJourneyActivity?journeyId=' +
        idJourney,
        requestOptions,
    ).then(handleResponse)
}

function getDriverActivities(driverId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/drivers/v1/Activity/GetDriverActivities?driverId=${driverId}`,
        requestOptions,
    ).then(handleResponse)
}

function addActivityDriver(activities, driverId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(activities),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/drivers/v1/Activity/AddDriverActivity?driverId=' +
        driverId,
        requestOptions,
    ).then(handleResponse)
}

function getCustomerActivities(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/customers/v1/Activity/GetCustomerActivities?customerId=${customerId}`,
        requestOptions,
    ).then(handleResponse)
}

function addActivityCustomer(activities, customerId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(activities),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/customers/v1/Activity/AddCustomerActivity?customerId=' +
        customerId,
        requestOptions,
    ).then(handleResponse)
}

/** */
function getTripActivities(tripId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/services/v1/Activity/GetTripActivities?tripId=${tripId}`,
        requestOptions,
    ).then(handleResponse)
}

function addActivityTrip(activities, tripId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(activities),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/Activity/AddTripActivity?tripId=' + tripId,
        requestOptions,
    ).then(handleResponse)
}

/** */

function getTripStatuses() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/tripStatus/TripStatuses?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getServiceLine(serviceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/serviceLine/getServiceLine?serviceId=' +
        serviceId,
        requestOptions,
    ).then(handleResponse)
}

function getServiceLineAvailableStops(serviceId, direction) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/serviceLine/getServiceLineAvailableStops?serviceId=' +
        serviceId +
        '&direction=' +
        direction,
        requestOptions,
    ).then(handleResponse)
}

function getTypeTipologies() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/customers/v1/customers/customerTipologies?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getTipologyPeriodicity() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    const lang = i18next.language
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/Periodicity?lang=' + lang,
        requestOptions,
    ).then(handleResponse)
}

function getServicesByOtp(otpId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/services/v1/services/getServicesByOtp?otpId=' + otpId,
        requestOptions,
    ).then(handleResponse)
}

function getConfirmedGroupedTripsKPI(start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/KPI/getConfirmedGroupedTripsKPI?startDate=' +
        start +
        '&finishDate=' +
        end,
        requestOptions,
    ).then(handleResponse)
}

function getKPITypes(lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/KPI/KPIsTypes?lang=${lang}`,
        requestOptions,
    ).then(handleResponse)
}

function getKPIsByType(id, lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway + `/services/v1/KPI/KPIsByType?lang=${lang}&kpiType=${id}`,
        requestOptions,
    ).then(handleResponse)
}

function getKPIGroups(kpiType, lang) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return customFetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/KPI/KPIGroups?lang=${lang}&kpiType=${kpiType}`,
        requestOptions,
    ).then(handleResponse)
}

function getDestinationZones(zoneId, lat, lon) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        stringConstants.URL_ApiGateway +
        `/services/v1/ServiceZones/GetDestinationZones?zoneId=${zoneId}`,
        requestOptions,
    ).then(handleResponse)
}
