export const incidentConstants = {
    GET_ALL_REQUEST: "GET_ALL_REQUEST",
    GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
    GET_ALL_FAILURE: "GET_ALL_FAILURE",

    ASIGN_INCIDENCE_TO_USER_REQUEST: "ASIGN_INCIDENCE_TO_USER_REQUEST",
    ASIGN_INCIDENCE_TO_USER_SUCCESS: "ASIGN_INCIDENCE_TO_USER_SUCCESS",
    ASIGN_INCIDENCE_TO_USER_FAILURE: "ASIGN_INCIDENCE_TO_USER_FAILURE",

    CLOSE_INCIDENCE_REQUEST: "CLOSE_INCIDENCE_REQUEST",
    CLOSE_INCIDENCE_SUCCESS: "CLOSE_INCIDENCE_SUCCESS",
    CLOSE_INCIDENCE_FAILURE: "CLOSE_INCIDENCE_FAILURE",

    REOPEN_INCIDENCE_REQUEST: "REOPEN_INCIDENCE_REQUEST",
    REOPEN_INCIDENCE_SUCCESS: "REOPEN_INCIDENCE_SUCCESS",
    REOPEN_INCIDENCE_FAILURE: "REOPEN_INCIDENCE_FAILURE",

    GET_PRIORITIES_REQUEST: "GET_PRIORITIES_REQUEST",
    GET_PRIORITIES_SUCCESS: "GET_PRIORITIES_SUCCESS",
    GET_PRIORITIES_FAILURE: "GET_PRIORITIES_FAILURE",

    GET_CAUSES_REQUEST: "GET_CAUSES_REQUEST",
    GET_CAUSES_SUCCESS: "GET_CAUSES_SUCCESS",
    GET_CAUSES_FAILURE: "GET_CAUSES_FAILURE",

    GET_TICKET_STATUS_REQUEST: "GET_TICKET_STATUS_REQUEST",
    GET_TICKET_STATUS_SUCCESS: "GET_TICKET_STATUS_SUCCESS",
    GET_TICKET_STATUS_FAILURE: "GET_TICKET_STATUS_FAILURE",

    GET_SPECIFIC_INCIDENT_REQUEST: "GET_SPECIFIC_INCIDENT_REQUEST",
    GET_SPECIFIC_INCIDENT_SUCCESS: "GET_SPECIFIC_INCIDENT_SUCCESS",
    GET_SPECIFIC_INCIDENT_FAILURE: "GET_SPECIFIC_INCIDENT_FAILURE",

    ADD_ACTIVITY_REQUEST: "ADD_ACTIVITY_REQUEST",
    ADD_ACTIVITY_SUCCESS: "ADD_ACTIVITY_SUCCESS",
    ADD_ACTIVITY_FAILURE: "ADD_ACTIVITY_FAILURE",

    SET_INCIDENCE_PRIORITY_REQUEST: "SET_INCIDENCE_PRIORITY_REQUEST",
    SET_INCIDENCE_PRIORITY_SUCCESS: "SET_INCIDENCE_PRIORITY_SUCCESS",
    SET_INCIDENCE_PRIORITY_FAILURE: "SET_INCIDENCE_PRIORITY_FAILURE",

    SET_INCIDENCE_STATUS_REQUEST: "SET_INCIDENCE_STATUS_REQUEST",
    SET_INCIDENCE_STATUS_SUCCESS: "SET_INCIDENCE_STATUS_SUCCESS",
    SET_INCIDENCE_STATUS_FAILURE: "SET_INCIDENCE_STATUS_FAILURE",

    GET_ALL_ACTIVITIES_IN_INCIDENCE_REQUEST: "GET_ALL_ACTIVITIES_IN_INCIDENCE_REQUEST",
    GET_ALL_ACTIVITIES_IN_INCIDENCE_SUCCESS: "GET_ALL_ACTIVITIES_IN_INCIDENCE_SUCCESS",
    GET_ALL_ACTIVITIES_IN_INCIDENCE_FAILURE: "GET_ALL_ACTIVITIES_IN_INCIDENCE_FAILURE",

    RECEIVE_EMAIL_NOTIFICATIONS_REQUEST: "RECEIVE_EMAIL_NOTIFICATIONS_REQUEST",
    RECEIVE_EMAIL_NOTIFICATIONS_SUCCESS: "RECEIVE_EMAIL_NOTIFICATIONS_SUCCESS",
    RECEIVE_EMAIL_NOTIFICATIONS_FAILURE: "RECEIVE_EMAIL_NOTIFICATIONS_FAILURE",

    RECEIVE_PUSH_NOTIFICATIONS_REQUEST: "RECEIVE_PUSH_NOTIFICATIONS_REQUEST",
    RECEIVE_PUSH_NOTIFICATIONS_SUCCESS: "RECEIVE_PUSH_NOTIFICATIONS_SUCCESS",
    RECEIVE_PUSH_NOTIFICATIONS_FAILURE: "RECEIVE_PUSH_NOTIFICATIONS_FAILURE",

    GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST: "GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST",
    GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS: "GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS",
    GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE: "GET_OP_USERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE",

    GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_REQUEST: "GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_REQUEST",
    GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS: "GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS",
    GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_FAILURE: "GET_OP_USERS_WANTS_PUSH_NOTIFICATIONS_FAILURE",

    GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST: "GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_REQUEST",
    GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS: "GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_SUCCESS",
    GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE: "GET_CUSTOMERS_WANTS_EMAIL_NOTIFICATIONS_FAILURE",

    GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_REQUEST: "GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_REQUEST",
    GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS: "GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_SUCCESS",
    GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_FAILURE: "GET_CUSTOMERS_WANTS_PUSH_NOTIFICATIONS_FAILURE",

    IS_THERE_SOMETHING_TO_REVIEW_REQUEST: "IS_THERE_SOMETHING_TO_REVIEW_REQUEST",
    IS_THERE_SOMETHING_TO_REVIEW_SUCCESS: "IS_THERE_SOMETHING_TO_REVIEW_SUCCESS",
    IS_THERE_SOMETHING_TO_REVIEW_FAILURE: "IS_THERE_SOMETHING_TO_REVIEW_FAILURE",

    SET_PENDING_TO_REVIEW_REQUEST: "SET_PENDING_TO_REVIEW_REQUEST",
    SET_PENDING_TO_REVIEW_SUCCESS: "SET_PENDING_TO_REVIEW_SUCCESS",
    SET_PENDING_TO_REVIEW_FAILURE: "SET_PENDING_TO_REVIEW_FAILURE",

    GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_REQUEST: "GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_REQUEST",
    GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_SUCCESS: "GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_SUCCESS",
    GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_FAILURE: "GET_NUMBER_OF_TICKETING_TO_REVIEW_FROM_OP_USER_FAILURE",

    INSERT_INCIDENCE_REQUEST: "INSERT_INCIDENCE_REQUEST",
    INSERT_INCIDENCE_SUCCESS: "INSERT_INCIDENCE_SUCCESS",
    INSERT_INCIDENCE_FAILURE: "INSERT_INCIDENCE_FAILURE",

    ADD_IMAGES_TO_INCIDENCE_REQUEST: "ADD_IMAGES_TO_INCIDENCE_REQUEST",
    ADD_IMAGES_TO_INCIDENCE_SUCCESS: "ADD_IMAGES_TO_INCIDENCE_SUCCESS",
    ADD_IMAGES_TO_INCIDENCE_FAILURE: "ADD_IMAGES_TO_INCIDENCE_FAILURE",

    MODIFY_INCIDENCE_REQUEST: "MODIFY_INCIDENCE_REQUEST",
    MODIFY_INCIDENCE_SUCCESS: "MODIFY_INCIDENCE_SUCCESS",
    MODIFY_INCIDENCE_FAILURE: "MODIFY_INCIDENCE_FAILURE",

    SET_FILTER_INCIDENTS: "INCIDENTS_SET_FILTER",
    SET_FILTER_DATE_INCIDENCES: "INCIDENCE_SET_FILTER_DATE",
    CLEAN_FILTER_INCIDENTS: "INCIDENTS_CLEAN_FILTER"
}