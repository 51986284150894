import React from 'react'
import useDownloadCSV from '../hooks/useDownloadCSV'
import PropTypes from 'prop-types'
import CsvSVG from '../../../resources/svgs/csv'
import IconButton from './iconButton'
import useKpiLoading from '../hooks/useKpiLoading'
import i18next from 'i18next'
import { getXLSXBlob } from '../../../helpers/xlsxExport'
import { downloadBlob } from '../../../helpers/exports'

export default function DownloadPageCSV({ page }) {
    const { isLoading } = useKpiLoading()
    const { csvList } = useDownloadCSV({ page })

    const downloadCSV = () => {
        const blob = getXLSXBlob(csvList)
        const title = `${i18next.t(`commandBox.${page}.title`)}_kpi`
        downloadBlob(title, blob, 'xlsx')
    }

    return (
        <IconButton text="Descargar Csv" disabled={isLoading} onClickAction={downloadCSV}>
            <CsvSVG />
        </IconButton>
    )
}

DownloadPageCSV.propTypes = {
    page: PropTypes.string.isRequired,
}
