import { zonesConstants } from 'constants/zones_constants'
import { initialStateZone, ZoneAction, ZoneState } from './types'

export function zoneReducer(state = initialStateZone, action: ZoneAction): ZoneState {
    switch (action.type) {
        case zonesConstants.DETAIL_REQUEST:
            return { ...state, pending: true }
        case zonesConstants.DETAIL_SUCCESS:
            return { ...state, zone: action.zone, pending: false }
        case zonesConstants.DETAIL_FAILURE:
            return { ...state, pending: false, error: action.error }
        case zonesConstants.GETALL_REQUEST:
            return { ...state, pending: true }
        case zonesConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results || [],
                stops: action.stops || [],
            }
        case zonesConstants.GETALL_FAILURE:
            return { ...state, pending: false, results: [], error: action.error }
        case zonesConstants.ADD_SUCCESS:
            return { ...state, zone: action.zone }
        case zonesConstants.ADD_FAILURE:
            return { ...state, error: action.error }
        case zonesConstants.CHECK_STOP_SUCCESS:
            return {
                ...state,
                pending: false,
                contentToModal: action.conflictServices,
                openModalConflictZones: true,
            }
        case zonesConstants.CHECK_STOP_FAILURE:
            return { ...state, pending: false, error: action.error }
        case zonesConstants.DELETE_SUCCESS:
            return { ...state, results: state.results.filter(x => x.id !== action.id) }
        case zonesConstants.DELETE_FAILURE:
            return { ...state, error: action.error }
        case zonesConstants.SET_ZONES_SELECTED:
            return {
                ...state,
                zonesSelected: action.zones || [],
                zonesCombination: action.zonesCombination || [],
            }
        case zonesConstants.CLEAR_ZONES_SELECTED:
            return { ...state, zonesSelected: [], zonesCombination: [] }
        case zonesConstants.SET_COMBINATION_ZONE: {
            const item = {
                idZone: action.idZone,
                zones: action.values?.map(v => v.id).join(',') ?? '',
                values: action.values,
            }
            return state.zonesCombination.some(x => x.idZone === action.idZone)
                ? {
                      ...state,
                      zonesCombination: state.zonesCombination.map(x =>
                          x.idZone === action.idZone ? item : x,
                      ),
                  }
                : { ...state, zonesCombination: [...state.zonesCombination, item] }
        }
        case zonesConstants.CLEAR_COMBINATION_ZONE:
            return { ...state, zonesCombination: [] }
        case zonesConstants.CHECK_EMPTY:
            return {
                ...state,
                zonesCombination: state.zonesCombination.filter(x => x.zones !== ''),
            }
        case zonesConstants.ADD_STOP_SUCCESS:
            return {
                ...state,
                pending: false,
                zone: action.zone,
                results: state.results.map(content =>
                    content.id === action.zone?.id ? action.zone : content,
                ),
                zoneStops: action.zone?.stops || [],
            }
        case zonesConstants.ADD_STOP_FAILURE:
            return { ...state, pending: false, error: action.error }
        case zonesConstants.SET_ZONE_STOPS:
            return { ...state, zoneStops: action.stops || [] }
        case zonesConstants.SET_ZONE_STOPS_REFRESH:
            return { ...state, changeZone: action.boolean ?? false }
        case zonesConstants.CLOSE_MODAL:
            return { ...state, openModalConflictZones: false }
        case zonesConstants.GETALL_ACTIVED_STOPS_SUCCESS:
            return { ...state, allStops: action.results || [] }
        case zonesConstants.GETALL_ACTIVED_STOPS_FAILURE:
            return { ...state, pending: false, error: action.error, allStops: [] }
        default:
            return state
    }
}
