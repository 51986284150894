import { notificationsConstants } from '../../constants/notifications_constants'
import { notificationService } from '../../services/notificationService'
import { alertActions } from '../actions/alert_actions'
import { customerActions } from '../actions/customer_actions'
import i18next from 'i18next'
import { customerService } from '../../services/customerService'
import { history } from '../../helpers/history'
import { NOTIFICATION_TYPES } from '../../constants/types'

export const notificationActions = {
    getAll,
    getDeviation,
    getTypes,
    check,
    checkDeviation,
    getNotificationsCount,
    requestTripFromCall,
    getCustomerNotifications,
    deleteNotifications,
    deleteDeviationNotification,
    getDeviationNotificationTypes,
}

function getAll() {
    return dispatch => {
        dispatch(request())
        notificationService.getAll().then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.notifications.getAllError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: notificationsConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: notificationsConstants.GETALL_FAILURE, error }
    }
}


function getDeviationNotificationTypes() {
    return dispatch => {
        dispatch(request())
        notificationService.getDeviationNotificationsTypes().then(
            notificationTypes => {
                const prof = makeEnum(notificationTypes)
                dispatch(success(prof))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.notifications.getAllError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_REQUEST }
    }

    function success(notificationTypes) {
        return { type: notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_SUCCESS, notificationTypes }
    }

    function failure(error) {
        return { type: notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_FAILURE, error }
    }
}

function getDeviation() {
    return dispatch => {
        dispatch(request())
        notificationService.getDeviationNotifications().then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.notifications.getAllError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.GET_DEVIATION_REQUEST }
    }

    function success(results) {
        return { type: notificationsConstants.GET_DEVIATION_SUCCESS, results }
    }

    function failure(error) {
        return { type: notificationsConstants.GET_DEVIATION_FAILURE, error }
    }
}

function getTypes() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        notificationService.getTypes().then(
            notificationTypes => {
                const prof = makeEnum(notificationTypes)
                dispatch(success(prof))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.notifications.getAllError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.TYPES_REQUEST }
    }

    function success(notificationTypes) {
        return { type: notificationsConstants.TYPES_SUCCESS, notificationTypes }
    }

    function failure(error) {
        return { type: notificationsConstants.TYPES_FAILURE, error }
    }
}

function check(id, type) {
    return dispatch => {

        dispatch(request())
        notificationService.checkNotification(id).then(
            notification => {
                dispatch(success(id))
                dispatch(getNotificationsCount())
                dispatch(alertActions.success(i18next.t('services.notifications.checkSuccess')))
                dispatch(getAll())
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(i18next.t('services.notifications.checkError')))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.CHECK_REQUEST }
    }

    function success(id) {
        return { type: notificationsConstants.CHECK_SUCCESS, id }
    }

    function failure(error) {
        return { type: notificationsConstants.CHECK_FAILURE, error }
    }


}

function checkDeviation(id) {
    return dispatch => {
        dispatch(request())
        notificationService.checkNotification(id).then(
            notification => {
                dispatch(success(id))
                dispatch(getNotificationsCount())
                dispatch(alertActions.success(i18next.t('services.notifications.checkSuccess')))
                dispatch(getDeviation())
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(i18next.t('services.notifications.checkError')))
            },
        )
    }


    function request() {
        return { type: notificationsConstants.CHECK_DEVIATION_REQUEST }
    }

    function success(id) {
        return { type: notificationsConstants.CHECK_DEVIATION_SUCCESS, id }
    }

    function failure(error) {
        return { type: notificationsConstants.CHECK_DEVIATION_FAILURE, error }
    }
}

function deleteNotifications(idList) {
    return dispatch => {
        dispatch(request())
        notificationService.deleteNotifications(idList).then(
            result => {
                dispatch(success(idList))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(i18next.t('services.notifications.deleteError')))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.DELETE_LIST_REQUEST }
    }

    function success(idList) {
        return { type: notificationsConstants.DELETE_LIST_SUCCESS, idList }
    }

    function failure(error) {
        return { type: notificationsConstants.DELETE_LIST_FAILURE, error }
    }
}

function deleteDeviationNotification(idList) {
    return dispatch => {
        dispatch(request())
        notificationService.deleteNotifications(idList, NOTIFICATION_TYPES.TripPickupDelayed).then(
            result => {
                dispatch(success(idList))
            },
            error => {
                dispatch(failure(error))
                dispatch(alertActions.error(i18next.t('services.notifications.deleteError')))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.DELETE_DEVIATION_LIST_REQUEST }
    }

    function success(idList) {
        return { type: notificationsConstants.DELETE_DEVIATION_LIST_SUCCESS, idList }
    }

    function failure(error) {
        return { type: notificationsConstants.DELETE_DEVIATION_LIST_FAILURE, error }
    }
}

function requestTripFromCall(data, themeColor) {
    return dispatch => {
        dispatch(customerActions.checkProfile(data.customerId))
        customerService.getById(data.customerId).then(customer => {
            customer.customerId = customer.id
            const requestObject = {
                dropOffLocation: {
                    desc: {
                        description: data.ivrResponse.dropOffLocation.address,
                    },
                    location: {
                        address: data.ivrResponse.dropOffLocation.address,
                        desc: {
                            description: data.ivrResponse.dropOffLocation.address,
                        },
                        lat: data.ivrResponse.dropOffLocation.lat,
                        lng: data.ivrResponse.dropOffLocation.lon,
                    },
                },
                pickUpLocation: {
                    desc: {
                        description: data.ivrResponse.pickUpLocation.address,
                    },
                    location: {
                        address: data.ivrResponse.pickUpLocation.address,
                        desc: {
                            description: data.ivrResponse.pickUpLocation.address,
                        },
                        lat: data.ivrResponse.pickUpLocation.lat,
                        lng: data.ivrResponse.pickUpLocation.lon,
                    },
                },
                pickUpDate: new Date(data.ivrResponse.requestPickUpStartTime),
                customerToRequest: customer,
                numPassengers: data.ivrResponse.numPassengers,
            }
            dispatch(request(requestObject))
            dispatch(
                customerActions.setCustomer(
                    customer.id,
                    customer.relatedCustomersCount,
                    customer.fullName,
                    customer.addressPosition,
                    customer.favouriteAddresses,
                    customer.name,
                    customer.surname1,
                    customer.birthDate,
                    customer.relatedCustomers,
                    customer.language,
                    customer.pushToken,
                    themeColor,
                ),
            )
            history.push('/requestTrip')
        })
    }
    function request(request) {
        return { type: notificationsConstants.REQUEST_TRIP_FROM_CALL, request }
    }
}

function getNotificationsCount() {
    return dispatch => {
        notificationService.getNotificationsCount().then(
            results => {
                dispatch(success(results))
            },
            error => { },
        )
    }

    function success(results) {
        return {
            type: notificationsConstants.GET_NOTIFICATIONS_COUNT,
            results,
        }
    }
}

function getCustomerNotifications(customerId) {
    return dispatch => {
        dispatch(request())
        notificationService.getPushNotificationsByRecipientId(customerId).then(
            notifications => {
                dispatch(success(notifications))
            },
            error => {
                dispatch(
                    alertActions.error(
                        i18next.t('services.notifications.getCustomerNotificationsError'),
                    ),
                )
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_REQUEST }
    }

    function success(notifications) {
        return { type: notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_SUCCESS, notifications }
    }

    function failure(error) {
        return { type: notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}
