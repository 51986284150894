import { reportConstants } from '../../../constants/report_constants'
import { ReportState, ReportAction, Report, Periodicity, initialStateReport } from './types'

export function reportReducer(state = initialStateReport, action: ReportAction): ReportState {
    switch (action.type) {
        case reportConstants.GETALL_REQUEST:
        case reportConstants.INSERT_REQUEST:
        case reportConstants.DELETE_REQUEST:
        case reportConstants.DETAIL_REQUEST:
        case reportConstants.UPDATE_REQUEST:
        case reportConstants.GET_PERIODICITY_REQUEST:
            return { ...state, pending: true }

        case reportConstants.GETALL_SUCCESS:
            return { ...state, pending: false, reports: action.results as Report[] }

        case reportConstants.INSERT_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: [...state.reports, ...(action.results as Report[])],
            }

        case reportConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: state.reports.map(report =>
                    report.id === (action.result as Report).id ? (action.result as Report) : report,
                ),
            }

        case reportConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                reports: state.reports.filter(report => report.id !== action.id),
            }

        case reportConstants.DETAIL_SUCCESS:
            return { ...state, pending: false, report: action.result as Report }

        case reportConstants.GET_PERIODICITY_SUCCESS:
            return { ...state, pending: false, periodicity: action.results as Periodicity }

        case reportConstants.GETALL_FAILURE:
        case reportConstants.INSERT_FAILURE:
        case reportConstants.DELETE_FAILURE:
        case reportConstants.DETAIL_FAILURE:
        case reportConstants.UPDATE_FAILURE:
        case reportConstants.GET_PERIODICITY_FAILURE:
            return { ...state, pending: false, error: action.error }

        default:
            return state
    }
}
