export const initialStateIncident: IncidentState = {
    pending: false,
    pendingResults: false,
    pendingStatus: false,
    pendingPriorities: false,
    pendingCauses: false,
    isThereSomethingToReview: false,
    userWantsToReceiveEmails: false,
    userWantsToReceivePush: false,
    opUserWantsEmail: false,
    opUserWantsPush: false,
    customerWantsEmail: false,
    customerWantsPush: false,
    numberOfPendingToReview: 0,
    results: [],
    allIncidents: [],
    incident_filterPriority: [],
    incident_filterTicketStatus: [],
    incident_filterCreated: [],
    filters: null,
    ticketStatus: [],
    priorities: [],
    filterStartDate: '',
    filterEndDate: '',
    causes: [],
    activities: [],
}

export interface Incident {
    id: string
    title: string
    description: string
    priority: string
    ticketStatus: string
    createdAt: string
    updatedAt: string
    causes?: string[]
    activities?: Activity[]
    [key: string]: any
}

export interface Activity {
    id: string
    description: string
    createdAt: string
}

export interface IncidentState {
    pending: boolean
    pendingResults: boolean
    pendingStatus: boolean
    pendingPriorities: boolean
    pendingCauses: boolean
    isThereSomethingToReview: boolean
    userWantsToReceiveEmails: boolean
    userWantsToReceivePush: boolean
    opUserWantsEmail: boolean
    opUserWantsPush: boolean
    customerWantsEmail: boolean
    customerWantsPush: boolean
    numberOfPendingToReview: number
    results: Incident[]
    allIncidents: Incident[]
    incident_filterPriority: string[]
    incident_filterTicketStatus: string[]
    incident_filterCreated: string[]
    filters: any
    ticketStatus: string[]
    priorities: string[]
    filterStartDate?: string
    filterEndDate?: string
    causes: string[]
    activities: Activity[]
    incident?: Incident
    activity?: Activity
    priority?: string
    userAsigned?: any
    pendingToReview?: boolean
    error?: string
}

export interface IncidentAction {
    type: string
    results?: Incident[]
    all?: Incident[]
    incident?: Incident
    causes?: string[]
    priorities?: string[]
    status?: string[]
    activities?: Activity[]
    activity?: Activity
    priority?: string
    userWantsToReceiveEmails?: boolean
    userWantsToReceivePush?: boolean
    opUserWantsEmail?: boolean
    opUserWantsPush?: boolean
    customerWantsEmail?: boolean
    customerWantsPush?: boolean
    pendingToReview?: boolean
    isThereSomethingToReview?: boolean
    numberOfPendingToReview?: number
    error?: string
    filters?: any
    prop?: string
    values?: string[]
    typeDate?: 'startDate' | 'endDate'
    value?: string
}
