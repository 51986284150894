import { tenantConstants } from 'constants/tenant_constants'
import { initialStateTenant, TenantAction, TenantState } from './types'

export function tenantReducer(state = initialStateTenant, action: TenantAction): TenantState {
    switch (action.type) {
        case tenantConstants.CREATE_REQUEST:
        case tenantConstants.UPDATE_REQUEST:
        case tenantConstants.GET_TENANTS_REQUEST:
        case tenantConstants.GET_TENANT_REQUEST:
        case tenantConstants.GET_TENANT_NAME_AND_COLORS_REQUEST:
        case tenantConstants.GET_TENANT_ICON_REQUEST:
        case tenantConstants.GET_TENANT_NAME_REQUEST:
        case tenantConstants.DELETE_REQUEST:
            return { ...state, pending: true }

        case tenantConstants.CREATE_SUCCESS:
        case tenantConstants.GET_TENANTS_SUCCESS:
        case tenantConstants.GET_TENANT_NAME_AND_COLORS_SUCCESS:
        case tenantConstants.GET_TENANT_ICON_SUCCESS:
        case tenantConstants.GET_TENANT_NAME_SUCCESS:
        case tenantConstants.DELETE_SUCCESS:
            return { ...state, pending: false, results: action.results || [] }

        case tenantConstants.UPDATE_SUCCESS:
        case tenantConstants.GET_TENANT_SUCCESS:
            return { ...state, pending: false, tenant: action.tenant }

        case tenantConstants.CREATE_FAILURE:
        case tenantConstants.UPDATE_FAILURE:
        case tenantConstants.GET_TENANTS_FAILURE:
        case tenantConstants.GET_TENANT_FAILURE:
        case tenantConstants.GET_TENANT_NAME_AND_COLORS_FAILURE:
        case tenantConstants.GET_TENANT_ICON_FAILURE:
        case tenantConstants.GET_TENANT_NAME_FAILURE:
        case tenantConstants.DELETE_FAILURE:
            return { ...state, pending: false, error: action.error }

        default:
            return state
    }
}
