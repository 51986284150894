import i18next from 'i18next'
import { servicesConstants } from '../../constants/services_constants'
import { alertActions } from './alert_actions'
import { regularLineService } from '../../services/regularLineService'
import { tripConstants } from '../../constants/trip_constants'
import { tripActions } from './trip_actions'
import { serviceActions } from './services_actions'
import { history } from '../../helpers/history'
import { zoneActions } from './zones_actions'
import { SERVICES_TYPE } from '../../constants/types'

export const regularLine_actions = {
    getServiceLines,
    getServiceLine,
    getServiceLineStopsAvailable,
    getRegularLineUserTariff,
    setServiceLine,
    setRegularLineExpeditions,
    setRegularLineUserTariff,
    removeLine,
    getRegularLinePossibleDropoffs,
    getRegularLineStopScheduledHours,
    setCalendarLine,
}

function getServiceLines(serviceId) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLines(serviceId).then(
            line => {
                dispatch(success(line))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.GET_SERVICE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SERVICE_LINE_FAILURE, error }
    }
}

function setRegularLineExpeditions(lineId, expeditions) {
    return dispatch => {
        dispatch(request())
        regularLineService.setRegularLineExpeditions(lineId, expeditions).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.expeditions.success')))
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (detailError) {
                        case 'InvalidParameters':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.InvalidParameters'),
                                ),
                            )
                            break
                        case 'DuplicatedExpedition':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.DuplicatedExpedition'),
                                ),
                            )
                            break
                        case 'ExpeditionOrderInvalid':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.ExpeditionOrderInvalid',
                                    ),
                                ),
                            )
                            break
                        case 'InvalidNumExpeditions':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.InvalidNumExpeditions',
                                    ),
                                ),
                            )
                            break
                        case 'ExpeditionHasTrips':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ExpeditionHasTrips'),
                                ),
                            )
                            break
                        case 'RemovedService':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.RemovedService'),
                                ),
                            )
                            break
                        case 'ServiceTypeNotValid':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceTypeNotValid'),
                                ),
                            )
                            break
                        case 'ServiceLineInvalidStops':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.ServiceLineInvalidStops',
                                    ),
                                ),
                            )
                            break
                        case 'ServiceNotFound':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceNotFound'),
                                ),
                            )
                            break
                        case 'VehicleOutsideWorkingHours':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.VehicleOutsideWorkingHours',
                                    ),
                                ),
                            )
                            break
                        case 'ExpeditionOutOfServiceWorkingHours':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.ExpeditionOutOfServiceWorkingHours',
                                    ),
                                ),
                            )
                            break
                        default:
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.error'),
                                ),
                            )
                    }
                } catch (e) {
                    if (error != 403) dispatch(alertActions.error(error.toString()))
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_EXPEDITIONS_REQUEST }
    }

    function success(expeditions) {
        return { type: servicesConstants.SET_EXPEDITIONS_SUCCESS, expeditions }
    }

    function failure(error) {
        return { type: servicesConstants.SET_EXPEDITIONS_FAILURE, error }
    }
}

function getServiceLine(lineId) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLine(lineId).then(
            line => {
                dispatch(success(line))
                dispatch(
                    serviceActions.getCalendar(
                        line.lineCalendar,
                        SERVICES_TYPE.regularWithSchedule,
                    ),
                )
                dispatch(zoneActions.getAll(line.serviceId))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.GET_SERVICE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SERVICE_LINE_FAILURE, error }
    }
}

function setCalendarLine(calendar, lineId) {
    return dispatch => {
        dispatch(request())
        regularLineService.setCalendarLine(calendar, lineId).then(
            result => {
                dispatch(success(result))
                history.goBack()
                dispatch(alertActions.success(i18next.t('services.services.calendar.success')))
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (detailError) {
                        case 'CalendarPeriodsCollapsing':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.calendar.collapseCalendar'),
                                ),
                            )
                            break
                        case 'ShiftHoursCollapisng':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.calendar.collapseShiftDays'),
                                ),
                            )
                            break
                        case 'ShiftHourLimitExceed':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.calendar.ShiftHourLimitExceed'),
                                ),
                            )
                            break
                        case 'RequestShiftHoursCollapisng':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.calendar.collapseRequestShiftDays',
                                    ),
                                ),
                            )
                            break
                        case 'VehicleHasTrips':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.calendar.VehicleHasTrips'),
                                ),
                            )
                            break
                        case 'ServiceHasTrips':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.calendar.ServiceHasTrips'),
                                ),
                            )
                            break
                        case 'ExpeditionOutOfServiceWorkingHours':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.calendar.ExpeditionOutOfServiceWorkingHours',
                                    ),
                                ),
                            )
                            break
                        case 'WorkingBankHolidaysHoursCollapsing':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.calendar.WorkingBankHolidaysHoursCollapsing',
                                    ),
                                ),
                            )
                            break
                        case 'BreakShiftHourLimitExceed':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.calendar.BreakShiftHourLimitExceed',
                                    ),
                                ),
                            )
                            break
                        case 'BreakShiftHoursCollapisng':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.calendar.BreakShiftHoursCollapisng',
                                    ),
                                ),
                            )
                            break
                        default:
                            dispatch(
                                alertActions.error(i18next.t('services.services.calendar.error')),
                            )
                            break
                    }
                } catch (e) {
                    dispatch(alertActions.error(error.toString()))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_CALENDAR_REQUEST }
    }

    function success(calendar) {
        return { type: servicesConstants.SET_CALENDAR_SUCCESS, calendar }
    }

    function failure(error) {
        return { type: servicesConstants.SET_CALENDAR_FAILURE, error }
    }
}

function getRegularLineUserTariff(lineId) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLineUserTariff(lineId).then(
            line => {
                dispatch(success(line))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_REQUEST }
    }

    function success(typologies) {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_SUCCESS, typologies }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_FAILURE, error }
    }
}

function setRegularLineUserTariff(lineId, tariff) {
    return dispatch => {
        dispatch(request())
        regularLineService.setRegularLineUserTariff(lineId, tariff).then(
            line => {
                dispatch(success(line))
                dispatch(alertActions.success(i18next.t('services.services.typology.success')))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.services.typology.error')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_BILLING_REQUEST }
    }

    function success(typologies) {
        return { type: servicesConstants.SET_BILLING_SUCCESS, typologies }
    }

    function failure(error) {
        return { type: servicesConstants.SET_BILLING_FAILURE, error }
    }
}

function getServiceLineStopsAvailable(serviceId, lineId) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLineAvailableStops(serviceId, lineId).then(
            stops => {
                dispatch(successSingle(stops))
            },
            error => {
                dispatch(failureSingle(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST }
    }

    function successSingle(stops) {
        return { type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS, stops }
    }

    function failureSingle(error) {
        return { type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE, error }
    }
}

function getRegularLinePossibleDropoffs(requestDateTime, stop) {
    return dispatch => {
        dispatch(request())
        regularLineService.getRegularLinePossibleDropoffs(requestDateTime, stop.id).then(
            stops => {
                dispatch(success(stops))
                dispatch(setOrigin(stop))
                dispatch(
                    tripActions.openModalRequestTrip(
                        i18next.t('trips.maps.markers.selectDestination'),
                    ),
                )
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (detailError) {
                        case 'NoDropoffsInExpedition':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.NoDropoffsInExpedition',
                                    ),
                                ),
                            )
                            break
                        default:
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.error'),
                                ),
                            )
                            break
                    }
                } catch (e) {
                    if (error != 403) dispatch(alertActions.error(error.toString()))
                }

                dispatch(failure(error))
            },
        )
    }

    function setOrigin(stop) {
        return { type: tripConstants.SET_ORIGIN, stop }
    }

    function request() {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_REQUEST }
    }

    function success(stops) {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_SUCCESS, stops }
    }

    function failure(error) {
        return { type: tripConstants.GET_POSSIBLE_DESTINATIONS_REG_LINE_FAILURE, error }
    }
}

function getRegularLineStopScheduledHours(
    requestDateTime,
    stopIdPickUp,
    stopIdDropOff,
    isRequestByDropOff,
) {
    return dispatch => {
        dispatch(request())
        regularLineService
            .getRegularLineStopScheduledHours(
                requestDateTime,
                stopIdPickUp,
                stopIdDropOff,
                isRequestByDropOff,
            )
            .then(
                expeditions => {
                    dispatch(success(expeditions))
                },
                error => {
                    try {
                        let parsedError = JSON.parse(error)
                        let detailError = parsedError.detail
                        switch (detailError) {
                            default:
                                dispatch(
                                    alertActions.error(
                                        i18next.t('services.services.serviceLine.error'),
                                    ),
                                )
                                break
                        }
                    } catch (e) {
                        if (error != 403) dispatch(alertActions.error(error.toString()))
                    }
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_REQUEST }
    }

    function success(expeditions) {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_SUCCESS, expeditions }
    }

    function failure(error) {
        return { type: tripConstants.GET_SCHEDULED_PICKUP_REG_LINE_FAILURE, error }
    }
}

function setServiceLine(lineId, line) {
    return dispatch => {
        dispatch(request())
        regularLineService.setRegularLine(lineId, line).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.serviceLine.success')))
                dispatch(getServiceLineStopsAvailable(line.serviceId, lineId))
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (detailError) {
                        case 'InvalidParameters':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.InvalidParameters'),
                                ),
                            )
                            break
                        case 'SameNameRegularLineExists':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.SameNameRegularLineExists',
                                    ),
                                ),
                            )
                            break
                        case 'RemovedService':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.RemovedService'),
                                ),
                            )
                            break
                        case 'ServiceTypeNotValid':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceTypeNotValid'),
                                ),
                            )
                            break
                        case 'ServiceLineInvalidStops':
                            dispatch(
                                alertActions.error(
                                    i18next.t(
                                        'services.services.serviceLine.ServiceLineInvalidStops',
                                    ),
                                ),
                            )
                            break
                        case 'ServiceNotFound':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceNotFound'),
                                ),
                            )
                            break
                        default:
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.error'),
                                ),
                            )
                    }
                } catch (e) {
                    if (error != 403) dispatch(alertActions.error(error.toString()))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_SERVICE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.SET_SERVICE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.SET_SERVICE_LINE_FAILURE, error }
    }
}

function removeLine(lineId, serviceId) {
    return dispatch => {
        dispatch(request())
        regularLineService.removeLine(lineId).then(
            result => {
                dispatch(success(result))
                dispatch(
                    alertActions.success(
                        i18next.t('services.services.serviceLine.removeLineSuccess'),
                    ),
                )
                dispatch(getServiceLines(serviceId))
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (detailError) {
                        case 'RemovedService':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.RemovedService'),
                                ),
                            )
                            break
                        case 'LineHasTrips':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.LineHasTrips'),
                                ),
                            )
                            break
                        case 'ServiceTypeNotValid':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceTypeNotValid'),
                                ),
                            )
                            break
                        case 'ServiceNotFound':
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.ServiceNotFound'),
                                ),
                            )
                            break
                        default:
                            dispatch(
                                alertActions.error(
                                    i18next.t('services.services.serviceLine.removeLineError'),
                                ),
                            )
                    }
                } catch (e) {
                    if (error != 403) dispatch(alertActions.error(error.toString()))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.REMOVE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.REMOVE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.REMOVE_LINE_FAILURE }
    }
}
