import {
    CustomerTypology,
    Service,
    ServiceLine,
    ShiftDays,
    TripBilling,
    WorkingBankHoliday,
} from 'model/Service'
import { Vehicle } from 'model/Vehicle'
import { Action } from 'redux'

export const initialStateService: ServiceState = {
    pending: false,
    typePending: false,
    allServices: [],
    results: [],
    typesService: [],
    typesPrices: [],
    serviceTypes: [],
    typeTypologies: [],
    typePeriodicities: [],
    anticipationTypes: [],
    routes: [],
    allRoutes: [],
    journeys: [],
    oldJourneys: [],
    allJourneys: [],
    routeStops: [],
    journeyTrips: [],
    zonesStops: [],
    shiftDays: [],
    requestShiftDays: [],
    weekDays: [],
    typesTrips: [],
    userList: [],
    isUserListPending: false,
    service_filterOTP: [],
    service_filters: null,
    journey_filterDate: [],
    journey_filterOTP: [],
    journey_filterService: [],
    journey_filterNumStop: [],
    journey_filterVehicle: [],
    journey_filterNumPassenger: [],
    kpiTypes: [],
    kpisByType: {},
    kpiFilters: {},
    journey_filters: null,
    filterJourneyStartDate: new Date(),
    filterJourneyEndDate: new Date(),
    ranges: [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
    workingRanges: [
        { startDate: new Date(), endDate: new Date(), shiftHours: [], key: 'selection' },
    ],
    serviceLineSingleStops: null,
    serviceLineReturnStops: null,
    serviceBilling: [],
    serviceList: [],
    tableFilters: [],
    journeysTableFilters: [],
    tripActivities: [],
    driverActivities: [],
    customerActivities: [],
    routeId: '',
    shiftBreaks: [],
    service: null,
    serviceLine: null,
    error: null,
    route_filterDate: [],
    route_filterOTP: [],
    route_filterNumStop: [],
    route_filterVehicle: [],
    route_filterService: [],
    route_filters: null,
    KPITimeUnits: '',
    area: null,
    kPITripOrigins: null,
}

interface ShiftBreak {
    shiftDays: ShiftDays
    stop: any
}

interface Calendar {
    excludedPeriods?: any
    workingBankHolidays?: WorkingBankHoliday[]
    shiftDays?: ShiftDays[]
    requestShiftDays?: ShiftDays[]
    shiftBreaks?: ShiftBreak[]
    workingWeekDays?: number[]
}

interface Range {
    startDate: Date
    endDate: Date
    key: string
}

interface WeekDay {
    id: string
    name: string
}

interface TableFilter {
    date: string[]
    otpName: string[]
    numStops: string[]
    driverName: string[]
    serviceName: string[]
    numPassengers: string[]
}

export interface UserBookingInfo {
    customerId: string
    customerName: string
    bookingsFromCustomerService: number
    bookingsFromAPP: number
    bookingsFromWebCustomer: number
}

export interface ServiceState {
    pending: boolean
    typePending: boolean
    allServices: Service[] | []
    results: Service[]
    typesService: any[]
    typesPrices: any[]
    serviceTypes: any[]
    typeTypologies: any[]
    typePeriodicities: any[]
    anticipationTypes: any[]
    routes: any[]
    allRoutes: any[]
    journeys: any[]
    oldJourneys: any[]
    allJourneys: any[]
    routeStops: any[]
    journeyTrips: any[]
    userList: UserBookingInfo[]
    isUserListPending: boolean
    zonesStops: any[]
    shiftDays: ShiftDays | []
    requestShiftDays: ShiftDays | []
    weekDays: WeekDay[] | []
    typesTrips: any[]
    service_filterOTP: any[]
    service_filters: any | null
    journey_filterDate: string[]
    journey_filterOTP: string[]
    journey_filterService: string[]
    journey_filterNumStop: string[]
    journey_filterVehicle: string[]
    journey_filterNumPassenger: string[]
    kpiTypes: any[]
    kpisByType: Record<string, any>
    kpiFilters: Record<string, any>
    journey_filters: TableFilter | null
    filterJourneyStartDate: Date
    filterJourneyEndDate: Date
    ranges: Range[] | []
    workingRanges: Array<{ startDate: Date; endDate: Date; shiftHours: any[]; key: string }> | []
    serviceLineSingleStops: any | null
    serviceLineReturnStops: any | null
    serviceBilling: TripBilling[]
    calendar?: Calendar
    serviceList: Service[]
    tableFilters: any[]
    journeysTableFilters: any[]
    tripActivities: any[]
    driverActivities: any[]
    customerActivities: any[]
    shiftBreaks: any[]
    routeId: string
    error?: string | null
    service?: any
    serviceLine: ServiceLine | null
    route_filterDate: []
    route_filterOTP: []
    route_filterNumStop: []
    route_filterVehicle: []
    route_filterService: []
    route_filters: [] | null
    KPITimeUnits: string
    area: any
    kPITripOrigins: any
}

export interface ServiceProp {
    serviceResponse?: Service | ServiceProp | null
    vehiclesResponse?: Vehicle[] | null
    customerTipologies?: CustomerTypology | null
}

export interface ServiceAction extends Action {
    type: string
    [key: string]: any
}
