import { rolesConstants } from '../../../constants/roles_constants'
import { RolesState, RolesAction, Role, User, initialStateRole } from './types'

export function rolesReducer(state = initialStateRole, action: RolesAction): RolesState {
    switch (action.type) {
        case rolesConstants.GETALL_REQUEST:
        case rolesConstants.DELETE_REQUEST:
        case rolesConstants.UPDATE_REQUEST:
        case rolesConstants.DETAIL_REQUEST:
        case rolesConstants.GET_USERS_REQUEST:
            return { ...state, pending: true }

        case rolesConstants.GETALL_SUCCESS:
            return { ...state, pending: false, roles: action.results as Role[] }

        case rolesConstants.UPDATE_SUCCESS:
        case rolesConstants.DETAIL_SUCCESS:
            return { ...state, pending: false, role: action.role as Role }

        case rolesConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                roles: state.roles.filter(role => role.id !== action.id),
            }

        case rolesConstants.GET_USERS_SUCCESS:
            return { ...state, pending: false, users: action.users as User[] }

        case rolesConstants.GETALL_FAILURE:
        case rolesConstants.DELETE_FAILURE:
        case rolesConstants.UPDATE_FAILURE:
        case rolesConstants.DETAIL_FAILURE:
        case rolesConstants.GET_USERS_FAILURE:
            return { ...state, pending: false, error: action.error }

        default:
            return state
    }
}
