import { areasConstants } from '../../constants/areas_constants'
import { history } from '../../helpers/history'
import { areaService } from '../../services/areaService'
import { alertActions } from '../actions/alert_actions'
import i18next from 'i18next'

export const areaActions = {
    getAll,
    getById,
    insert,
    update,
    clearResults,
    remove,
    getAllMunicipalities,
    getAllRegions,
}

function getAll() {
    return dispatch => {
        dispatch(request())
        areaService.getAll().then(
            results => {
                results.forEach(area => {
                    let zones = area.zonesResponse
                    area.zones = zones
                        .map(function (elem) {
                            return elem.name
                        })
                        .join(' - ')
                })
                dispatch(success(results))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.areas.getAllError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.GETALL_REQUEST }
    }

    function success(results) {
        return { type: areasConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: areasConstants.GETALL_FAILURE, error }
    }
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        areaService.getById(id).then(
            area => {
                dispatch(success(area))
                if (redirect) {
                    history.push('/areas/' + id)
                }
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.areas.getByIdError')))
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: areasConstants.DETAIL_REQUEST, id }
    }

    function success(area) {
        return { type: areasConstants.DETAIL_SUCCESS, area }
    }

    function failure(error) {
        return { type: areasConstants.DETAIL_FAILURE, error }
    }
}

function update(area) {
    return dispatch => {
        dispatch(request())
        areaService.update(area).then(
            area => {
                dispatch(success(area))
                history.push('/areas')
                dispatch(alertActions.success(i18next.t('services.areas.updateSuccess')))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.areas.updateError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.EDIT_REQUEST }
    }

    function success(area) {
        return { type: areasConstants.EDIT_SUCCESS, area }
    }

    function failure(error) {
        return { type: areasConstants.EDIT_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: areasConstants.CLEAR_RESULTS }
    }
}

function insert(area) {
    return dispatch => {
        dispatch(request())
        areaService.insert(area).then(
            area => {
                dispatch(success(area))
                history.push('/areas')
                dispatch(alertActions.success(i18next.t('services.areas.insertSuccess')))
            },
            error => {
                dispatch(alertActions.error(error.toString()))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.ADD_REQUEST }
    }

    function success(area) {
        return { type: areasConstants.ADD_SUCCESS, area }
    }

    function failure(error) {
        return { type: areasConstants.ADD_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        areaService._delete(id).then(
            area => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.areas.deleteSuccess')))
            },
            error => {
                dispatch(alertActions.error(i18next.t('services.areas.deleteError')))
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: areasConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: areasConstants.DELETE_FAILURE, error }
    }
}

function getAllMunicipalities() {
    return dispatch => {
        dispatch(request())
        areaService.getAllMunicipalities().then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.GET_ALL_MUNICIPALITIES_REQUEST }
    }

    function success(results) {
        return { type: areasConstants.GET_ALL_MUNICIPALITIES_SUCCESS, results }
    }

    function failure(error) {
        return { type: areasConstants.GET_ALL_MUNICIPALITIES_FAILURE, error }
    }
}

function getAllRegions() {
    return dispatch => {
        dispatch(request())
        areaService.getAllRegions().then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: areasConstants.GET_ALL_REGIONS_REQUEST }
    }

    function success(results) {
        return { type: areasConstants.GET_ALL_REGIONS_SUCCESS, results }
    }

    function failure(error) {
        return { type: areasConstants.GET_ALL_REGIONS_FAILURE, error }
    }
}
