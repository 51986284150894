import { chatConstants } from '../../../constants/chat_constants'
import { ChatState, ChatAction, Conversation, Message, initialStateChat } from './types'

function handleChatError(state: ChatState, action: ChatAction): ChatState {
    return {
        ...state,
        pending: false,
        messages:
            action.type === chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE
                ? []
                : state.messages,
        conversations:
            action.type === chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE
                ? []
                : state.conversations,
        error: action.error,
    }
}

export function chatReducer(state = initialStateChat, action: ChatAction): ChatState {
    switch (action.type) {
        case chatConstants.SET_CURRENT_CONVERSATION:
            return {
                ...state,
                currentConversation: action.selected!,
            }
        case chatConstants.GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS:
            return {
                ...state,
                unreadMsg: action.results as number,
            }
        case chatConstants.NEW_MESSAGE_FLAG:
            return {
                ...state,
                newMessages: action.bool!,
            }
        case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_REQUEST:
            return {
                ...state,
                pendingGetAllMessagesFromConversation: true,
            }
        case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_SUCCESS:
            return {
                ...state,
                messages: action.results as Message[],
                pendingGetAllMessagesFromConversation: false,
            }
        case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_SUCCESS:
            return {
                ...state,
                conversations: action.results as Conversation[],
                pending: false,
            }
        case chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE:
        case chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE:
            return handleChatError(state, action)

        default:
            return state
    }
}
