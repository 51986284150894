import { Customer, KPI, RelatedCustomer } from 'model/Customer'
import { customerConstants } from '../../../constants/customer_constants'
import { servicesConstants } from '../../../constants/services_constants'
import { userConstants } from '../../../constants/user_constants'
import { CustomerState, CustomerAction, initialStateCustomer } from './types'
import { tripConstants } from 'constants/trip_constants'

export function customerReducer(
    state = initialStateCustomer,
    action: CustomerAction,
): CustomerState {
    switch (action.type) {
        case customerConstants.ADD_REQUEST:
        case customerConstants.DELETE_REQUEST:
        case customerConstants.RESET_PSW_REQUEST:
        case customerConstants.CHECK_PROFILE_REQUEST:
        case customerConstants.UPDATE_REQUEST:
        case customerConstants.CUSTOMER_GET_KPI_REQUEST:
        case customerConstants.GET_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
                error: null,
            }

        case customerConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true,
                customer: null,
            }

        case customerConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
                results: [],
            }

        case customerConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results as Customer[],
            }
        case customerConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }

        case servicesConstants.SET_ABSENCES_SUCCESS:
            if (state.customer) {
                return {
                    ...state,
                    customer: {
                        ...state.customer,
                        absences: action.absences,
                    },
                }
            }
            return state

        case customerConstants.GETBYID_SUCCESS:
            return {
                ...state,
                customer: action.customer,
                relatedCustomers: action.customer?.relatedCustomers || [],
                pending: false,
            }

        case customerConstants.GETBYID_FAILURE:
        case customerConstants.ADD_FAILURE:
        case customerConstants.DELETE_FAILURE:
        case customerConstants.CHECK_PROFILE_FAILURE:
        case customerConstants.UPDATE_FAILURE:
        case customerConstants.CUSTOMER_GET_KPI_FAILURE:
        case customerConstants.GET_DOCUMENT_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }

        case customerConstants.ADD_SUCCESS:
        case customerConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                customer: action.customer,
            }

        case customerConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(cust => cust.id !== action.id),
            }

        case userConstants.ADD_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: [...state.relatedCustomers, action.customer as RelatedCustomer],
            }

        case userConstants.REMOVE_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.filter(
                    item => item.tableData.id !== action.customer?.tableData.id,
                ),
            }

        case userConstants.EDIT_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.map(rc =>
                    rc.tableData.id === action.customer?.tableData.id ? { ...action.customer } : rc,
                ),
            }
        case tripConstants.SET_MODIFY_TRIP:
            return {
                ...state,
                favouriteAddresses: [],
                relatedCustomers: action.relatedCustomers ?? [],
            }

        case userConstants.OPEN_MODAL:
            return {
                ...state,
                add: action.typeOperation === 'add',
                edit: action.typeOperation === 'edit',
                userEdit: action.typeOperation === 'edit' ? action.user : null,
            }

        case customerConstants.SET_CUSTOMER:
            return {
                ...state,
                currentCustomer: action.id,
                countMax: action.countMax,
                fullName: action.fullName,
                address: action.address,
                name: action.name,
                surname: action.surname,
                favouriteAddresses: action.favouriteAddresses ?? [],
                customerBirthDate: action.customerDateOfBirth,
                langNotification: action.lang,
                relatedCustomers: action.relatedCustomers ?? [],
                customerPushToken: action.pushToken,
                hasWheelChair: action.hasWheelChair,
            }

        case customerConstants.CUSTOMER_GET_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                customersKPI: action.results as KPI[],
            }

        case customerConstants.GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                documentTypes: action.data as DocumentType[],
            }

        case customerConstants.SAVE_TABLE_STATE:
            return {
                ...state,
                tableState: action.tableState || [],
            }

        case userConstants.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            }

        case customerConstants.CHECK_PROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                canOperate: action.result,
            }
        default:
            return state
    }
}
