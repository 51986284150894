import { DelayedNotification, Notification } from 'model/Notification'
import { notificationsConstants } from '../../../constants/notifications_constants'
import { NotificationState, NotificationAction, initialStateNotifications } from './types'

export function notificationReducer(
    state = initialStateNotifications,
    action: NotificationAction,
): NotificationState {
    switch (action.type) {
        case notificationsConstants.GETALL_REQUEST:
            return { ...state, isFetchLoading: true }

        case notificationsConstants.GETALL_SUCCESS:
            return {
                ...state,
                isFetchLoading: false,
                results: action.results as Notification[],
            }

        case notificationsConstants.GETALL_FAILURE:
            return {
                ...state,
                isFetchLoading: false,
                results: [],
                error: action.error,
            }

        case notificationsConstants.TYPES_REQUEST:
        case notificationsConstants.DELETE_LIST_REQUEST:
        case notificationsConstants.DELETE_DEVIATION_LIST_REQUEST:
        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_REQUEST:
            return { ...state, pending: true }

        case notificationsConstants.TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                notificationTypes: action.notificationTypes || [],
            }

        case notificationsConstants.TYPES_FAILURE:
        case notificationsConstants.DELETE_LIST_FAILURE:
        case notificationsConstants.DELETE_DEVIATION_LIST_FAILURE:
        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_FAILURE:
            return { ...state, pending: false, error: action.error }

        case notificationsConstants.GET_DEVIATION_REQUEST:
            return { ...state, isDeviationLoading: true }

        case notificationsConstants.GET_DEVIATION_SUCCESS:
            return {
                ...state,
                isDeviationLoading: false,
                deviations: action.results as DelayedNotification[],
            }

        case notificationsConstants.GET_DEVIATION_FAILURE:
            return {
                ...state,
                isDeviationLoading: false,
                deviations: [],
                error: action.error,
            }

        case notificationsConstants.DELETE_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(
                    notification => !action.idList?.includes(notification.id),
                ),
            }

        case notificationsConstants.DELETE_DEVIATION_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                deviations: state.deviations.filter(
                    deviation => !action.idList?.includes(deviation.id),
                ),
            }

        case notificationsConstants.GET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                uncheckedNotifications: action.results as number,
            }

        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.notifications || [],
            }

        default:
            return state
    }
}
