
export const getUser = (user, customerReducer) => {
    return user
        ? user
        : customerReducer.edit
            ? customerReducer.userEdit
            : {}
}


const phoneTemplate = {
    phoneNumber: "",
    countryCode: "",
    prefix: "",
}
export const getId = (user) => {
    return user.id || ''
}

export const getName = (user) => {
    return user.name || ''
}

export const getSurname1 = (user) => {
    return user.surname1 || ''
}

export const getSurname2 = (user) => {
    return user.surname2 || ''
}

export const getEmail = (user) => {
    return user.email || ''
}

export const getDocumentType = (user) => {
    return user.documentType || 10
}

export const getIdentityDocumentCode = (user) => {
    return user.identityDocumentCode || ''
}

export const getSecondaryPhone = user => {
    return user.secondaryPhone || phoneTemplate
}

export const getPhone = (user) => {
    return user.phone || phoneTemplate
}

export const getBirthDate = (user) => {
    return user.birthDate || ''
}

export const getIsPMR = (user) => {
    return user.isPMR || false
}

export const getHasWheelChair = (user) => {
    return user.hasWheelChair || false
}

export const getIsDisabled = (user) => {
    return user.isDisabled || false
}

export const getAddress = (user) => {
    return user.addressPosition || {
        address: '',
        lat: '',
        lon: '',
    }
}