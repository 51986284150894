import { userConstants } from '../../../constants/user_constants'
import { AuthenticationState, Action, initialStateAuthentication } from './types'

export function authenticationReducer(
    state = initialStateAuthentication,
    action: Action,
): AuthenticationState {
    switch (action.type) {
        case userConstants.SAVE_MESSAGING_TOKEN:
            return {
                ...state,
                messagingToken: action.token!,
            }

        case userConstants.LOGIN_REQUEST:
        case userConstants.TOKEN_REQUEST:
        case userConstants.REFRESH_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                auth: true,
                refreshToken: action.data.user.refreshToken,
                user: action.data.user,
                pending: false,
            }

        case userConstants.LOGIN_FAILURE:
        case userConstants.TOKEN_FAILURE:
        case userConstants.REFRESH_FAILURE:
            return {
                ...state,
                auth: false,
                pending: false,
                error: action.error,
            }

        case userConstants.TOKEN_SUCCESS:
            return {
                ...state,
                name: action.data.claims.name,
                profile: action.data.claims.profile,
                mongoId: action.data.claims.mongo_id,
                token: action.data.token,
                customerId: action.data.claims.customer_id,
                Permissions: action.data.claims.permissions,
                roles: action.data.claims[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ],
                pending: false,
            }

        case userConstants.LOGOUT:
            return {
                ...state,
                auth: false,
                pending: false,
                refreshToken: null,
                user: null,
            }

        case userConstants.REFRESH_SUCCESS:
            return {
                ...state,
                pending: false,
                token: action.token,
            }

        default:
            return state
    }
}
