import { Operator } from 'model/Operator'
import { operatorsConstants } from '../../../constants/operator_constants'
import { OperatorState, OperatorAction, Tenant, initialStateOperator } from './types'

export function operatorReducer(
    state = initialStateOperator,
    action: OperatorAction,
): OperatorState {
    switch (action.type) {
        case operatorsConstants.DETAIL_REQUEST:
        case operatorsConstants.GETALL_REQUEST:
        case operatorsConstants.PROFILES_REQUEST:
        case operatorsConstants.REGISTER_REQUEST:
        case operatorsConstants.EDIT_REQUEST:
        case operatorsConstants.DELETE_REQUEST:
        case operatorsConstants.RESET_PSW_REQUEST:
        case operatorsConstants.UPDATE_PSW_REQUEST:
        case operatorsConstants.OP_GET_ALL_TENANTS_REQUEST:
            return { ...state, pending: true }

        case operatorsConstants.GETALL_SUCCESS:
            return { ...state, pending: false, results: action.results as Operator[] }

        case operatorsConstants.DETAIL_SUCCESS:
        case operatorsConstants.REGISTER_SUCCESS:
        case operatorsConstants.EDIT_SUCCESS:
            return { ...state, pending: false, operator: action.operator }

        case operatorsConstants.PROFILES_SUCCESS:
            return { ...state, pending: false, profilesAllowed: action.profiles || [] }

        case operatorsConstants.OP_GET_ALL_TENANTS_SUCCESS:
            return { ...state, pending: false, tenants: action.results as Tenant[] }

        case operatorsConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(op => op.id !== action.id),
            }

        case operatorsConstants.CLEAR_OPERATOR:
            return { ...state, operator: null }

        case operatorsConstants.OP_SET_PAGE_TABLE:
            return { ...state, currentPage: action.page ?? state.currentPage }

        case operatorsConstants.RESET_PSW_SUCCESS:
        case operatorsConstants.UPDATE_PSW_SUCCESS:
            return { ...state, pending: false }

        case operatorsConstants.DETAIL_FAILURE:
        case operatorsConstants.GETALL_FAILURE:
        case operatorsConstants.PROFILES_FAILURE:
        case operatorsConstants.REGISTER_FAILURE:
        case operatorsConstants.EDIT_FAILURE:
        case operatorsConstants.DELETE_FAILURE:
        case operatorsConstants.RESET_PSW_FAILURE:
        case operatorsConstants.UPDATE_PSW_FAILURE:
        case operatorsConstants.OP_GET_ALL_TENANTS_FAILURE:
            return { ...state, pending: false, error: action.error }

        default:
            return state
    }
}
