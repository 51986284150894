import { authHeader } from '../helpers/authHeader'
import stringConstants from '../constants/strings'
import { factory } from '../helpers/factory'
import firebase from 'firebase/app'

import '../firebase'
import moment from 'moment'
import { handleResponse } from '../helpers/services'
import { customFetch } from '.'

export const userService = {
    login,
    logout,
    getById,
    registerCustomer,
    authorizationToSendEmail,
    getTokenId,
    forgotPassword,
    refreshToken,
    getWithExpiry,
    setWithExpiry,
    subscribeToTopic,
    existRefreshToken,
}

function existRefreshToken() {
    const token = getWithExpiry('refreshToken_webAdmin')
    return token != null
}

function subscribeToTopic(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(
        `${stringConstants.URL_ApiGateway}/OpUsers/v1/Chat/SubscribeToTopic?token=${token}`,
        requestOptions,
    ).then(handleResponse)
}

function registerCustomer(email, password, firstName, lastName, document, gdprAccepted) {
    const args = factory.createCustomerRegistration(
        email,
        password,
        firstName,
        lastName,
        document,
        gdprAccepted,
    )
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(args),
    }
    return customFetch(
        stringConstants.URL_ApiGateway + '/customers/v1/register',
        requestOptions,
    ).then(handleResponse)
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return customFetch(stringConstants.URL_ApiGateway + '/customers/v1/' + id, requestOptions).then(
        handleResponse,
    )
}

function getTokenId() {
    return firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(data => {
            setWithExpiry('token_webAdmin', data.token, moment().add(1, 'h').toDate().getTime())
            return data
        })
}

function logout() {
    return firebase
        .auth()
        .signOut()
        .then(data => {
            return data
        })
}

function forgotPassword(email) {
    return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(data => {
            return data
        })
}

function refreshToken() {
    const token = getWithExpiry('refreshToken_webAdmin')
    const args = `grant_type=refresh_token&refresh_token=${token}`
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: args,
    }
    return fetch(stringConstants.URL_Refresh_Token, requestOptions)
        .then(handleResponse)
        .then(data => {
            setWithExpiry(
                'token_webAdmin',
                data.access_token,
                moment().add(1, 'h').toDate().getTime(),
            )
            // store token details and jwt token in local storage to keep user logged in between page refreshes
            setWithExpiry(
                'refreshToken_webAdmin',
                data.refresh_token,
                moment().add(2, 'years').toDate().getTime(),
            )
            return data.access_token
        })
}

function login(loginId, password) {
    return firebase
        .auth()
        .signInWithEmailAndPassword(loginId.toLowerCase(), password)
        .then(data => {
            return data
        })
}

function authorizationToSendEmail(email, password) {
    firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(data => {
            firebase.auth().currentUser.sendEmailVerification()
            firebase.auth().signOut()
        })
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    var date = new Date()
    var now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
    )
    const now = new Date(now_utc)
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

function setWithExpiry(key, value, ttl) {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}
