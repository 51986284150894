import { areasConstants } from '../../../constants/areas_constants'
import { AreaState, AreaAction, initialStateArea } from './types'

export function areaReducer(state = initialStateArea, action: AreaAction): AreaState {
    switch (action.type) {
        case areasConstants.DETAIL_REQUEST:
        case areasConstants.GETALL_REQUEST:
        case areasConstants.ADD_REQUEST:
        case areasConstants.DELETE_REQUEST:
        case areasConstants.GET_ALL_MUNICIPALITIES_REQUEST:
        case areasConstants.GET_ALL_REGIONS_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case areasConstants.DETAIL_SUCCESS:
        case areasConstants.ADD_SUCCESS:
            return {
                ...state,
                area: action.area,
                pending: false,
            }

        case areasConstants.GETALL_SUCCESS:
            return {
                ...state,
                results: action.results || [],
                pending: false,
            }

        case areasConstants.DELETE_SUCCESS:
            return {
                ...state,
                results: state.results.filter(x => x.id !== action.id),
                pending: false,
            }

        case areasConstants.GET_ALL_MUNICIPALITIES_SUCCESS:
            return {
                ...state,
                municipalities: action.results || [],
                pending: false,
            }

        case areasConstants.GET_ALL_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.results || [],
                pending: false,
            }

        case areasConstants.DETAIL_FAILURE:
        case areasConstants.GETALL_FAILURE:
        case areasConstants.ADD_FAILURE:
        case areasConstants.DELETE_FAILURE:
        case areasConstants.GET_ALL_MUNICIPALITIES_FAILURE:
        case areasConstants.GET_ALL_REGIONS_FAILURE:
            return {
                ...state,
                error: action.error,
                pending: false,
            }

        default:
            return state
    }
}
