import { chatConstants } from '../../constants/chat_constants'
import { chatService } from '../../services/chatService'
import { alertActions } from '../actions/alert_actions'
import { incidentActions } from '../actions/incident_actions'
import i18next from 'i18next'
import store from '../store'
import { notificationActions } from '../../redux/actions/notification_actions'
export const chatActions = {
    getAllConversationsBySenderId,
    removeConversation,
    createConversation,
    sendMessage,
    getAllMessagesfromConversation,
    deleteMessage,
    updateMessage,
    setNewMessageFlag,
    conversationReadMessages,
    setNumberOfUnreadMsgs,
    setcurrentConversation,
    getNumberOfUnreadMessagesBySenderId,
    getChatDataOnTabChange,
}

function getChatDataOnTabChange() {
    return dispatch => {
        const mongoIdFromLocalStorge = JSON.parse(localStorage.getItem('claims')).value.mongo_id
        const { chatReducer } = store.getState()
        const receiverIdFromCurrentConversation = chatReducer.currentConversation.recipientId
        dispatch(getNumberOfUnreadMessagesBySenderId())
        dispatch(getAllConversationsBySenderId())
        dispatch(
            getAllMessagesfromConversation({
                senderId: mongoIdFromLocalStorge,
                receiverId: receiverIdFromCurrentConversation,
            }),
        )
    }
}
function getNumberOfUnreadMessagesBySenderId(senderId) {
    const localstorageData = JSON.parse(localStorage.getItem('claims'))
    const idFromLocalStorageData = localstorageData && localstorageData.value.mongo_id
    return dispatch => {
        // console.log("getNumberOfUnreadMessagesBySenderId", senderId);
        chatService.getNumberOfUnreadMessagesBySenderId(senderId || idFromLocalStorageData).then(
            results => {
                dispatch(success(results))
            },
            error => {},
        )
    }

    function success(results) {
        return {
            type: chatConstants.GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS,
            results,
        }
    }
}

function setcurrentConversation(selected) {
    return {
        type: chatConstants.SET_CURRENT_CONVERSATION,
        selected,
    }
}

function setNumberOfUnreadMsgs(number) {
    return {
        type: chatConstants.GET_NUMBER_OF_UNREAD_MESSAGES_BY_SENDER_ID_SUCCESS,
        results: number,
    }
}

function conversationReadMessages(conversationId, senderId) {
    return dispatch => {
        if (typeof conversationId != 'number') {
            chatService.conversationReadMessages(conversationId).then(
                results => {
                    dispatch(getAllConversationsBySenderId(senderId))
                },
                error => {},
            )
        }
    }
}

function setNewMessageFlag(bool, payload) {
    return dispatch => {
        dispatch(success(bool))
        const { authenticationReducer, chatReducer } = store.getState()
        const { mongoId } = authenticationReducer
        const localMongoId = mongoId || JSON.parse(localStorage.getItem('claims')).value.mongo_id
        if (bool) {
            const { data } = payload.notification
            switch (data.Type) {
                case 'Chat':
                    const { SenderId, RecipientId } = data
                    dispatch(getAllConversationsBySenderId(localMongoId))
                    // dispatch(setNumberOfUnreadMsgs(UnreadMessages));
                    dispatch(getNumberOfUnreadMessagesBySenderId(localMongoId))
                    if (
                        chatReducer.currentConversation &&
                        chatReducer.currentConversation.hasOwnProperty('recipientId')
                    ) {
                        const {
                            recipientId: currentConvrecipientId,
                            senderId: currentConvSenderId,
                            id: currnetConvId,
                        } = chatReducer.currentConversation

                        if (SenderId === currentConvrecipientId) {
                            dispatch(
                                getAllMessagesfromConversation({
                                    senderId: SenderId,
                                    receiverId: RecipientId,
                                }),
                            )
                            dispatch(conversationReadMessages(currnetConvId, currentConvSenderId))
                        }
                    }
                    break
                case 'Ticketing':
                    dispatch(incidentActions.getAll())
                    dispatch(incidentActions.getNumberOfTicketsToReviewFromOpUser(localMongoId))
                    break
                case 'RejectedTrip':
                    dispatch(notificationActions.getAll())
                    dispatch(notificationActions.getNotificationsCount())
                    break
                default:
                    break
            }
        }
        function success(bool) {
            return {
                type: chatConstants.NEW_MESSAGE_FLAG,
                bool,
            }
        }
    }
}

function updateMessage(body) {
    return dispatch => {
        chatService.updateMessage(body).then(
            results => {
                dispatch(getAllMessagesfromConversation('body'))
            },
            error => {
                dispatch(alertActions.error('Error updateMessage: ' + error))
            },
        )
    }
}

function deleteMessage(messageId) {
    return dispatch => {
        chatService.deleteMessage(messageId).then(
            results => {
                dispatch(getAllMessagesfromConversation('body'))
            },
            error => {
                //
            },
        )
    }
}

function sendMessage(body) {
    const { findDriver, senderId, recipientId } = body

    delete body.findDriver

    const language = findDriver.language || 'en'

    return dispatch => {
        chatService.getDriverPushToken(findDriver.id).then(
            results => {
                let localExpoPushToken = ''
                if (!results || results.length === 0) {
                    dispatch(alertActions.warning(i18next.t('chat.errors.pushTokenError')))
                } else {
                    localExpoPushToken = results
                }
                const updatedBody = {
                    ...body,
                    expoPushToken: localExpoPushToken,
                }
                chatService.sendMessage(updatedBody, language).then(
                    results => {
                        dispatch(
                            getAllMessagesfromConversation({
                                senderId: senderId,
                                receiverId: recipientId,
                            }),
                        )
                    },
                    error => {
                        if (results != '') {
                            dispatch(alertActions.error(i18next.t('chat.errors.sendMessage')))
                        }
                    },
                )
            },
            error => {
                dispatch(alertActions.error(i18next.t('chat.errors.pushTokenError')))
            },
        )
    }
}

function getAllMessagesfromConversation(body) {
    return dispatch => {
        if (body.senderId && body.receiverId) {
            dispatch(request())
            chatService.getAllMessagesfromConversation(body).then(
                results => {
                    dispatch(success(results))
                },
                error => {
                    dispatch(
                        alertActions.error(i18next.t('chat.errors.getAllMessagesFromConversation')),
                    )
                    dispatch(failure(error))
                },
            )
        }
    }

    function request() {
        return {
            type: chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_REQUEST,
        }
    }

    function success(results) {
        return {
            type: chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return {
            type: chatConstants.GET_ALL_MESSAGES_FROM_CONVERSATION_FAILURE,
            error,
        }
    }
}

function createConversation(body) {
    const { senderId } = body
    return dispatch => {
        chatService.createConversation(body).then(
            results => {
                dispatch(getAllConversationsBySenderId(senderId, body))
            },
            error => {
                dispatch(alertActions.error(i18next.t('chat.errors.createConversation')))
            },
        )
    }
}

function removeConversation(conversationId, senderId) {
    return dispatch => {
        chatService.removeConversation(conversationId).then(
            results => {
                dispatch(alertActions.success('Conversation removed'))
                dispatch(getAllConversationsBySenderId(senderId))
                dispatch(setcurrentConversation({ id: null }))
            },
            error => {
                dispatch(alertActions.error(i18next.t('chat.errors.removeConversation')))
            },
        )
    }
}

function getAllConversationsBySenderId(senderId, setConversationBody) {
    const localstorageData = JSON.parse(localStorage.getItem('claims'))
    const id = senderId || (localstorageData && localstorageData.value.mongo_id)
    return dispatch => {
        dispatch(request())
        chatService.getAllConversationsBySenderId(id).then(
            results => {
                dispatch(success(results))
                dispatch(getNumberOfUnreadMessagesBySenderId(id))
                if (setConversationBody) {
                    const findConversation = results.find(
                        r => setConversationBody.recipientId === r.recipientId,
                    )

                    dispatch(setcurrentConversation(findConversation))
                }
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return {
            type: chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_REQUEST,
        }
    }

    function success(results) {
        return {
            type: chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return {
            type: chatConstants.GET_ALL_CONVERSATIONS_BY_SENDER_ID_FAILURE,
            error,
        }
    }
}
