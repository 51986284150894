import i18next from 'i18next'
import { KPIConstants } from '../../constants/kpi_constant'
import { ERROR_CODES, KPI_FILTER_OPTIONS } from '../../constants/types'
import { kpiService } from '../../services/kpiService'
import { alertActions } from './alert_actions'
import { getDriversGroupBy } from '../../components/commandBox/helpers/kpiHelper'

export const kpiActions = {
    getConfirmedTrips,
    getReservationsKPI,
    getAlertsKPI,
    getCustomersKPI,
    getDriversKPI,
    getDriverRequestsKPI,
    getJourneysKPI,
    getConfirmedGroupedTripsKPI,
    getDriverCalendarKPI,
    getTripBookingKPI,
    getVehicleGeneralDataKPI,
    cleanKPI,
}

const FilterOptions = (
    groupedBy,
    value,
    filterByDate = false,
    startDate = '',
    finishDate = '',
    timeUnits = null,
    tripOrigin = null,
) => {
    return { groupedBy, value, filterByDate, startDate, finishDate, timeUnits, tripOrigin }
}

const getBody = (dateStart, dateEnd, type, data) => {
    let services = []
    let otps = []
    let tenants = []
    let regions = []
    let municipalities = []

    switch (type) {
        case KPI_FILTER_OPTIONS.services:
            services = data.map(el => el.value)
            break
        case KPI_FILTER_OPTIONS.otps:
            otps = data.map(el => el.value)
            break
        case KPI_FILTER_OPTIONS.tenant:
            tenants = data.map(el => el.value)
            break
        case KPI_FILTER_OPTIONS.region:
            regions = data.map(el => el.label)
            break
        case KPI_FILTER_OPTIONS.municipalities:
            municipalities = data.map(el => el.label)
            break
        default:
            break
    }

    return { dateStart, dateEnd, services, otps, tenants, regions, municipalities }
}

function getConfirmedTrips(dateStart, dateEnd, filter) {
    if (!filter) return
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getConfirmedTripsKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.forbiddenGetConfirmedTrips'),
                            ),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.notFoundGetConfirmedTrips')),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.getConfirmedTripsError')),
                        )
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: KPIConstants.GET_CONFIRMED_TRIPS_REQUEST }
    }
    function success(confirmTrips) {
        return { type: KPIConstants.GET_CONFIRMED_TRIPS_SUCCESS, confirmTrips }
    }
    function failure(error) {
        return { type: KPIConstants.GET_CONFIRMED_TRIPS_FAILURE, error }
    }
}

function getConfirmedGroupedTripsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter
        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getConfirmedGroupedTripsKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.forbiddenGetConfirmedGroupedTrips'),
                            ),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.notFoundGetConfirmedGroupedTrips'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.getConfirmedGroupedTripsError'),
                            ),
                        )
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_REQUEST }
    }
    function success(confirmTrips) {
        return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_SUCCESS, confirmTrips }
    }
    function failure(error) {
        return { type: KPIConstants.GET_CONFIRMED_GROUPED_TRIPS_FAILURE, error }
    }
}
function getReservationsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getReservationsKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.forbiddenGetReservations')),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.notFoundGetReservations')),
                        )
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getReservationsError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_RESERVATIONS_REQUEST }
    }
    function success(reservations) {
        return { type: KPIConstants.GET_RESERVATIONS_SUCCESS, reservations }
    }
    function failure(error) {
        return { type: KPIConstants.GET_RESERVATIONS_FAILURE, error }
    }
}

function getAlertsKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getAlertsKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetAlerts')))
                        break
                    case ERROR_CODES.notFound:
                        dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetAlerts')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getAlertsError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_ALERTS_REQUEST }
    }
    function success(alerts) {
        return { type: KPIConstants.GET_ALERTS_SUCCESS, alerts }
    }
    function failure(error) {
        return { type: KPIConstants.GET_ALERTS_FAILURE, error }
    }
}

function getCustomersKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getCustomersKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.forbiddenGetCustomers')),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetCustomers')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getCustomersError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_CUSTOMERS_REQUEST }
    }
    function success(customers) {
        return { type: KPIConstants.GET_CUSTOMERS_SUCCESS, customers }
    }
    function failure(error) {
        return { type: KPIConstants.GET_CUSTOMERS_FAILURE, error }
    }
}

function getDriversKPI(dateStart, dateEnd, filter) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriversKPI(body).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetDrivers')))
                        break
                    case ERROR_CODES.notFound:
                        dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetDrivers')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getDriversError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_DRIVERS_REQUEST }
    }
    function success(drivers) {
        return { type: KPIConstants.GET_DRIVERS_SUCCESS, drivers }
    }
    function failure(error) {
        return { type: KPIConstants.GET_DRIVERS_FAILURE, error }
    }
}

function getDriverCalendarKPI(dateStart, dateEnd, filter, lang) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriverCalendarKPI({ ...body, lang }).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.forbiddenGetDriverCalendar'),
                            ),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.notFoundGetDriverCalendar')),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.getDriverCalendarError')),
                        )
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_DRIVER_CALENDAR_REQUEST }
    }
    function success(driverCalendar) {
        return { type: KPIConstants.GET_DRIVER_CALENDAR_SUCCESS, driverCalendar }
    }
    function failure(error) {
        return { type: KPIConstants.GET_DRIVER_CALENDAR_FAILURE, error }
    }
}

function getDriverRequestsKPI(dateStart, dateEnd, filter, status, lang) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getDriverRequestsKPI({ ...body, status, lang }).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.forbiddenGetDriverRequests'),
                            ),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.notFoundGetDriverRequests')),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.getDriverRequestsError')),
                        )
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_DRIVER_REQUEST_REQUEST }
    }
    function success(driverRequest) {
        return { type: KPIConstants.GET_DRIVER_REQUEST_SUCCESS, driverRequest }
    }
    function failure(error) {
        return { type: KPIConstants.GET_DRIVER_REQUEST_FAILURE, error }
    }
}

function getJourneysKPI(dateStart, dateEnd, filter, lang) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter

        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getJourneysKPI({ ...body, lang }).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.kpi.forbiddenGetJourneys')))
                        break
                    case ERROR_CODES.notFound:
                        dispatch(alertActions.error(i18next.t('services.kpi.notFoundGetJourneys')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getJourneysError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_JOURNEYS_REQUEST }
    }
    function success(journeys) {
        return { type: KPIConstants.GET_JOURNEYS_SUCCESS, journeys }
    }
    function failure(error) {
        return { type: KPIConstants.GET_JOURNEYS_FAILURE, error }
    }
}

function getTripBookingKPI(dateStart, dateEnd, filter, lang) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter
        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getTripsBookingKPI({ ...body, lang }).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.forbiddenGetTripBooking')),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(i18next.t('services.kpi.notFoundGetTripBooking')),
                        )
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.kpi.getTripBookingError')))
                        break
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: KPIConstants.GET_TRIP_BOOKING_REQUEST }
    }
    function success(tripBooking) {
        return { type: KPIConstants.GET_TRIP_BOOKING_SUCCESS, tripBooking }
    }
    function failure(error) {
        return { type: KPIConstants.GET_TRIP_BOOKING_FAILURE, error }
    }
}

function getVehicleGeneralDataKPI(dateStart, dateEnd, filter, lang) {
    return dispatch => {
        dispatch(request())
        const { type, data } = filter
        const body = getBody(dateStart, dateEnd, type, data)
        kpiService.getVehicleGeneralDataKPI({ ...body, lang }).then(
            result => {
                dispatch(success(result))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.forbiddenGetVehicleGeneralData'),
                            ),
                        )
                        break
                    case ERROR_CODES.notFound:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.notFoundGetVehicleGeneralData'),
                            ),
                        )
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.kpi.getVehicleGeneralDataError'),
                            ),
                        )
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: KPIConstants.GET_VEHICLE_GENERAL_DATA_REQUEST }
    }
    function success(vehicleGeneralData) {
        return { type: KPIConstants.GET_VEHICLE_GENERAL_DATA_SUCCESS, vehicleGeneralData }
    }
    function failure(error) {
        return { type: KPIConstants.GET_VEHICLE_GENERAL_DATA_FAILURE, error }
    }
}
function cleanKPI() {
    return dispatch => {
        dispatch({ type: KPIConstants.CLEAN_KPI })
    }
}
