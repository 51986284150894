export const initialStateMultipleTrip: MultipleTripState = {
    openModalSelectFrecuency: false,
    openModalShowResults: false,
    executeMultipleTrips: false,
    areInReturnProcess: false,
    results: [],
    pending: false,
    idOriginalOutboundTrip: null,
    idOriginalReturnTrip: null,
    selectedTripsToReturn: [],
    requestPickUpStartTime: null,
}

export interface TripResult {
    tripId: string
    requestPickUpTime: string
    tripResponse?: any
}

export interface SelectedTrip {
    id: string
    checked: boolean
    schedulePickUpTime?: string
}

export interface MultipleTripState {
    openModalSelectFrecuency: boolean
    openModalShowResults: boolean
    executeMultipleTrips: boolean
    areInReturnProcess: boolean
    results?: TripResult[]
    pending: boolean
    idOriginalOutboundTrip?: string | null
    idOriginalReturnTrip?: string | null
    selectedTripsToReturn: SelectedTrip[]
    requestPickUpStartTime: string | null
    previewTrip?: TripResult
    tripIdToPlanOutbound?: string
    serviceType?: string
    maxRequestTripDays?: number
    outboundTripId?: string
    error?: string | null
}

export interface MultipleTripAction {
    type: string
    results?: { results: TripResult[]; previewTrip: TripResult } | TripResult[]
    trip?: TripResult
    error?: string
    selectedValues?: SelectedTrip[]
    value?: boolean | string | number
    tripId?: string
    requestPickUpStartTime?: string
    serviceType?: string
    maxRequestTimeDays?: number
    array?: TripResult[]
    tripIdToRetry?: string
    result?: TripResult
    outboundTripId?: string
}
