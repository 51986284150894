import { combineReducers } from 'redux'
import { authenticationReducer } from './authentication/index'
import { alertReducer } from './alert/index'
import { operatorReducer } from './operator/index'
import { otpReducer } from './otp/index'
import { driverReducer } from './driver/index'
import { vehicleReducer } from './vehicle/index'
import { zoneReducer } from './zone/index'
import { areaReducer } from './area/index'
import { serviceReducer } from './service/index'
import { customerReducer } from './customer/index'
import { chatReducer } from './chat/index'
import { incidentReducer } from './incident/index'
import { notificationReducer } from './notification/index'
import { tripReducer } from './trip/index'
import { tenantReducer } from './tenant/index'
import { rolesReducer } from './role/index'
import { modulesReducer } from './module/index'
import { multipleTripReducer } from './multipleTrip/index'
import { reportReducer } from './report/index'
import { kpiReducer } from './kpi/index'
import { kpiFiltersReducer } from './kpi/filter/index'
import { journeyReducer } from './journey/index'

// Definir la estructura del estado global
export interface RootState {
    authenticationReducer: ReturnType<typeof authenticationReducer>
    alertReducer: ReturnType<typeof alertReducer>
    operatorReducer: ReturnType<typeof operatorReducer>
    otpReducer: ReturnType<typeof otpReducer>
    driverReducer: ReturnType<typeof driverReducer>
    vehicleReducer: ReturnType<typeof vehicleReducer>
    zoneReducer: ReturnType<typeof zoneReducer>
    areaReducer: ReturnType<typeof areaReducer>
    serviceReducer: ReturnType<typeof serviceReducer>
    customerReducer: ReturnType<typeof customerReducer>
    chatReducer: ReturnType<typeof chatReducer>
    incidentReducer: ReturnType<typeof incidentReducer>
    tripReducer: ReturnType<typeof tripReducer>
    notificationReducer: ReturnType<typeof notificationReducer>
    tenantReducer: ReturnType<typeof tenantReducer>
    rolesReducer: ReturnType<typeof rolesReducer>
    modulesReducer: ReturnType<typeof modulesReducer>
    multipleTripReducer: ReturnType<typeof multipleTripReducer>
    reportReducer: ReturnType<typeof reportReducer>
    kpiReducer: ReturnType<typeof kpiReducer>
    kpiFiltersReducer: ReturnType<typeof kpiFiltersReducer>
    journeyReducer: ReturnType<typeof journeyReducer>
}

const reducer = combineReducers<RootState>({
    authenticationReducer,
    alertReducer,
    operatorReducer,
    otpReducer,
    driverReducer,
    vehicleReducer,
    zoneReducer,
    areaReducer,
    serviceReducer,
    customerReducer,
    chatReducer,
    incidentReducer,
    tripReducer,
    notificationReducer,
    tenantReducer,
    rolesReducer,
    modulesReducer,
    multipleTripReducer,
    reportReducer,
    kpiReducer,
    kpiFiltersReducer,
    journeyReducer,
})

export default reducer
