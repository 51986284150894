import { OTP } from 'model/Otp'

export const initialStateOTP: OTPState = {
    pending: false,
    results: [],
    otp: null,
    error: null,
}

export interface OTPState {
    pending: boolean
    results: OTP[]
    otp?: OTP | null
    error?: string | null
}

export interface OTPAction {
    type: string
    results?: OTP[]
    otp?: OTP
    id?: string
    tenantId?: string
    error?: string
}
