import moment from 'moment';
import React, { forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
import "react-datepicker/dist/react-datepicker.css";
import i18next from 'i18next';


class DatePickerFilter extends React.Component {

    constructor(props) {
        super()
        switch (true) {
            case (i18next.language == "en"):
                registerLocale('en', en)
                break;
            case (i18next.language == "es"):
                registerLocale('es', es)
                break;
            case (i18next.language == "ca"):
                registerLocale('ca', ca)
                break;
            default:
                registerLocale('ca', ca)
                break;
        }
    }

    render() {
        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <input onClick={onClick} ref={ref} value={value} readOnly autoComplete='off' style={{ width: '78%' }} />
        ));
        return (           
                <DatePicker
                    customInput={<CustomInput />}
                    dateFormat="dd/MM/yyyy"
                    locale={i18next.language}
                    selected={this.props.value[0] ? moment(this.props.value[0]).toDate() : ''}
                    onChange={date => this.props.setValue(this.props.prop, date ? [moment(date).utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString().replace(/.\d+Z$/g, "Z")] : [])}
                />
            );
    }
}

export default DatePickerFilter;