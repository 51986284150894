import React from 'react';
import Spinner from '../../shared/spinner';
import ServiceForm from '../form';
import ServiceUserList from '../components/serviceUserList';

class ServicesDetailsView extends React.Component {

    render() {
        const { serviceReducer } = this.props
        return (
            <React.Fragment>
                {serviceReducer.pending || serviceReducer.service?.serviceResponse == null ?
                    <Spinner loading={serviceReducer.pending} /> :
                    <>
                        <ServiceForm
                            {...this.props}
                        />
                        <ServiceUserList />
                    </>
                }
            </React.Fragment>
        );
    }
}

export default ServicesDetailsView;