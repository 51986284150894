import React from 'react'
import ItemStop from '../stop'
import { Grid, FormControl, Button } from '@material-ui/core'

class StopsForm extends React.Component {
    render() {
        const { t, zoneReducer, state } = this.props

        return (
            <Grid
                container
                style={{
                    maxHeight: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxWidth: '100%',
                }}
            >
                {this.props.state.stops.map((item, index) => (
                    <ItemStop
                        key={item.id}
                        index={index}
                        id={item.id}
                        value={item}
                        length={this.props.state.stops.length}
                        errors={this.props.state.errors.items[index]}
                        t={t}
                        onChangeAddress={this.props.onChangeAddress}
                        onChangeLatLon={this.props.onChangeLatLon}
                        onChange={this.props.onChange}
                        add={this.props.addStop}
                        remove={this.props.removeItem}
                        onMapEditMode={this.props.onMapEditMode}
                        editingIndex={this.props.state.editingIndex}
                    />
                ))}
                {state.zone !== '' && (
                    <Grid
                        container
                        spacing={2}
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        style={{ paddingBottom: 25, paddingTop: 25 }}
                    >
                        <Grid
                            item
                            xs={10}
                            sm={5}
                            md={5}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => this.props.addStop()}
                                size="large"
                            >
                                {t('zones.stops.form.addStop')}
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={10}
                            sm={5}
                            md={5}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <FormControl fullWidth>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={this.props.onSubmit}
                                    disabled={
                                        zoneReducer.pending ||
                                        this.props.state.zone == '' ||
                                        this.props.state.zone == null
                                            ? true
                                            : false
                                    }
                                    size="large"
                                >
                                    {t('zones.stops.form.save')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default StopsForm
