import { createStore, applyMiddleware, Store, AnyAction, CombinedState } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer, { RootState } from '../redux/reducers/index'

const loggerMiddleware = createLogger()

const middleware: Array<ThunkMiddleware<RootState, AnyAction>> = [thunk]

if (process.env.NODE_ENV === 'development') {
    middleware.push(loggerMiddleware as ThunkMiddleware<RootState, AnyAction>)
}

const store: Store<CombinedState<RootState>, AnyAction> = createStore(
    rootReducer,
    applyMiddleware(...middleware),
)

export default store
