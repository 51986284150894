export const initialStateChat: ChatState = {
    conversations: [],
    messages: [],
    newMessages: false,
    unreadMsg: 0,
    pendingGetAllMessagesFromConversation: false,
    currentConversation: { id: null, participants: [], lastMessage: '', timestamp: '' },
}

export interface Conversation {
    id: string | null
    participants: string[]
    lastMessage: string
    timestamp: string
}

export interface Message {
    id: string
    senderId: string
    content: string
    timestamp: string
    read: boolean
}

export interface ChatState {
    conversations: Conversation[]
    messages: Message[]
    newMessages: boolean
    unreadMsg: number
    pendingGetAllMessagesFromConversation: boolean
    pending?: boolean
    currentConversation: Conversation
    error?: string
}

export interface ChatAction {
    type: string
    selected?: Conversation
    results?: Conversation[] | Message[] | number
    bool?: boolean
    error?: string
}
