export function isInsideZone(point, zones) {
    let res = null;
    return zones.map((zone) => {
        res = {
            'insideZone': zone,
            'inside': isInPolygon(point, zone.points),
            'name': zone.name,
            'pickupAndDropOffAutoComplete': zone.pickupAndDropOffAutoComplete ?? false,
            'pickupAndDropOffHidden': zone.pickupAndDropOffHidden ?? false
        }
        return res;
    })
}

function isInPolygon(point, polygonArray) {

    let x = point.latitude
    let y = point.longitude

    let inside = false
    for (let i = 0, j = polygonArray.length - 1; i < polygonArray.length; j = i++) {
        let xLat = polygonArray[i].lat
        let yLat = polygonArray[i].lon
        let xLon = polygonArray[j].lat
        let yLon = polygonArray[j].lon

        let intersect = ((yLat > y) !== (yLon > y)) && (x < (xLon - xLat) * (y - yLat) / (yLon - yLat) + xLat)
        if (intersect) inside = !inside
    }
    return inside
}