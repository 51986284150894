export const initialStateJourney: JourneyState = {
    journey: {} as Journey,
    error: '',
    isLoading: false,
}

export interface Journey {
    id: string
    origin: string
    destination: string
    departureTime: string
    arrivalTime: string
    driverId: string
    vehicleId: string
    status: string
}

export interface JourneyState {
    journey: Journey
    error: string
    isLoading: boolean
}

export interface JourneyAction {
    journey: Journey
    error: string
    type: string
}
