import { userService } from './userService'
import { userActions } from '../redux/actions/user_actions'
import store from '../redux/store'

let refreshPromise = null
let pendingRequests = []

export async function customFetch(url, options = {}) {
    if (!userService.existRefreshToken()) {
        store.dispatch(userActions.logout())
    }

    if (isInvalidToken(options.headers?.Authorization)) {
        return getValidToken(url, options)
    }

    if (refreshPromise) {
        return new Promise((resolve, reject) => {
            pendingRequests.push({ url, options, resolve, reject })
        })
    }

    try {
        let response = await fetch(url, options)

        if (response.status === 401 && userService.existRefreshToken()) {
            return getValidToken(url, options)
        }

        return response
    } catch (error) {
        return Promise.reject(error)
    }
}

async function getValidToken(url, options) {
    if (!refreshPromise) {
        refreshPromise = userService
            .refreshToken()
            .then(newToken => {
                processPendingRequests(newToken)
                return newToken
            })
            .catch(error => {
                failPendingRequests(error)
                store.dispatch(userActions.logout())
                throw error
            })
            .finally(() => {
                refreshPromise = null
            })
    }

    const token = await refreshPromise

    options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
    }

    return fetch(url, options)
}

function processPendingRequests(newToken) {
    pendingRequests.forEach(({ url, options, resolve, reject }) => {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${newToken}`,
        }
        fetch(url, options).then(resolve).catch(reject)
    })

    pendingRequests = []
}

function failPendingRequests(error) {
    pendingRequests.forEach(({ reject }) => reject(error))
    pendingRequests = []
}

function isInvalidToken(token) {
    return (
        !token ||
        token === 'null' ||
        token === 'undefined' ||
        token === 'INVALID_TOKEN' ||
        token.includes('null')
    )
}
