import { otpConstants } from '../../../constants/otp_constants'
import { OTPState, OTPAction, initialStateOTP } from './types'

export function otpReducer(state = initialStateOTP, action: OTPAction): OTPState {
    switch (action.type) {
        case otpConstants.DETAIL_REQUEST:
        case otpConstants.GETALL_REQUEST:
        case otpConstants.ADD_REQUEST:
        case otpConstants.DELETE_REQUEST:
            return { ...state, pending: true }

        case otpConstants.GETALL_SUCCESS:
            return { ...state, pending: false, results: action.results || [] }

        case otpConstants.DETAIL_SUCCESS:
        case otpConstants.ADD_SUCCESS:
            return { ...state, pending: false, otp: action.otp }

        case otpConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(otp => otp.id !== action.id),
            }

        case otpConstants.FILTER_BY_TENANT:
            return {
                ...state,
                results: state.results.filter(otp => otp.tenantId === action.tenantId),
            }
        case otpConstants.DETAIL_FAILURE:
        case otpConstants.GETALL_FAILURE:
        case otpConstants.ADD_FAILURE:
        case otpConstants.DELETE_FAILURE:
            return { ...state, pending: false, error: action.error }

        default:
            return state
    }
}
